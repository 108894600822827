import { useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Formik, Form as FForm, Field, ErrorMessage } from "formik";
import { createIssue, sendEmail } from "../../../actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { v4 as uuidv4 } from "uuid";
import {
    ActiveTab,
    Issue,
    IssueStatus,
    IssueType,
    EmailPayload,
} from "../../../types"; //enum
import IssueTrackerTabs from "./IssueFormTabs";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { issueSchema } from "../../../validators/issueSchema";
import { render } from "@react-email/render";
import IssuesEmail, { IssuesEmailUserType } from "./IssuesEmail";

interface Props {
    isSmall: boolean;
}
export default function IssueForm({ isSmall }: Props) {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [error, setError] = useState("");
    const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.WRITE);
    return (
        <div className="container mb-4">
            <Formik
                validationSchema={issueSchema}
                initialValues={{
                    ownerId: user.id || "",
                    name: user.displayName || "",
                    email: user.email || "",
                    issueType: "",
                    description: "",
                    title: "",
                    status: IssueStatus.NEW,
                }}
                onSubmit={async (values, actions) => {
                    try {
                        if (!values.ownerId) {
                            throw new Error("No owner Id!");
                        }
                        setError("");
                        actions.setSubmitting(true);
                        const date: number = Date.now();
                        const payload: Issue = {
                            ...values,
                            id: uuidv4(),
                            createdAt: date,
                            updatedAt: date,
                            threads: [
                                {
                                    id: uuidv4(),
                                    createdAt: date,
                                    updatedAt: date,
                                    ownerId: values.ownerId,
                                    ownerName: values.name,
                                    text: values.description,
                                },
                            ],
                        };
                        await dispatch(createIssue(payload));

                        const userHtml = render(
                            <IssuesEmail
                                data={payload}
                                userType={IssuesEmailUserType.USER}
                            />
                        );
                        const userText = render(
                            <IssuesEmail
                                data={payload}
                                userType={IssuesEmailUserType.USER}
                            />,
                            {
                                plainText: true,
                            }
                        );
                        const userEmailPayload: EmailPayload = {
                            sender: "The Ravim Issues Desk<contact@theravim.com>",
                            recipient: values.email,
                            subject: `New Issue: ${values.issueType}`,
                            html: userHtml,
                            text: userText,
                        };
                        console.log("User email payload", userEmailPayload);
                        const adminHtml = render(
                            <IssuesEmail
                                data={payload}
                                userType={IssuesEmailUserType.ADMIN}
                            />
                        );
                        const adminText = render(
                            <IssuesEmail
                                data={payload}
                                userType={IssuesEmailUserType.ADMIN}
                            />,
                            {
                                plainText: true,
                            }
                        );
                        const adminEmailPayload: EmailPayload = {
                            sender: "The Ravim Issues Desk<contact@theravim.com>",
                            recipient: "contact@theravim.com",
                            subject: `New Issue: ${values.issueType}`,
                            html: adminHtml,
                            text: adminText,
                        };
                        console.log("Admin email payload", adminEmailPayload);
                        await dispatch(sendEmail(adminEmailPayload));
                        await dispatch(sendEmail(userEmailPayload));
                        actions.resetForm({ values });
                        actions.setSubmitting(false);
                    } catch (error: any) {
                        console.warn(error);
                        console.log(typeof error.code);
                        actions.setSubmitting(false);
                        setError(error.message);
                        // if (
                        //     error.code.includes("user-not-found") ||
                        //     error.code.includes("wrong-password")
                        // ) {
                        //     setError("Bad username or password");
                        // } else {
                        //     setError(error.message);
                        // }
                    }
                }}
            >
                {({ dirty, errors, isSubmitting, values }) => (
                    <FForm
                        noValidate
                        className="d-flex flex-column align-items-center my-4"
                    >
                        <div
                            className="d-flex flex-column align-items-center w-100"
                            style={{ maxWidth: 400 }}
                        >
                            <Form.Group className="w-100">
                                <Form.Label className="mb-1">
                                    {isSmall ? (
                                        <small>Name</small>
                                    ) : (
                                        <span>Name</span>
                                    )}
                                </Form.Label>
                                <Field
                                    className={`form-control ${
                                        isSmall ? "form-control-sm" : ""
                                    }`}
                                    name="name"
                                    placeholder="Edgar"
                                    type="text"
                                />
                                <ErrorMessage
                                    className="text-danger ms-2"
                                    name="name"
                                    component="small"
                                />
                            </Form.Group>
                            <Form.Group className="w-100 mt-2">
                                <Form.Label className="mb-1">
                                    {isSmall ? (
                                        <small>Email</small>
                                    ) : (
                                        <span>Email</span>
                                    )}
                                </Form.Label>
                                <Field
                                    className={`form-control ${
                                        isSmall ? "form-control-sm" : ""
                                    }`}
                                    name="email"
                                    placeholder="edgar@theravim.com"
                                    type="email"
                                />
                                <ErrorMessage
                                    className="text-danger ms-2"
                                    name="email"
                                    component="small"
                                />
                            </Form.Group>
                            <Form.Group className="w-100 mt-2">
                                <Form.Label className="mb-1">
                                    {isSmall ? (
                                        <small>Title</small>
                                    ) : (
                                        <span>Title</span>
                                    )}
                                </Form.Label>
                                <Field
                                    className={`form-control ${
                                        isSmall ? "form-control-sm" : ""
                                    }`}
                                    name="title"
                                    placeholder="Cannot print eBook"
                                    type="text"
                                />
                                <ErrorMessage
                                    className="text-danger ms-2"
                                    name="title"
                                    component="small"
                                />
                            </Form.Group>
                            <Form.Group className="w-100 mt-2">
                                <Form.Label className="mb-1">
                                    {isSmall ? (
                                        <small>Issue Type</small>
                                    ) : (
                                        <span>Issue Type</span>
                                    )}
                                </Form.Label>
                                <Field
                                    as="select"
                                    className={`form-select ${
                                        isSmall ? "form-select-sm" : ""
                                    }`}
                                    name="issueType"
                                    // onChange={()=>{
                                    //     setF
                                    // }}
                                    type="text"
                                >
                                    <option value="">Select Issue Type</option>
                                    <option value={IssueType.BUG}>
                                        Bug Report
                                    </option>
                                    <option value={IssueType.FEATURE_REQUEST}>
                                        Feature Request
                                    </option>
                                    <option value={IssueType.FEEDBACK}>
                                        Feedback
                                    </option>
                                    <option value={IssueType.QUESTION}>
                                        Question
                                    </option>
                                    <option value={IssueType.OTHER}>
                                        Other
                                    </option>
                                </Field>
                                <ErrorMessage
                                    className="text-danger ms-2"
                                    name="issueType"
                                    component="small"
                                />
                            </Form.Group>
                            <Form.Group className="w-100 mt-2">
                                <Form.Label className="mb-1">
                                    {isSmall ? (
                                        <small>Describe the Issue</small>
                                    ) : (
                                        <span>Describe the Issue</span>
                                    )}
                                </Form.Label>
                                <div className="ms-1">
                                    <IssueTrackerTabs
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        isSmall
                                        isPreviewDisabled={
                                            values.description.length === 0
                                        }
                                    />
                                </div>
                                {activeTab === ActiveTab.WRITE ? (
                                    <Field
                                        as="textarea"
                                        rows={5}
                                        className="form-control"
                                        name="description"
                                        placeholder=""
                                        type="text"
                                    />
                                ) : (
                                    <ReactMarkdown className="border rounded issue-description-markdown-field">
                                        {values.description}
                                    </ReactMarkdown>
                                )}
                                {!errors.description ? (
                                    <small className="ms-2 text-info">
                                        You can use Markdown here
                                    </small>
                                ) : (
                                    <ErrorMessage
                                        className="text-danger ms-2"
                                        name="description"
                                        component="small"
                                    />
                                )}
                            </Form.Group>
                            {error && (
                                <Alert
                                    className="text-center py-1 w-100 mt-2 mb-0"
                                    variant="warning"
                                >
                                    <small>{error}</small>
                                </Alert>
                            )}
                            <Button
                                className="d-flex align-items-center justify-content-center mt-3 w-100"
                                disabled={isSubmitting || !dirty}
                                size="sm"
                                type="submit"
                            >
                                {isSubmitting ? (
                                    <Spinner size="sm" />
                                ) : (
                                    <i className="bi bi-cloud-arrow-up" />
                                )}
                                <span className="ms-2">Submit Issue</span>
                            </Button>
                            {/* <Button
                            className="custom-link-btn-muted"
                            onClick={() =>
                                setState({
                                    ...state,
                                    showForm: false,
                                    selectedAgent: undefined,
                                })
                            }
                            variant="link"
                        >
                            <small>cancel</small>
                        </Button> */}
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );
}
