import Nav from "react-bootstrap/Nav";
import { ActiveTab } from "../../../types";

interface Props {
    activeTab: ActiveTab;
    setActiveTab: (t: ActiveTab) => void;
    isSmall: boolean;
    isPreviewDisabled: boolean;
}
export default function IssueTrackerTabs({
    activeTab,
    setActiveTab,
    isPreviewDisabled,
    isSmall,
}: Props) {
    return (
        <Nav
            className="w-100 mt-0 mb-0"
            variant="tabs"
            defaultActiveKey="/home"
        >
            <Nav.Item>
                <Nav.Link
                    className="py-0 py-sm-1"
                    active={activeTab === ActiveTab.WRITE}
                    onClick={() => setActiveTab(ActiveTab.WRITE)}
                >
                    {isSmall ? <small>Write</small> : <span>Write</span>}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    className="py-0 py-sm-1"
                    disabled={isPreviewDisabled}
                    active={activeTab === ActiveTab.PREVIEW}
                    onClick={() => setActiveTab(ActiveTab.PREVIEW)}
                >
                    {isSmall ? <small>Preview</small> : <span>Preview</span>}
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
}
