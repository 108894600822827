import {
    Body,
    Container,
    Head,
    Html,
    Img,
    Preview,
    Text,
} from "@react-email/components";
import { ContactFormEntry } from "../../../types";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    data: ContactFormEntry;
}

export default function Email({ data }: Props) {
    return (
        <Html>
            <Head />
            <Preview>The Ravim Contact Form</Preview>
            <Body style={main}>
                <Container style={container}>
                    <Img
                        src={
                            isDev
                                ? "http://localhost:3000/the_ravim_logo_96.jpg"
                                : `https://theravim.com/the_ravim_logo_96.jpg`
                        }
                        alt="The Ravim logo"
                        style={logo}
                    />
                    <Text style={paragraph}>
                        <strong>From:</strong> {`${data.name}`}
                    </Text>
                    <Text style={paragraph}>
                        <strong>Email:</strong> {`${data.email}`}
                    </Text>
                    <Text style={paragraph}>
                        <strong>Issue Type:</strong> {`${data.issueType}`}
                    </Text>
                    <Text style={paragraph}>
                        <strong>Message:</strong>
                    </Text>
                    <Text style={paragraph}>{`${data.message}`}</Text>
                </Container>
            </Body>
        </Html>
    );
}

const main = {
    backgroundColor: "#ffffff",
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const container = {
    margin: "0 auto",
    padding: "20px 0 48px",
};

const logo = {
    borderRadius: "15px",
    margin: "0 auto",
};

const paragraph = {
    fontSize: "16px",
    lineHeight: "26px",
};

// const btnContainer = {
//     textAlign: "center" as const,
// };

// const button = {
//     backgroundColor: "#5F51E8",
//     borderRadius: "3px",
//     color: "#fff",
//     fontSize: "16px",
//     textDecoration: "none",
//     textAlign: "center" as const,
//     display: "block",
// };

// const hr = {
//     borderColor: "#cccccc",
//     margin: "20px 0",
// };

// const footer = {
//     color: "#8898aa",
//     fontSize: "12px",
// };
