// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { OAuthProvider, GoogleAuthProvider } from "firebase/auth";

const apiKey = process.env.REACT_APP_FIREBASE_APIKEY;
const authDomain = process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
const projectId = process.env.REACT_APP_FIREBASE_PROJECTID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGEBUCKET;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
const appId = process.env.REACT_APP_FIREBASE_APPID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENTID;
if (
    !apiKey ||
    !authDomain ||
    !projectId ||
    !storageBucket ||
    // !messagingSenderId ||
    !appId ||
    !measurementId
) {
    console.error("Missing Firebase Environment Variables!");
}

const firebaseConfig = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); 
const functions = getFunctions(app);
const storage = getStorage(app);
const appleAuthProvider = new OAuthProvider("apple.com");
const googleAuthProvider = new GoogleAuthProvider();
const analytics = getAnalytics(app);
export {
    analytics,
    appleAuthProvider,
    app,
    functions,
    googleAuthProvider,
    db as default,
    storage,
};
