import {
    collection,
    doc,
    getDocs,
    query,
    setDoc,
    where,
} from "firebase/firestore";
import { AppThunk } from "../store/store";
import { Issue, IssueAction } from "../types";
import db from "../firebase/firebase";
import { User } from "firebase/auth";

export const createIssue =
    (issue: Issue): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: IssueAction.CREATE_ISSUE_START });
            await setDoc(doc(db, "issues", issue.id), issue);
            dispatch({
                type: IssueAction.CREATE_ISSUE_SUCCESS,
                payload: issue,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: IssueAction.CREATE_ISSUE_FAIL });
            return Promise.reject(error);
        }
    };

export const getIssues =
    (user: User): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: IssueAction.GET_ISSUES_START });
            const q = query(
                collection(db, "issues"),
                where("ownerId", "==", user.uid)
            );
            const querySnapshot = await getDocs(q);
            const issues: Issue[] = [];
            querySnapshot.forEach((doc) => {
                const issue = doc.data() as Issue;
                issues.push(issue);
            });
            dispatch({ type: IssueAction.GET_ISSUES_SUCCESS, payload: issues });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: IssueAction.GET_ISSUES_FAIL });
            return Promise.reject(error);
        }
    };
