import {
    ActionType,
    ChapterTitleStyle,
    SceneSectionStatus,
    StoryArcBadgeColor,
} from "../types"; // enum
import {
    collection,
    deleteDoc,
    doc,
    DocumentData,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import { AppThunk } from "../store/store";
import {
    getChapterTitle,
    getReordered,
    getVersionedScene,
    getWordCount,
} from "../functions";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import db from "../firebase/firebase";
import type { Manuscript, Scene, SceneBackup, Section } from "../types";
import {
    deleteObject,
    getBlob,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
} from "firebase/storage";

const isDev = process.env.NODE_ENV === "development";

export const checkManuscriptForUpdates =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            dispatch({ type: ActionType.CHECK_MANUSCRIPT_FOR_UPDATES_START });
            const { draftManuscript } = getState().app;
            if (!draftManuscript) {
                console.log("No draft ms.  Nothing to do.");
                dispatch({
                    type: ActionType.CHECK_MANUSCRIPT_FOR_UPDATES_SUCCESS,
                });
                return Promise.resolve();
            }
            const docRef = doc(db, "manuscripts", draftManuscript.id);
            const docSnap = await getDoc(docRef);
            if (!docSnap) {
                throw new Error("Could not find manuscript in db!");
            }
            const msInDb = docSnap.data();
            if (draftManuscript?.updatedAt !== msInDb?.updatedAt) {
                if (isDev) {
                    console.log("%cWe are different", "color:hotpink");
                    console.log(
                        "%cLocal",
                        "color:hotpink",
                        draftManuscript?.updatedAt
                    );
                    console.log(
                        "%cDatabase",
                        "color:hotpink",
                        msInDb?.updatedAt
                    );
                }
                dispatch({ type: ActionType.CLOSE_MANUSCRIPT });
                dispatch({
                    type: ActionType.SET_IS_MANUSCRIPT_DIFFERENT_IN_DATABASE,
                    payload: true,
                });
                return Promise.resolve();
            }
            if (isDev) {
                console.log("%cWe are the same", "color:lime");
            }
            dispatch({ type: ActionType.CHECK_MANUSCRIPT_FOR_UPDATES_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: ActionType.CHECK_MANUSCRIPT_FOR_UPDATES_FAIL });
            return Promise.reject(error);
        }
    };

export const createManuscript =
    (title: string, userId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: ActionType.CREATE_MANUSCRIPT_START });
            const { email, penName, phone } = getState().user;
            const date = dayjs().format();
            const msId = uuidv4();
            const payload: Partial<Manuscript> = {
                id: msId,
                authorInfo: {
                    email: email || "",
                    penName: penName || "",
                    phone: phone || "",
                },
                chapterTitleStyle: ChapterTitleStyle.DEFAULT,
                characters: [],
                copyright: "",
                coverPagePath: "",
                dedication: "",
                sections: [],
                storyArcs: [
                    { title: "Main", color: StoryArcBadgeColor.PURPLE },
                ],
                submissions: [],
                title,
                owner: userId,
                createdAt: date,
                updatedAt: date,
            };
            await setDoc(doc(db, "manuscripts", msId), payload);
            dispatch({ type: ActionType.CREATE_MANUSCRIPT_SUCCESS, payload });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: ActionType.CREATE_MANUSCRIPT_FAIL });
            return Promise.reject(error);
        }
    };

export const deleteManuscript =
    (manuscript: Manuscript, userId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.DELETE_MANUSCRIPT_START });
            if (!manuscript.id) {
                throw new Error("No manuscript ID!");
            }
            await dispatch(deleteAllManuscriptScenes(manuscript, userId));
            await dispatch(
                deleteAllManuscriptDeletedScenes(manuscript, userId)
            );
            // await dispatch(deleteAllManuscriptSceneBackups(manuscript.id));
            await deleteDoc(doc(db, "manuscripts", manuscript.id));
            dispatch({
                type: ActionType.DELETE_MANUSCRIPT_SUCCESS,
                payload: manuscript.id,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: ActionType.DELETE_MANUSCRIPT_FAIL });
            return Promise.reject(error);
        }
    };

export const deleteAllManuscriptScenes =
    (manuscript: Manuscript, userId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.DELETE_ALL_MANUSCRIPT_SCENES_START });
            const collectionRef = collection(db, "scenes");
            const q = query(
                collectionRef,
                where("owner", "==", userId),
                where("manuscriptId", "==", manuscript.id)
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                if (isDev) {
                    console.log("%cDeleting Scenes", "color:cyan");
                }
                const batch = writeBatch(db);
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                });
                await batch.commit();
            } else {
                if (isDev) {
                    console.log("%cNo scenes to delete", "color:cyan");
                }
            }
            dispatch({ type: ActionType.DELETE_ALL_MANUSCRIPT_SCENES_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: ActionType.DELETE_ALL_MANUSCRIPT_SCENES_FAIL });
            return Promise.reject(error);
        }
    };

export const deleteAllManuscriptDeletedScenes =
    (manuscript: Manuscript, userId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            const collectionRef = collection(db, "deletedScenes");
            const q = query(
                collectionRef,
                where("owner", "==", userId),
                where("manuscriptId", "==", manuscript.id)
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                if (isDev) {
                    console.log("%cRemoving Deleted Scenes", "color:cyan");
                }
                const batch = writeBatch(db);
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                });
                await batch.commit();
            } else {
                if (isDev) {
                    console.log("%cNo deleted scenes to remove", "color:cyan");
                }
            }
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    };

export const getManuscript =
    (id: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.GET_MANUSCRIPT_START });
            const docRef = doc(db, "manuscripts", id);
            const docSnap = await getDoc(docRef);
            if (!docSnap) {
                throw new Error("Could not find manuscript in db!");
            }
            const ms = docSnap.data() as Manuscript;
            dispatch({ type: ActionType.GET_MANUSCRIPT_SUCCESS, payload: ms });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: ActionType.GET_MANUSCRIPT_FAIL });
            return Promise.reject(error);
        }
    };

export const updateManuscript =
    (
        manuscript: Partial<Manuscript> | Manuscript,
        updatedAt?: string
    ): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.UPDATE_MANUSCRIPT_START });
            if (!manuscript) {
                throw new Error("No manuscript!");
            }
            if (!manuscript.id) {
                throw new Error("No manuscript ID!");
            }
            const date = dayjs().format();
            const payload = { ...manuscript, updatedAt: updatedAt || date };
            await setDoc(doc(db, "manuscripts", manuscript.id), payload, {
                merge: true,
            });
            dispatch({
                type: ActionType.UPDATE_MANUSCRIPT_SUCCESS,
                payload,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: ActionType.UPDATE_MANUSCRIPT_FAIL });
            return Promise.reject(error);
        }
    };

export const createSection =
    (title: string, synopsis: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.CREATE_SECTION_START,
            });
            const state = getState().app;
            if (!state.draftManuscript) {
                throw new Error("No draft manuscript!");
            }
            // const titleSlug = slugify(title, { lower: true });
            const existingSections = state.draftManuscript.sections;
            const order = !state.activeSection
                ? existingSections.length === 0
                    ? 0
                    : existingSections.length + 1
                : state.activeSection.order + 1;
            if (isDev) {
                console.log(
                    "Order of active section",
                    state.activeSection?.order || "no active section"
                );
                console.log("Section should be created with order:", order);
            }
            const date = dayjs().format();

            const sectionPayload: Section = {
                createdAt: date,
                excludeFromCompile: false,
                id: uuidv4(),
                manuscriptId: state.draftManuscript.id,
                notes: "",
                order,
                status: SceneSectionStatus.NO_STATUS,
                storyArc: "Main",
                subtitle: title,
                synopsis,
                title,
                updatedAt: date,
                useSubtitle: false,
            };
            const sections = getReordered(
                order,
                sectionPayload,
                state.draftManuscript.sections
            );
            const manuscriptPayload: Partial<Manuscript> = {
                ...state.draftManuscript,
                sections: sections as Section[],
            };
            if (isDev) {
                console.log("Updated Manuscript", manuscriptPayload);
            }
            await dispatch(updateManuscript(manuscriptPayload, date));
            dispatch({
                type: ActionType.CREATE_SECTION_SUCCESS,
                payload: {
                    manuscript: manuscriptPayload,
                    section: sectionPayload,
                },
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.CREATE_SECTION_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const deleteSection =
    (
        manuscript: Manuscript,
        moveScenesTo: string | undefined,
        scenes: Scene[],
        section: Section,
        userId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.DELETE_SECTION_START });
            const date = dayjs().format();
            const collectionRef = collection(db, "scenes");
            const q = query(
                collectionRef,
                where("owner", "==", userId),
                where("manuscriptId", "==", manuscript.id),
                where("sectionId", "==", section.id)
            );
            if (moveScenesTo && scenes.length) {
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    if (isDev) {
                        console.log(
                            "%cNo scenes to update.  Nothing to do",
                            "color:cyan"
                        );
                    }
                } else {
                    if (isDev) {
                        console.log("%cUpdating scenes", "color:cyan");
                        const section = manuscript.sections.find(
                            (item) => item.id === moveScenesTo
                        );
                        if (!section) {
                            throw new Error(
                                `%cNo section found with ID of ${moveScenesTo}`
                            );
                        } else {
                            console.log(
                                `%cMoving scenes to section: ${
                                    section?.title || "n/a"
                                } `,
                                "color:cyan"
                            );
                        }
                    }
                    const batch = writeBatch(db);
                    querySnapshot.forEach((doc) => {
                        batch.update(doc.ref, {
                            sectionId: moveScenesTo,
                            updatedAt: date,
                        });
                    });
                    await batch.commit();
                }
            } else if (!moveScenesTo && scenes.length) {
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    if (isDev) {
                        console.log("%cNo scenes to delete", "color:cyan");
                    }
                } else {
                    if (isDev) {
                        console.log("%cMoving Scenes to Trash", "color:cyan");
                    }
                    const moveBatch = writeBatch(db);
                    querySnapshot.forEach((document) => {
                        const deletedScene: Scene = {
                            ...(document.data() as Scene),
                            deletedOn: date,
                        };
                        const docRef = doc(
                            db,
                            "deletedScenes",
                            document.ref.id
                        );
                        moveBatch.set(docRef, deletedScene);
                    });
                    const deleteBatch = writeBatch(db);
                    querySnapshot.forEach((doc) => {
                        deleteBatch.delete(doc.ref);
                    });
                    await moveBatch.commit();
                    await deleteBatch.commit();
                }
            }
            const payload: Manuscript = {
                ...manuscript,
                sections: manuscript.sections.filter(
                    (item) => item.id !== section.id
                ),
            };
            await dispatch(updateManuscript(payload));
            dispatch({ type: ActionType.DELETE_SECTION_SUCCESS, payload });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: ActionType.DELETE_SECTION_FAIL });
            return Promise.reject(error);
        }
    };

export const createScene =
    (
        saveDraftScene: boolean,
        title: string,
        synopsis: string,
        userId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.CREATE_SCENE_START,
            });
            const state = getState().app;
            if (!state.draftManuscript) {
                throw new Error("No draft manuscript!");
            }
            if (!state.activeSection) {
                throw new Error("No active section!");
            }
            if (saveDraftScene) {
                console.log(
                    "%cSaving active scene, because it's dirty!",
                    "color:hotpink"
                );
                await dispatch(saveActiveScene());
            }
            const text = `# ${title}\n\n`;
            const date = dayjs().format();
            const wordCount = getWordCount(text);

            if (isDev) {
                console.log(
                    "%cWhy is state.activeSection wanting a ? when I've guarded it above?!",
                    "color:orange"
                );
            }
            let existingScenes: Scene[] = state.scenes.filter(
                (item) => item.sectionId === state.activeSection?.id
            );
            if (isDev) {
                console.log(
                    "%cExisting scenes",
                    "color:orange",
                    existingScenes
                );
                console.log(
                    "%cExisting scenes map",
                    "color:orange",
                    existingScenes.map((item) => ({
                        scene: item.title,
                        order: item.order,
                    }))
                );
                console.log(
                    "%cActive Scene Order",
                    "color:orange",
                    state.activeScene?.order
                );
            }
            const order = !state.activeScene
                ? existingScenes.length === 0
                    ? 0
                    : existingScenes.length
                : state.activeScene.order + 1;

            const sceneId = uuidv4();

            const scenePayload: Scene = {
                createdAt: date,
                excludeFromCompile: false,
                id: sceneId,
                manuscriptId: state.draftManuscript.id,
                notes: "",
                order,
                owner: userId,
                sectionId: state.activeSection.id,
                backups: [],
                status: SceneSectionStatus.NO_STATUS,
                synopsis,
                text,
                title,
                updatedAt: date,
                wordCount,
                versions: [],
            };

            // const versionedScene = getVersionedScene(scenePayload);

            await setDoc(doc(db, "scenes", scenePayload.id), scenePayload);
            // await addDoc(
            //     collection(db, "scenes", scenePayload.id),
            //     scenePayload
            // );

            if (isDev) {
                console.log(
                    "%cExisting Scenes Length",
                    "color:orange",
                    existingScenes.length
                );
                console.log("%cOrder", "color:orange", order);
            }

            if (existingScenes.length === 0) {
                if (isDev) {
                    console.log("%cNo existing scenes.", "color:orange");
                }
                dispatch({
                    type: ActionType.CREATE_SCENE_SUCCESS,
                    payload: { scene: scenePayload, scenes: [scenePayload] },
                });
                // Don't reorder if new scene is being added at the end!
            } else if (order >= existingScenes.length) {
                if (isDev) {
                    console.log(
                        "%cScene going to end. No reorder.",
                        "color:orange"
                    );
                }
                scenePayload.order = existingScenes.length;
                dispatch({
                    type: ActionType.CREATE_SCENE_SUCCESS,
                    payload: {
                        scene: scenePayload,
                        scenes: [...existingScenes, scenePayload],
                    },
                });
            } else if (order < existingScenes.length) {
                if (isDev) {
                    console.log(
                        "%cScene is inserted.  Reordering...",
                        "color:orange"
                    );
                }

                const reorderedScenes = getReordered(
                    order,
                    scenePayload,
                    [...existingScenes].sort((a, b) => a.order - b.order)
                );
                const sceneOrders: Record<string, number> = {};
                reorderedScenes.forEach((item) => {
                    sceneOrders[item.id] = item.order;
                });
                const collectionRef = collection(db, "scenes");
                const q = query(
                    collectionRef,
                    where("owner", "==", userId),
                    where("manuscriptId", "==", state.draftManuscript.id),
                    where("sectionId", "==", state.activeSection.id)
                );
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    if (isDev) {
                        console.log(
                            "%cNo scenes found in Firestore.  This should not happen.",
                            "color:hotpink"
                        );
                    }
                    throw new Error("No scenes found in Firestore");
                } else {
                    const batch = writeBatch(db);
                    querySnapshot.forEach((doc) => {
                        const order = sceneOrders[doc.ref.id];
                        const sceneTitle = doc.data().title;
                        if (order !== undefined) {
                            batch.update(doc.ref, {
                                order,
                                updatedAt: date,
                            });
                        } else {
                            if (isDev) {
                                console.log(
                                    `%cThere is no order for ${sceneTitle}`,
                                    "color:hotpink"
                                );
                            }
                        }
                    });
                    await batch.commit();
                    dispatch({
                        type: ActionType.CREATE_SCENE_SUCCESS,
                        payload: {
                            scene: scenePayload,
                            scenes: reorderedScenes,
                        },
                    });
                }
            }
            await dispatch(updateManuscript(state.draftManuscript, date));
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.CREATE_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const deleteScene =
    (scene: Scene, userId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.DELETE_SCENE_START,
            });
            const state = getState().app;
            if (!state.draftManuscript) {
                throw new Error("No active manuscript!");
            }
            if (!state.activeSection) {
                throw new Error("No active section!");
            }
            if (!scene.id) {
                throw new Error("No scene ID!");
            }
            const date = dayjs().format();

            const existingScenes = state.scenes
                .filter((item) => item.sectionId === state.activeSection?.id)
                .sort((a, b) => a.order - b.order);
            const activeSceneIndex = existingScenes.indexOf(scene);
            if (isDev) {
                console.log(
                    "%cActive Scene Index",
                    "color:orange",
                    activeSceneIndex
                );
                console.log(
                    "%cExisting scenes length -1",
                    "color:orange",
                    existingScenes.length - 1
                );
            }
            const needToReorder =
                existingScenes.length > 1 &&
                activeSceneIndex < existingScenes.length - 1;

            const deletedScene: Scene = { ...scene, deletedOn: date };
            await setDoc(doc(db, "deletedScenes", scene.id), deletedScene);
            await deleteDoc(doc(db, "scenes", scene.id));
            let updatedScenes: Scene[] = [];
            if (needToReorder) {
                if (isDev) {
                    console.log(
                        "%cReordering scenes after scene delete...",
                        "color:cyan"
                    );
                }
                const sceneOrders: Record<string, number> = {};
                const remainingScenes = existingScenes
                    .filter((item) => item.id !== scene.id)
                    .sort((a, b) => a.order - b.order);
                if (isDev) {
                    console.log(
                        "%cRemaining Scenes",
                        "color:orange",
                        remainingScenes
                    );
                }
                remainingScenes.forEach((item, index) => {
                    sceneOrders[item.id] = index;
                });
                const collectionRef = collection(db, "scenes");
                const q = query(
                    collectionRef,
                    where("owner", "==", userId),
                    where("manuscriptId", "==", state.draftManuscript.id),
                    where("sectionId", "==", scene.sectionId)
                );
                const querySnapshot = await getDocs(q);
                const batch = writeBatch(db);
                querySnapshot.forEach((doc) => {
                    const scene = doc.data() as Scene;
                    const sceneTitle = scene.title;
                    const order = sceneOrders[doc.ref.id];
                    if (order !== undefined) {
                        updatedScenes.push({
                            ...scene,
                            order,
                            updatedAt: date,
                        });
                        batch.update(doc.ref, {
                            order,
                            updatedAt: date,
                        });
                    } else {
                        if (isDev) {
                            console.log(
                                `%cThere is no order for ${sceneTitle}`,
                                "color:hotpink"
                            );
                        }
                    }
                });
                await batch.commit();
            } else {
                if (isDev) {
                    console.log(
                        "%cNot reordering scenes after scene delete",
                        "color:cyan"
                    );
                }
                const filtered = existingScenes.filter(
                    (item) => item.id !== scene.id
                );
                if (isDev) {
                    console.log("%cFiltered", "color:hotpink", filtered);
                }
                updatedScenes = filtered;
            }
            await dispatch(updateManuscript(state.draftManuscript, date));
            const deleteScenePayload = {
                scenes: updatedScenes,
                sceneIdToDelete: scene.id,
            };
            dispatch({
                type: ActionType.DELETE_SCENE_SUCCESS,
                payload: deleteScenePayload,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.DELETE_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

// export const deleteAllManuscriptSceneBackups =
//     (manuscriptId: string): AppThunk<Promise<void>> =>
//     async (dispatch) => {
//         try {
//             dispatch({
//                 type: ActionType.DELETE_ALL_MANUSCRIPT_SCENE_BACKUPS_START,
//             });
//             const collectionRef = collection(db, "sceneBackups");
//             const q = query(
//                 collectionRef,
//                 where("manuscriptId", "==", manuscriptId)
//             );
//             const querySnapshot = await getDocs(q);
//             if (querySnapshot.empty) {
//                 if (isDev) {
//                     console.log(
//                         "%cNo backup scenes to delete.  Nothing to do",
//                         "color:cyan"
//                     );
//                 }
//             } else {
//                 if (isDev) {
//                     console.log("%cDeleting backups...", "color:cyan");
//                 }
//                 const batch = writeBatch(db);
//                 querySnapshot.forEach((doc) => {
//                     batch.delete(doc.ref);
//                 });
//                 await batch.commit();
//             }
//             dispatch({
//                 type: ActionType.DELETE_ALL_MANUSCRIPT_SCENE_BACKUPS_SUCCESS,
//             });
//             return Promise.resolve();
//         } catch (error) {
//             dispatch({
//                 type: ActionType.DELETE_ALL_MANUSCRIPT_SCENE_BACKUPS_FAIL,
//             });
//             return Promise.reject(error);
//         }
//     };

// export const removeSceneBackups =
//     (scene: Scene): AppThunk<Promise<void>> =>
//     async (dispatch) => {
//         try {
//             dispatch({ type: ActionType.REMOVE_SCENE_BACKUPS_START });
//             const collectionRef = collection(db, "sceneBackups");
//             const q = query(
//                 collectionRef,
//                 where("originalSceneId", "==", scene.id)
//             );
//             const querySnapshot = await getDocs(q);
//             if (querySnapshot.empty) {
//                 if (isDev) {
//                     console.log(
//                         "%cNo backup scenes to delete.  Nothing to do",
//                         "color:cyan"
//                     );
//                 }
//             } else {
//                 if (isDev) {
//                     console.log("%cDeleting backups...", "color:cyan");
//                 }
//                 const batch = writeBatch(db);
//                 querySnapshot.forEach((doc) => {
//                     batch.delete(doc.ref);
//                 });
//                 await batch.commit();
//             }
//             dispatch({ type: ActionType.REMOVE_SCENE_BACKUPS_SUCCESS });
//             return Promise.resolve();
//         } catch (error) {
//             dispatch({ type: ActionType.REMOVE_SCENE_BACKUPS_FAIL });
//             return Promise.reject(error);
//         }
//     };

export const removeDeletedScene =
    (scene: Scene): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionType.REMOVE_DELETED_SCENE_START,
            });
            dispatch({
                type: ActionType.REMOVE_DELETED_SCENE_SUCCESS,
                payload: scene.id,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.REMOVE_DELETED_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const cleanScene =
    (sceneId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionType.CLEAN_SCENE_START,
            });
            await deleteDoc(doc(db, "scenes", sceneId));
            dispatch({
                type: ActionType.CLEAN_SCENE_SUCCESS,
                payload: sceneId,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.CLEAN_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const moveScene =
    (
        sceneToMove: Scene,
        destinationSectionId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.MOVE_SCENE_START,
            });
            const {
                app: state,
                user: { id: userId },
            } = getState();
            if (!state.draftManuscript) {
                throw new Error("No active manuscript!");
            }
            if (!userId) {
                throw new Error("No user ID!");
            }
            // if (!state.activeSection) {
            //     throw new Error("No active section!");
            // }
            // if (!state.activeScene) {
            //     throw new Error("No active scene!");
            // }
            // if (!sceneToMove.id) {
            //     throw new Error("No sceneToMove ID!");
            // }
            const date = dayjs().format();
            const scenesInDestination = state.scenes.filter(
                (scene) => scene.sectionId === destinationSectionId
            );

            const scenePayload: Scene = {
                ...sceneToMove,
                order: scenesInDestination.length,
                sectionId: destinationSectionId,
                updatedAt: date,
            };

            await setDoc(doc(db, "scenes", sceneToMove.id), scenePayload, {
                merge: true,
            });

            const originalSectionId = sceneToMove.sectionId;
            console.log(
                "%cOriginal section ID",
                "color:orange",
                originalSectionId
            );
            console.log("%cState scenes", "color:orange", state.scenes);
            const remainingScenes = state.scenes
                .filter(
                    (scene) =>
                        scene.sectionId === originalSectionId &&
                        scene.id !== sceneToMove.id
                )
                .sort((a, b) => a.order - b.order)
                .map((item, index) => ({ ...item, order: index }));
            console.log("%cRemaining scenes", "color:orange", remainingScenes);
            // only query if there are scenes in section from which the scene was removed
            const updatedScenes: Scene[] = [];
            if (remainingScenes.length > 0) {
                const collectionRef = collection(db, "scenes");
                // query scenes for matches to sectionID of original scene
                const q = query(
                    collectionRef,
                    where("owner", "==", userId),
                    where("manuscriptId", "==", state.draftManuscript.id),
                    where("sectionId", "==", originalSectionId)
                );
                const querySnapshot = await getDocs(q);
                // batch update order of all scenes
                if (querySnapshot.empty) {
                    if (isDev) {
                        console.log(
                            "%cNo scenes to update.  Nothing to do",
                            "color:cyan"
                        );
                    }
                } else {
                    if (isDev) {
                        console.log(
                            "%cUpdating scenes remaining in original section",
                            "color:cyan"
                        );
                    }
                    const sceneOrders: Record<string, number> = {};
                    remainingScenes.forEach((scene, index) => {
                        sceneOrders[scene.id] = index;
                    });
                    const batch = writeBatch(db);
                    querySnapshot.forEach((doc) => {
                        const scene = doc.data() as Scene;
                        const order = sceneOrders[doc.ref.id];
                        const sceneTitle = scene.title;
                        if (order !== undefined) {
                            updatedScenes.push({
                                ...scene,
                                order,
                                updatedAt: date,
                            });
                            batch.update(doc.ref, {
                                order,
                                updatedAt: date,
                            });
                        } else {
                            if (isDev) {
                                console.log(
                                    `%cThere is no order for ${sceneTitle}`,
                                    "color:hotpink"
                                );
                            }
                        }
                    });
                    await batch.commit();
                }
            }
            console.log(
                "%cDo I still need to update the sections here!?",
                "color:orange"
            );
            await dispatch(updateManuscript(state.draftManuscript, date));
            // need all updated scenes for payload!
            console.log("updated remaining scenes", updatedScenes);
            const scenesPayload = [...updatedScenes, scenePayload];
            console.log("Move scenes payload", scenesPayload);
            dispatch({
                type: ActionType.MOVE_SCENE_SUCCESS,
                payload: {
                    scene: scenePayload,
                    scenes: scenesPayload,
                },
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.MOVE_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const renameScene =
    (scene: Scene): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.RENAME_SCENE_START,
            });
            const state = getState().app;
            if (!state.draftManuscript) {
                throw new Error("No active manuscript!");
            }
            if (!scene?.id) {
                throw new Error("No scene ID!");
            }
            const date = dayjs().format();

            const scenePayload: Scene = {
                ...scene,
                updatedAt: date,
            };

            await setDoc(doc(db, "scenes", scene.id), scenePayload, {
                merge: true,
            });
            await dispatch(updateManuscript(state.draftManuscript, date));
            dispatch({
                type: ActionType.RENAME_SCENE_SUCCESS,
                payload: scenePayload,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.RENAME_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const restoreDeletedScene =
    (
        scene: Scene,
        manuscript: Manuscript,
        section: Section
    ): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionType.RESTORE_DELETED_SCENE_START,
            });
            await setDoc(doc(db, "scenes", scene.id), scene);
            await deleteDoc(doc(db, "deletedScenes", scene.id));
            await dispatch(updateManuscript(manuscript));
            dispatch({
                type: ActionType.RESTORE_DELETED_SCENE_SUCCESS,
                payload: { scene, section },
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.RESTORE_DELETED_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const restoreSceneFromLocalStorage =
    (scene: Scene): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const state = getState().app;
            dispatch({
                type: ActionType.RESTORE_SCENE_FROM_LOCAL_STORAGE_START,
            });
            if (!state.draftManuscript) {
                throw new Error("No active manuscript!");
            }
            const date = dayjs().format();
            const wordCount = getWordCount(scene.text);
            const trimmed = scene.text.trim();
            const scenePayload: Scene = {
                ...scene,
                text: trimmed,
                wordCount,
                updatedAt: date,
            };
            const versionedScene = getVersionedScene(scenePayload);

            await setDoc(doc(db, "scenes", scene.id), versionedScene, {
                merge: true,
            });
            await dispatch(updateManuscript(state.draftManuscript, date));
            dispatch({
                type: ActionType.RESTORE_SCENE_FROM_LOCAL_STORAGE_SUCCESS,
                payload: versionedScene,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.RESTORE_SCENE_FROM_LOCAL_STORAGE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const saveActiveScene =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            const state = getState().app;
            dispatch({
                type: ActionType.SAVE_ACTIVE_SCENE_START,
                payload: state.activeScene?.title,
            });
            if (!state.draftManuscript) {
                throw new Error("No active manuscript!");
            }
            // should allow scene to be saved as blank
            // if (!state.draftActiveSceneText) {
            //     throw new Error("No draft active scene text!");
            // }
            if (!state.activeSection) {
                throw new Error("No active section!");
            }
            if (!state.activeScene) {
                throw new Error("No active scene!");
            }
            if (!state.activeScene.id) {
                throw new Error("No active scene ID!");
            }
            const date = dayjs().format();
            const trimmed = state.draftActiveSceneText.trim();
            const wordCount = getWordCount(trimmed);

            const scenePayload: Scene = {
                ...state.activeScene,
                text: trimmed,
                wordCount,
                updatedAt: date,
            };

            const versionPayload = getVersionedScene(scenePayload);

            await setDoc(
                doc(db, "scenes", state.activeScene.id),
                versionPayload,
                { merge: true }
            );
            await dispatch(updateManuscript(state.draftManuscript, date));
            dispatch({
                type: ActionType.SAVE_ACTIVE_SCENE_SUCCESS,
                payload: versionPayload,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.SAVE_ACTIVE_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const getSceneBackup =
    (id: string): AppThunk<Promise<SceneBackup>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionType.GET_BACKUP_SCENE_START,
            });
            const docSnap = await getDoc(doc(db, "sceneBackups", id));
            if (!docSnap) {
                throw new Error("Could not find backup in db!");
            }
            const backupScene = docSnap.data();
            dispatch({
                type: ActionType.GET_BACKUP_SCENE_SUCCESS,
            });
            return Promise.resolve(backupScene as SceneBackup);
        } catch (error) {
            dispatch({
                type: ActionType.GET_BACKUP_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const saveSceneBackup =
    (scene: Scene): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionType.SAVE_SCENE_BACKUP_START,
            });
            await setDoc(doc(db, "scenes", scene.id), scene, {
                merge: true,
            });
            // await setDoc(doc(db, "sceneBackups", backup.id), backup);
            dispatch({
                type: ActionType.SAVE_SCENE_BACKUP_SUCCESS,
                payload: scene,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.SAVE_SCENE_BACKUP_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const saveSceneVersion =
    (scene: Scene): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionType.SAVE_SCENE_VERSION_START,
            });
            await setDoc(doc(db, "scenes", scene.id), scene, {
                merge: true,
            });
            dispatch({
                type: ActionType.SAVE_SCENE_VERSION_SUCCESS,
                payload: scene,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.SAVE_SCENE_VERSION_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const deleteSceneBackup =
    (scene: Scene): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionType.DELETE_SCENE_BACKUP_START,
            });

            const versionedScene = getVersionedScene(scene);
            await setDoc(doc(db, "scenes", versionedScene.id), versionedScene, {
                merge: true,
            });
            // await deleteDoc(doc(db, "sceneBackups", backup.id));
            dispatch({
                type: ActionType.DELETE_SCENE_BACKUP_SUCCESS,
                payload: versionedScene,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.DELETE_SCENE_BACKUP_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const saveUnsavedScene =
    (scene: Scene): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.SAVE_UNSAVED_SCENE_START,
            });
            const { draftManuscript, navigateToTargetScene } = getState().app;
            if (!draftManuscript) {
                throw new Error("No draft manuscript!");
            }
            const date = dayjs().format();

            const scenePayload = { ...scene, updatedAt: date };

            const versionedScene = getVersionedScene(scenePayload);

            await setDoc(doc(db, "scenes", scene.id), versionedScene, {
                merge: true,
            });
            await dispatch(updateManuscript(draftManuscript, date));
            dispatch({
                type: ActionType.SAVE_UNSAVED_SCENE_SUCCESS,
                payload: {
                    navigateToTargetScene,
                    updatedScene: versionedScene,
                },
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.SAVE_UNSAVED_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const updateScene =
    (
        scene: Scene,
        updates: Partial<Scene>,
        section: Section
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.UPDATE_SCENE_START,
            });
            const { draftManuscript } = getState().app;
            if (!draftManuscript) {
                throw new Error("No draft manuscript!");
            }
            if (!updates.id) {
                throw new Error("No scene ID!");
            }
            const date = dayjs().format();

            await setDoc(
                doc(db, "scenes", updates.id),
                { ...updates, updatedAt: date },
                {
                    merge: true,
                }
            );
            await dispatch(updateManuscript(draftManuscript, date));
            dispatch({
                type: ActionType.UPDATE_SCENE_SUCCESS,
                payload: { scene, section },
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.UPDATE_SCENE_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const updateSceneOrder =
    (scenes: Scene[]): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: ActionType.UPDATE_SCENE_ORDER_START,
            });
            const state = getState().app;
            if (!state.draftManuscript) {
                throw new Error("No active manuscript!");
            }
            // if (!state.activeSection) {
            //     throw new Error("No active section!");
            // }
            const date = dayjs().format();
            console.log("%cScenes to reorder", "color:orange", scenes);
            const sceneIds = scenes.map((item) => item.id);
            console.log("%cScene IDs", "color:orange", sceneIds);
            const collectionRef = collection(db, "scenes");
            const q = query(collectionRef, where("id", "in", sceneIds));

            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                if (isDev) {
                    console.log(
                        "%cNo scenes match query.  Nothing to do",
                        "color:hotpink"
                    );
                }
                throw new Error("No scenes match query!");
            } else {
                if (isDev) {
                    const sectionId = scenes[0].sectionId;
                    const sectionTitle = state.draftManuscript.sections.find(
                        (section) => section.id === sectionId
                    )?.title;
                    console.log(
                        `%cUpdating scene order in ${sectionTitle}`,
                        "color:cyan",
                        scenes.map((item) => ({
                            scene: item.title,
                            order: item.order,
                        }))
                    );
                }
                const sceneOrders: Record<string, number> = {};
                scenes.forEach((scene, index) => {
                    sceneOrders[scene.id] = index;
                });
                const batch = writeBatch(db);
                const scenesToBeUpdated: Scene[] = [];
                querySnapshot.forEach((doc) => {
                    const scene: Scene = doc.data() as Scene;
                    const order = sceneOrders[doc.ref.id];
                    const sceneTitle = scene.title;
                    if (order !== undefined) {
                        scenesToBeUpdated.push({
                            ...scene,
                            order,
                            updatedAt: date,
                        });
                        batch.update(doc.ref, {
                            order,
                            updatedAt: date,
                        });
                    } else {
                        if (isDev) {
                            console.log(
                                `%cThere is no order for ${sceneTitle}`,
                                "color:hotpink"
                            );
                        }
                    }
                });
                await batch.commit();
                if (isDev) {
                    console.log("updated scenes", scenesToBeUpdated);
                }
            }

            await dispatch(updateManuscript(state.draftManuscript, date));
            dispatch({
                type: ActionType.UPDATE_SCENE_ORDER_SUCCESS,
                payload: scenes,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: ActionType.UPDATE_SCENE_ORDER_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const getDeletedScenes =
    (msId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.GET_DELETED_SCENES_START });
            const q = query(
                collection(db, "deletedScenes"),
                where("manuscriptId", "==", msId)
            );
            const querySnapshot = await getDocs(q);
            const scenes: Scene[] = [];
            querySnapshot.forEach((doc) => {
                const id = doc.ref.id;
                scenes.push({ ...doc.data(), id } as Scene);
            });
            dispatch({
                type: ActionType.GET_DELETED_SCENES_SUCCESS,
                payload: scenes,
            });
        } catch (error) {
            dispatch({ type: ActionType.GET_DELETED_SCENES_FAIL });
            return Promise.reject(error);
        }
    };

export const getManuscripts =
    (userId: string): AppThunk<Promise<Manuscript[]>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.GET_MANUSCRIPTS_START });
            if (isDev) {
                console.log(
                    "%cMake sure user can only see their stuff!",
                    "color:orange"
                );
            }
            const q = query(
                collection(db, "manuscripts"),
                where("owner", "==", userId)
            );
            const querySnapshot = await getDocs(q);
            const manuscripts: DocumentData[] = [];
            querySnapshot.forEach((doc) => {
                const id = doc.ref.id;
                manuscripts.push({ ...doc.data(), id });
            });

            dispatch({
                type: ActionType.GET_MANUSCRIPTS_SUCCESS,
                payload: manuscripts,
            });
            return Promise.resolve(manuscripts as Manuscript[]);
        } catch (error) {
            console.warn("Could not get manuscripts", error);
            dispatch({ type: ActionType.GET_MANUSCRIPTS_FAIL });
            return Promise.reject(error);
        }
    };

export const getManuscriptScenes =
    (manuscriptId: string, userId: string): AppThunk<Promise<Scene[]>> =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionType.GET_MANUSCRIPT_SCENES_START });
            const q = query(
                collection(db, "scenes"),
                where("owner", "==", userId),
                where("manuscriptId", "==", manuscriptId)
            );
            const querySnapshot = await getDocs(q);
            const scenes: DocumentData[] = [];
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const id = doc.ref.id;
                    scenes.push({ ...doc.data(), id });
                });
            }
            /* DO NOT SORT SCENES HERE!
               Scenes should only be sorted once they have been filtered by section.
            */
            dispatch({
                type: ActionType.GET_MANUSCRIPT_SCENES_SUCCESS,
                payload: scenes,
            });
            return Promise.resolve(scenes as Scene[]);
        } catch (error) {
            console.warn("Could not get manuscript scenes", error);
            dispatch({ type: ActionType.GET_MANUSCRIPT_SCENES_FAIL });
            return Promise.reject(error);
        }
    };

// export const listenToManuscripts =
//     (userId: string): AppThunk<void> =>
//     (dispatch) => {
//         try {
//             dispatch({ type: ActionType.LISTEN_TO_MANUSCRIPTS_START });
//             console.log(
//                 "%cMake sure user can only see their stuff!",
//                 "color:orange"
//             );
//             const ref = query(
//                 // collection(db, "users", userId, "manuscripts")
//                 collection(db, "manuscripts"),
//                 where("owner", "==", userId)
//             );
//             const unsubscribe = onSnapshot(ref, (querySnapshot) => {
//                 querySnapshot.docChanges().forEach((change: DocumentChange) => {
//                     if (isDev) {
//                         console.log(
//                             "%cManuscript Change Type",
//                             "color:cyan",
//                             change.type
//                         );
//                     }
//                     const docId = change.doc.ref.id;
//                     if (change.type === "added") {
//                         const payload = { ...change.doc.data(), id: docId };
//                         console.log("manuscript added", payload);

//                         dispatch({
//                             type: ActionType.MANUSCRIPT_ADD,
//                             payload,
//                         });
//                     }
//                     if (change.type === "modified") {
//                         const payload = { ...change.doc.data(), id: docId };
//                         console.log("manuscript modified", payload);
//                         dispatch({
//                             type: ActionType.MANUSCRIPT_UPDATE,
//                             payload,
//                         });
//                     }
//                     if (change.type === "removed") {
//                         const payload = { ...change.doc.data(), id: docId };
//                         console.log("manuscript removed", payload);
//                         dispatch({
//                             type: ActionType.MANUSCRIPT_DELETE,
//                             payload,
//                         });
//                     }
//                 });
//             });
//             dispatch({
//                 type: ActionType.LISTEN_TO_MANUSCRIPTS_SUCCESS,
//                 payload: unsubscribe,
//             });
//         } catch (error) {
//             console.warn("Could not listen to manuscripts", error);
//             dispatch({ type: ActionType.LISTEN_TO_MANUSCRIPTS_FAIL });
//         }
//     };

// export const stopListenToManuscripts =
//     (): AppThunk<void> => (dispatch, getState) => {
//         try {
//             dispatch({ type: ActionType.STOP_LISTEN_TO_MANUSCRIPTS_START });
//             const { manuscriptsUnsubscribe } = getState().app;
//             if (manuscriptsUnsubscribe) {
//                 manuscriptsUnsubscribe();
//             }
//             dispatch({ type: ActionType.STOP_LISTEN_TO_MANUSCRIPTS_SUCCESS });
//         } catch (error) {
//             dispatch({ type: ActionType.STOP_LISTEN_TO_MANUSCRIPTS_FAIL });
//         }
//     };

// export const getMarkdownManuscript2 =
//     (): AppThunk<Promise<string>> => async (dispatch, getState) => {
//         try {
//             dispatch({ type: ActionType.GET_MARKDOWN_MANUSCRIPT_START });
//             const { draftManuscript: manuscript, scenes } = getState().app;
//             if (!manuscript) {
//                 throw new Error("No draft manuscript!");
//             }
//             const sections = [...manuscript.sections]
//                 .filter((item) => !item.excludeFromCompile)
//                 .sort((a, b) => a.order - b.order);
//             // console.log("Sections", sections);
//             let text = "";
//             text += `# ${manuscript.title}`;
//             sections.forEach((section) => {
//                 const sectionScenes = scenes
//                     .filter((scene) => scene.sectionId === section.id)
//                     .sort((a, b) => a.order - b.order);

//                 // console.log(
//                 //     `Section Scenes for ${section.title}`,
//                 //     sectionScenes
//                 // );
//                 if (sections.indexOf(section) > 0) {
//                     text += `\n\n***`;
//                 }
//                 if (section.useSubtitle) {
//                     text += `\n\n## ${section.subtitle}`;
//                 }

//                 sectionScenes.forEach((scene) => {
//                     if (scene.text) {
//                         text += `\n\n${scene.text}`;
//                     }
//                 });
//             });

//             dispatch({
//                 type: ActionType.GET_MARKDOWN_MANUSCRIPT_SUCCESS,
//                 payload: text,
//             });
//             return Promise.resolve(text);
//         } catch (error) {
//             dispatch({ type: ActionType.GET_MARKDOWN_MANUSCRIPT_FAIL });
//             return Promise.reject(error);
//         }
//     };

class CustomFormData extends FormData {
    public append(name: string, value: any): void {
        if (Array.isArray(value)) {
            for (const val of value) {
                super.append(name, val);
            }
        } else {
            super.append(name, value);
        }
    }
}
export const getEpub =
    (text: string): AppThunk<Promise<Blob>> =>
    async (dispatch, getState) => {
        try {
            const {
                app,
                user: { id: userId, penName },
            } = getState();
            dispatch({ type: ActionType.GET_EPUB_START });
            if (!app.draftManuscript) {
                throw new Error("No draft manuscript!");
            }

            let coverPage: File | null = null;
            if (app.draftManuscript.coverPagePath) {
                const blob = await dispatch(
                    getCoverPageFile(app.draftManuscript.coverPagePath)
                );
                const file = new File([blob], "cover.jpg", {
                    type: "image/jpeg",
                });
                coverPage = file;
            }
            const metaDataResp = await fetch("./pandoc/metadata.txt");
            let metadata = await metaDataResp.text();
            metadata = metadata.replace("TITLE", app.draftManuscript.title);
            metadata = metadata.replace(
                "AUTHOR",
                app.draftManuscript.authorInfo?.penName ||
                    penName ||
                    "NO AUTHOR"
            );
            /* START For testing */
            // metadata += "\n";
            // metadata += `    - role: editor`;
            // metadata += "\n";
            // const editor = "Big Fat Mama!";
            // metadata += `      text: ${editor}`;
            // metadata += "\n";
            // metadata += `publisher: ${"Gatekeeper Press"}`;
            /* END For testing */
            // if (app.draftManuscript.editor) {
            //     metadata += "\n";
            //     metadata += `    - role: editor`;
            //     metadata += "\n";
            //     metadata += `      text: ${editor}`;
            // }
            // if (app.draftManuscript.publisher) {
            //     metadata += "\n";
            //     metadata += `publisher: ${app.draftManuscript.publisher}`;
            // }
            if (app.draftManuscript.copyright) {
                metadata += "\n";
                metadata += `rights: © ${app.draftManuscript.copyright}`;
            }
            metadata += "\n";
            metadata += `date: ${dayjs().format("MMMM YYYY")}`;
            let markdown = metadata;
            markdown += "\n";
            markdown += "---";
            if (isDev) {
                console.log("%cMETADATA\n", "color:orange", markdown);
            }
            markdown += "\n\n";
            markdown += text;
            // const payload = {
            //     standalone: true,
            //     text: markdown,
            //     to: "epub",
            // };
            // const payload = {
            //     text: markdown,
            //     title: app.draftManuscript.title,
            // };
            const formData = new CustomFormData();
            formData.append("text", markdown);
            formData.append("title", app.draftManuscript.title);
            formData.append("userId", userId);
            if (coverPage) {
                formData.append("cover", coverPage);
            }
            const url = isDev
                ? "http://localhost:3030"
                : process.env.REACT_APP_CLOUD_RUN_BASE_URL;
            if (!url) {
                throw new Error("No URL for Cloud Run in env vars!");
            }
            const resp = await fetch(`${url}/convert`, {
                method: "POST",
                // headers: {
                //     Accept: "application/json",
                //     "Content-Type": "application/json",
                // },
                // body: JSON.stringify(payload),
                body: formData,
            });
            if (isDev) {
                console.log("%cFETCH COVER RESPONSE", "color:orange", resp);
            }
            if (resp.status !== 200) {
                throw new Error(resp.statusText);
            }
            const blob = await resp.blob();
            // const data: {
            //     error: string;
            //     output: string;
            //     base64: boolean;
            //     messages: { message: string; verbosity: string }[];
            // } = await resp.json();
            // console.log("data", data);
            // if (data.error) {
            //     throw new Error(data.error);
            // }
            // if (data.messages.length > 0) {
            //     for (let i = 0; i < data.messages.length; i++) {
            //         console.log(
            //             `%c${data.messages[i].verbosity}: ${data.messages[i].message}`,
            //             "color:yellow"
            //         );
            //     }
            // }
            dispatch({ type: ActionType.GET_EPUB_SUCCESS });
            return Promise.resolve(blob);
        } catch (error: any) {
            dispatch({ type: ActionType.GET_EPUB_FAIL });
            console.warn(error.message);
            return Promise.reject(error);
        }
    };

export const getMarkdownManuscript =
    (): AppThunk<Promise<string>> => async (dispatch, getState) => {
        try {
            dispatch({ type: ActionType.GET_MARKDOWN_MANUSCRIPT_START });
            const {
                app: { draftManuscript: manuscript, scenes },
            } = getState();
            if (!manuscript) {
                throw new Error("No draft manuscript!");
            }
            const sections = [...manuscript.sections]
                .filter((item) => !item.excludeFromCompile)
                .sort((a, b) => a.order - b.order);
            let text = "";
            sections.forEach((section, index) => {
                const sorted = [...scenes]
                    .filter((scene) => scene.sectionId === section.id)
                    .sort((a, b) => a.order - b.order);
                if (index === 0 && section.title.toLowerCase() === "prologue") {
                    text += `# Prologue`;
                } else if (
                    index === 0 &&
                    section.title.toLowerCase() !== "prologue"
                ) {
                    text += `# ${getChapterTitle(index, sections, manuscript)}`;
                } else {
                    // (index > 0)
                    text += `\n\n# ${getChapterTitle(
                        index,
                        sections,
                        manuscript
                    )}`;
                }
                if (section.useSubtitle) {
                    text += `\n\n## ${section.subtitle}`;
                }

                sorted.forEach((scene, index) => {
                    if (scene.text) {
                        if (index > 0) {
                            text += `\n\n###### * * *`;
                        }
                        const paragraphs = scene.text.split("\n\n");
                        // paragraphs.forEach((p, index) => {
                        //     if (index === 0) {
                        //         text += `\n\n${p}`;
                        //     } else {
                        //         text += `\n\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${p}`;
                        //     }
                        // });
                        paragraphs.forEach((p, index) => {
                            const isScene = p && p.slice(0, 1) !== "#";
                            const isLastAHeading =
                                index > 0 &&
                                paragraphs[index - 1] &&
                                paragraphs[index - 1].slice(0, 1) === "#";
                            const isFirstP =
                                isScene &&
                                (isLastAHeading ||
                                    (!isLastAHeading && index === 0));
                            if (isFirstP) {
                                text += `\n\n${p}`;
                            } else {
                                text += `\n\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${p}`;
                            }
                        });
                    }
                });
            });
            dispatch({
                type: ActionType.GET_MARKDOWN_MANUSCRIPT_SUCCESS,
            });
            return Promise.resolve(text);
        } catch (error) {
            dispatch({ type: ActionType.GET_MARKDOWN_MANUSCRIPT_FAIL });
            return Promise.reject(error);
        }
    };

// const compileManuscript =
//     (): AppThunk<Promise<string>> => async (dispatch, getState) => {
//         try {
//             dispatch({ type: ActionType.COMPILE_MANUSCRIPT_START });
//             const {
//                 app: { draftManuscript: manuscript, scenes },
//                 user: { penName },
//             } = getState();
//             if (!manuscript) {
//                 throw new Error("No draft manuscript!");
//             }
//             const sections = [...manuscript.sections]
//                 .filter((item) => !item.excludeFromCompile)
//                 .sort((a, b) => a.order - b.order);
//             let text = "";
//             text += `% ${manuscript.title}`;
//             text += `\n\n% ${manuscript.authorInfo?.penName || penName}`;

//             // text += `# ${manuscript.title}`;
//             sections.forEach((section, index) => {
//                 const sectionScenes = scenes
//                     .filter((scene) => scene.sectionId === section.id)
//                     .sort((a, b) => a.order - b.order);

//                 // console.log(
//                 //     `Section Scenes for ${section.title}`,
//                 //     sectionScenes
//                 // );
//                 if (index === 0) {
//                     // if (sections.indexOf(section) > 0) {
//                     text += `\n\n# Chapter ${index + 1}`;
//                 } else {
//                     text += `\n\n***`;
//                     text += `\n\n# Chapter ${index + 1}`;
//                 }
//                 if (section.useSubtitle) {
//                     text += `\n\n## ${section.subtitle}`;
//                 }

//                 sectionScenes.forEach((scene) => {
//                     if (scene.text) {
//                         text += `\n\n${scene.text}`;
//                     }
//                 });
//             });

//             dispatch({
//                 type: ActionType.COMPILE_MANUSCRIPT_SUCCESS,
//                 // payload: text,
//             });
//             return Promise.resolve(text);
//         } catch (error) {
//             dispatch({ type: ActionType.COMPILE_MANUSCRIPT_FAIL });
//             return Promise.reject(error);
//         }
//     };

// export const compileManuscript2 =
//     (): AppThunk<Promise<string>> => async (dispatch, getState) => {
//         try {
//             dispatch({ type: ActionType.COMPILE_MANUSCRIPT_START });
//             const { draftManuscript: manuscript, scenes } = getState().app;
//             if (!manuscript) {
//                 throw new Error("No draft manuscript!");
//             }
//             const sections = [...manuscript.sections]
//                 .filter((item) => !item.excludeFromCompile)
//                 .sort((a, b) => a.order - b.order);
//             // console.log("Sections", sections);
//             let text = "";
//             text += `# ${manuscript.title}`;
//             sections.forEach((section) => {
//                 const sectionScenes = scenes
//                     .filter((scene) => scene.sectionId === section.id)
//                     .sort((a, b) => a.order - b.order);

//                 // console.log(
//                 //     `Section Scenes for ${section.title}`,
//                 //     sectionScenes
//                 // );
//                 if (sections.indexOf(section) > 0) {
//                     text += `\n\n***`;
//                 }
//                 if (section.useSubtitle) {
//                     text += `\n\n## ${section.subtitle}`;
//                 }

//                 sectionScenes.forEach((scene) => {
//                     if (scene.text) {
//                         text += `\n\n${scene.text}`;
//                     }
//                 });
//             });

//             dispatch({
//                 type: ActionType.COMPILE_MANUSCRIPT_SUCCESS,
//                 // payload: text,
//             });
//             return Promise.resolve(text);
//         } catch (error) {
//             dispatch({ type: ActionType.COMPILE_MANUSCRIPT_FAIL });
//             return Promise.reject(error);
//         }
//     };

export const getManuscriptWordCount =
    (): AppThunk<Promise<number>> => async (dispatch) => {
        try {
            const ms = await dispatch(getMarkdownManuscript());
            const wc = getWordCount(ms);
            return Promise.resolve(wc);
        } catch (error) {
            return Promise.reject(error);
        }
    };

export const getCoverPageFile =
    (path: string): AppThunk<Promise<Blob>> =>
    async (dispatch, getState) => {
        try {
            const {
                app: { draftManuscript },
            } = getState();
            if (!draftManuscript) {
                throw new Error("No draft manuscript!");
            }
            const storage = getStorage();
            const imageRef = ref(storage, path);
            const blob = await getBlob(imageRef);
            return Promise.resolve(blob);
        } catch (error) {
            return Promise.reject(error);
        }
    };

export const deleteCoverPage =
    (path: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const {
                app: { draftManuscript },
            } = getState();
            if (!draftManuscript) {
                throw new Error("No draft manuscript!");
            }
            const storage = getStorage();
            const imageRef = ref(storage, path);
            await deleteObject(imageRef);
            await dispatch(
                updateManuscript({ ...draftManuscript, coverPagePath: "" })
            );
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    };

export const getCoverPageUrl =
    (path: string): AppThunk<Promise<string>> =>
    async (dispatch) => {
        try {
            const storage = getStorage();
            const url = await getDownloadURL(ref(storage, path));
            return Promise.resolve(url);
        } catch (error) {
            return Promise.reject(error);
        }
    };

export const saveCoverPage =
    (file: File): AppThunk<Promise<string>> =>
    async (dispatch, getState) => {
        try {
            const {
                app: { draftManuscript },
                user: { id: userId },
            } = getState();
            if (!draftManuscript) {
                throw new Error("No draft manuscript!");
            }
            const storage = getStorage();
            const path = `coverImages/${userId}/${draftManuscript.id}/coverImage.jpg`;
            const imageRef = ref(storage, path);
            const resp = await uploadBytes(imageRef, file);
            if (isDev) {
                console.log("%cUPLOAD RESPONSE", "color:orange", resp);
            }
            await dispatch(
                updateManuscript({ ...draftManuscript, coverPagePath: path })
            );

            return Promise.resolve(path);
        } catch (error) {
            return Promise.reject(error);
        }
    };
// export const listenToScenes =
//     (manuscriptId: string, userId: string): AppThunk<void> =>
//     (dispatch, getState) => {
//         try {
//             dispatch({ type: ActionType.LISTEN_TO_SCENES_START });
//             const { scenesUnsubscribe } = getState().app;
//             if (scenesUnsubscribe) {
//                 scenesUnsubscribe();
//             }
//             console.log(
//                 "%cMake sure user can only see their stuff!",
//                 "color:orange"
//             );
//             const ref = query(
//                 // collection(db, "users", userId, "manuscripts")
//                 collection(db, "scenes"),
//                 where("owner", "==", userId),
//                 where("manuscriptId", "==", manuscriptId)
//             );
//             const unsubscribe = onSnapshot(ref, (querySnapshot) => {
//                 querySnapshot.docChanges().forEach((change: DocumentChange) => {
//                     if (isDev) {
//                         console.log(
//                             "%cScene Change Type",
//                             "color:cyan",
//                             change.type
//                         );
//                     }
//                     const docId = change.doc.ref.id;
//                     if (change.type === "added") {
//                         const payload = { ...change.doc.data(), id: docId };
//                         console.log("scene added", payload);

//                         dispatch({
//                             type: ActionType.SCENE_ADD,
//                             payload,
//                         });
//                     }
//                     if (change.type === "modified") {
//                         const payload = { ...change.doc.data(), id: docId };
//                         console.log("scene modified", payload);
//                         dispatch({
//                             type: ActionType.SCENE_UPDATE,
//                             payload,
//                         });
//                     }
//                     if (change.type === "removed") {
//                         const payload = { ...change.doc.data(), id: docId };
//                         console.log("scene removed", payload);
//                         dispatch({
//                             type: ActionType.SCENE_DELETE,
//                             payload,
//                         });
//                     }
//                 });
//             });
//             dispatch({
//                 type: ActionType.LISTEN_TO_SCENES_SUCCESS,
//                 payload: unsubscribe,
//             });
//         } catch (error) {
//             console.warn("Could not listen to manuscripts", error);
//             dispatch({ type: ActionType.LISTEN_TO_SCENES_FAIL });
//         }
//     };

// export const stopListenToScenes =
//     (): AppThunk<void> => (dispatch, getState) => {
//         try {
//             dispatch({ type: ActionType.STOP_LISTEN_TO_SCENES_START });
//             const { scenesUnsubscribe } = getState().app;
//             if (scenesUnsubscribe) {
//                 scenesUnsubscribe();
//             }
//             dispatch({ type: ActionType.STOP_LISTEN_TO_SCENES_SUCCESS });
//         } catch (error) {
//             dispatch({ type: ActionType.STOP_LISTEN_TO_SCENES_FAIL });
//         }
//     };
