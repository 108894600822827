import { AppDispatch } from "../store/store";
import { AppState } from "../reducers/AppReducer";
import {
    ChapterTitleStyle,
    LocalStorageAction,
    LocalStorageManuscriptScenes,
    LocalStorageScenePayload,
    SceneSectionStatus,
} from "../types";
import { saveActiveScene } from "../actions";
import { User } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import config from "../config/config.json";
import type { Agent, Manuscript, Scene, Section } from "../types";

const isDev = process.env.NODE_ENV === "development";

export function elideText(s: string, sliceAt: number) {
    if (s.length <= sliceAt) {
        return s;
    }
    return s.slice(0, sliceAt).trim() + "...";
}

export function getWordCount(s: string): number {
    if (!s) {
        return 0;
    } else if (s.length === 0) {
        return 0;
    } else {
        s = s.replace(/(^\s*)|(\s*$)/gi, ""); // exclude start and end white-space
        s = s.replace(/[ ]{2,}/gi, " "); // change 2 or more space to 1
        s = s.replace(/^#+\s/g, ""); // exclude markdown title hashes;
        // s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
        s = s.replace(/\n/g, " "); // exclude newline with a start spacing
        // const result = s.split(" ").filter(function (str) {
        //     return str != "";
        // });
        // console.log("word count result", result);
        return s.split(" ").filter(function (str) {
            return str !== "";
        }).length;
        //return s.split(' ').filter(String).length; - this can also be used
    }
}

export function getReordered(
    start: number,
    item: Scene | Section,
    items: Scene[] | Section[]
): Scene[] | Section[] {
    const temp = [...items];
    temp.splice(start, 0, item);
    if (isDev) {
        console.log(
            "%cSpliced scenes",
            "color:orange",
            temp.map((item) => ({ title: item.title, order: item.order }))
        );
    }
    const remapped = [...temp]
        .map((i, index) => ({ ...i, order: index }))
        .sort((a, b) => a.order - b.order);
    if ("sectionId" in item) {
        if (isDev) {
            console.log(
                "%cReordered scenes",
                "color:orange",
                remapped.map((item) => ({
                    title: item.title,
                    order: item.order,
                }))
            );
        }
        return remapped as Scene[];
    } else {
        if (isDev) {
            console.log(
                "%cReordered section",
                "color:orange",
                remapped.map((item) => ({
                    title: item.title,
                    order: item.order,
                }))
            );
        }
        return remapped as Section[];
    }
}

export function formatWordCount(wc: number): string {
    if (wc === 0) {
        return "0";
    }
    const arr = wc.toString().split("").reverse();
    let str = "";
    for (let i = 0; i < arr.length; i++) {
        if (i === 3 || i === 7 || i === 11) {
            str += `,${arr[i]}`;
        } else {
            str += arr[i];
        }
    }
    return str.split("").reverse().join("");
}

export function getRoundedWordCount(wordCount: string) {
    try {
        const value = parseInt(wordCount.replace(",", ""));
        const rounded = Math.round(value / 1000) * 1000;
        return formatWordCount(rounded);
    } catch (error) {
        console.warn(error);
    }
}

export async function handleSaveScene(
    dispatch: AppDispatch,
    isSceneDirty: boolean,
    state: AppState,
    user: User | null
) {
    try {
        if (!user) {
            throw new Error("No User!");
        }
        if (state.activeScene) {
            if (isSceneDirty) {
                if (isDev) {
                    console.log(
                        `%cSaving scene: ${state.activeScene.title}`,
                        "color:cyan"
                    );
                }
                if (user) {
                    await dispatch(saveActiveScene());
                } else {
                    throw new Error("No User!");
                }
            } else {
                if (isDev) {
                    console.log(
                        "%cScene is clean. Nothing to save.",
                        "color:cyan"
                    );
                }
            }
        } else {
            if (isDev) {
                console.log(
                    "%cNo Active Scene. Nothing to save.",
                    "color:cyan"
                );
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

export function getTextColorByStatus(
    status: SceneSectionStatus,
    isDark: boolean
) {
    switch (status) {
        case SceneSectionStatus.IN_PROGRESS:
            return "var(--bs-danger)";
        case SceneSectionStatus.FIRST_DRAFT:
            return "var(--bs-orange)";
        case SceneSectionStatus.REVISED_DRAFT:
            return "var(--bs-warning)";
        case SceneSectionStatus.FINAL_DRAFT:
            return "var(--bs-info)";
        case SceneSectionStatus.DONE:
            return isDark
                ? "var(--bs-success-text-emphasis)"
                : "var(--bs-success)";
        default:
            return;
    }
}

export function getSceneFromLocalStorage(
    manuscriptId: string,
    sceneId: string
): LocalStorageScenePayload | undefined {
    try {
        const item = localStorage.getItem(config.DRAFT_SCENE_KEY);
        if (item) {
            const parsed: LocalStorageManuscriptScenes = JSON.parse(item);
            if (parsed) {
                const manuscriptScenes = parsed[manuscriptId];
                if (manuscriptScenes) {
                    const match = manuscriptScenes.find(
                        (item) => item.sceneId === sceneId
                    );
                    return match;
                }
            }
        }
        return undefined;
    } catch (error) {
        console.warn(error);
    }
}

export function getScenesFromLocalStorage():
    | LocalStorageManuscriptScenes
    | undefined {
    try {
        //    create
        const item = localStorage.getItem(config.DRAFT_SCENE_KEY);
        if (item) {
            const parsed: LocalStorageManuscriptScenes = JSON.parse(item);
            return parsed;
        }
        return undefined;
    } catch (error) {
        console.warn(error);
    }
}

export function updateSceneInLocalStorage(
    action: LocalStorageAction,
    manuscriptId: string,
    sceneId: string,
    text?: string
) {
    try {
        const scenes: LocalStorageManuscriptScenes | undefined =
            getScenesFromLocalStorage();
        if (action === LocalStorageAction.DELETE_SCENE) {
            if (scenes && scenes[manuscriptId]) {
                let updatedScenes = { ...scenes };
                const manuscriptScenes = scenes[manuscriptId];
                const updatedManuscriptScenes = manuscriptScenes.filter(
                    (item) => item.sceneId !== sceneId
                );
                if (updatedManuscriptScenes.length === 0) {
                    delete updatedScenes[manuscriptId];
                } else {
                    updatedScenes[manuscriptId] = updatedManuscriptScenes;
                }
                const str = JSON.stringify(updatedScenes);
                localStorage.setItem(config.DRAFT_SCENE_KEY, str);
            } else {
                console.log(
                    "%cNo scenes in local storage!  Nothing to delete",
                    "color:orange"
                );
            }
        } else if (action === LocalStorageAction.UPDATE_SCENE) {
            console.log("updating");
            if (scenes && scenes[manuscriptId]) {
                console.log("there is scenes cookie in local storage");
                if (text === undefined) {
                    throw new Error("Text is undefined.  Not updating!");
                }
                console.log("there is text", text);
                let updatedScenes = { ...scenes };
                const manuscriptScenes = scenes[manuscriptId];
                const match = manuscriptScenes.find(
                    (item) => item.sceneId === sceneId
                );
                if (match) {
                    const updatedManuscriptScenes = manuscriptScenes.map(
                        (item) => {
                            if (item.sceneId === match.sceneId) {
                                return { ...item, text };
                            } else {
                                return item;
                            }
                        }
                    );
                    updatedScenes[manuscriptId] = updatedManuscriptScenes;
                } else {
                    updatedScenes[manuscriptId] = [
                        ...manuscriptScenes,
                        { sceneId, text },
                    ];
                }
                console.log("storing updated scenes", updatedScenes);
                const str = JSON.stringify(updatedScenes);
                localStorage.setItem(config.DRAFT_SCENE_KEY, str);
            } else {
                const payload = {
                    ...scenes,
                    [manuscriptId]: [{ sceneId, text }],
                };
                console.log(
                    "%cNo scenes in local storage!  Updating with new.",
                    "color:orange",
                    payload
                );
                const str = JSON.stringify(payload);
                localStorage.setItem(config.DRAFT_SCENE_KEY, str);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

export function getAgentName(agent: Agent, full: boolean = false): string {
    let name = "";
    if (agent.honorific) {
        name += `${agent.honorific} `;
    }
    if (full && agent.firstName) {
        name += `${agent.firstName} `;
    }
    if (agent.lastName) {
        name += `${agent.lastName}`;
    }
    return name;
}

export function getVersionedScene(scene: Scene): Scene {
    const maxLength = 4;
    const updatedScene = JSON.parse(JSON.stringify(scene));
    if (!updatedScene.versions) {
        updatedScene.versions = [];
    }
    const versionPayload = {
        id: uuidv4(),
        createdAt: scene.updatedAt,
        text: scene.text,
    };
    if (updatedScene.versions.length >= maxLength) {
        updatedScene.versions.shift();
    }
    updatedScene.versions.push(versionPayload);
    return updatedScene;
}

export function getChapterTitle(
    index: number,
    sections: Section[],
    manuscript?: Manuscript
) {
    if (manuscript && sections && sections.length) {
        if (sections[0].title.toLowerCase() === "prologue") {
            if (
                manuscript.chapterTitleStyle === ChapterTitleStyle.NUMBER_ONLY
            ) {
                return index;
            } else {
                return `Chapter ${index}`;
            }
        } else {
            if (
                manuscript.chapterTitleStyle === ChapterTitleStyle.NUMBER_ONLY
            ) {
                return index + 1;
            } else {
                return `Chapter ${index + 1}`;
            }
        }
    } else {
        if (!manuscript) {
            console.log("%cNo Manuscript!", "color:hotpink");
            throw new Error("No Manuscript!");
        }
        if (sections.length === 0) {
            console.log("%cNo Sections!", "color:hotpink");
            throw new Error("No Sections!");
        }
    }
}
