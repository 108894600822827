import { useContext, useMemo } from "react";
import { Nav } from "react-bootstrap";
import { HelpFeature } from "./Help";
import { ThemeContext } from "../../contexts/ThemeProvider";
import { useNavigate } from "react-router-dom";
import config from "../../config/config.json";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import poe from "../../assets/images/poe-icon.png";
import { useHotkeys } from "react-hotkeys-hook";

// const isDev = process.env.NODE_ENV === "development";

interface Props {
    isMed: boolean;
    isSmall: boolean;
    selectedFeature: HelpFeature;
    setSelectedFeature: (f: HelpFeature) => void;
}
export default function HelpNavbar({
    isMed,
    isSmall,
    selectedFeature,
    setSelectedFeature,
}: Props) {
    const { isDark, toggleTheme } = useContext(ThemeContext);
    const navigate = useNavigate();

    const { logoSize } = useMemo(() => {
        const iconFontSize = isSmall ? "fs-6" : "fs-3";
        const logoSize = isSmall ? 24 : 30;
        return { iconFontSize, logoSize };
    }, [isSmall]);

    useHotkeys("meta+j", () => toggleTheme(), {
        enableOnContentEditable: true,
    });
    // useHotkeys(
    //     "alt+meta+a",
    //     () => (role === UserRole.ADMIN ? navigate("/admin") : () => {}),
    //     {
    //         enableOnContentEditable: true,
    //     }
    // );
    // useHotkeys(
    //     "meta+alt+s",
    //     () => {
    //         handleSaveScene(dispatch, isSceneDirty, state, user);
    //     },
    //     { enableOnFormTags: true, preventDefault: true }
    // );

    // useEffect(() => {
    //     dispatch({
    //         type: SizeActionType.SET_SIZE,
    //         payload: { element: SizeElementType.NAVBAR, height, width },
    //     });
    // }, [dispatch, height, width]);

    // const { isSceneDirty } = useMemo(() => {
    //     let isManuscriptDirty = false;
    //     let isSceneDirty = false;
    //     if (
    //         JSON.stringify(state.activeManuscript) !==
    //         JSON.stringify(state.draftManuscript)
    //     ) {
    //         // if (isDev) {
    //         //     console.log("%cManuscript is dirty", "color:cyan");
    //         // }
    //         isManuscriptDirty = true;
    //     } else {
    //         // if (isDev) {
    //         //     console.log("%cManuscript is not dirty", "color:cyan");
    //         // }
    //         isManuscriptDirty = false;
    //     }
    //     if (
    //         state.activeScene &&
    //         state.activeScene.text !== state.draftActiveSceneText
    //     ) {
    //         // if (isDev) {
    //         //     console.log("%cScene is dirty", "color:cyan");
    //         //     console.log("%cActive", "color:cyan", state.activeScene.text);
    //         // }
    //         isSceneDirty = true;
    //     } else {
    //         isSceneDirty = false;
    //         // if (isDev) {
    //         //     console.log("%cScene is not dirty", "color:cyan");
    //         // }
    //     }
    //     return { isManuscriptDirty, isSceneDirty };
    // }, [
    //     state.activeManuscript,
    //     state.draftManuscript,
    //     state.activeScene,
    //     state.draftActiveSceneText,
    // ]);

    return (
        <Navbar className="w-100" bg="tertiary" expand={true}>
            <Container
                fluid
                className="d-flex align-items-center justify-content-between px-0"
            >
                <Navbar.Brand className="ps-2 me-1">
                    <div
                        className="d-flex align-items-center"
                        onClick={() => navigate(-1)}
                        style={{ cursor: "pointer" }}
                    >
                        <img
                            alt="Poe Logo"
                            src={poe}
                            width={logoSize}
                            height={logoSize}
                            style={{
                                filter: `invert(${isDark ? 1 : 0})`,
                                opacity: isDark ? 0.6 : 1,
                            }}
                        />{" "}
                        {!isMed && (
                            <span className="text-body-secondary ms-2">
                                {config.SITE_NAME}
                            </span>
                        )}
                    </div>
                </Navbar.Brand>
                <Nav className="ms-auto">
                    <Nav.Link onClick={() => navigate(-1)}>Home</Nav.Link>
                    {/* {isDev && (
                        <Nav.Link
                            active={selectedFeature === HelpFeature.DOCS}
                            onClick={() => setSelectedFeature(HelpFeature.DOCS)}
                        >
                            Docs
                        </Nav.Link>
                    )} */}
                    <Nav.Link
                        active={selectedFeature === HelpFeature.FAQ}
                        onClick={() => setSelectedFeature(HelpFeature.FAQ)}
                    >
                        FAQ
                    </Nav.Link>
                    <Nav.Link
                        active={selectedFeature === HelpFeature.ISSUES}
                        onClick={() => setSelectedFeature(HelpFeature.ISSUES)}
                    >
                        Issues
                    </Nav.Link>
                    <Nav.Link
                        active={selectedFeature === HelpFeature.CONTACT}
                        onClick={() => setSelectedFeature(HelpFeature.CONTACT)}
                    >
                        Contact
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}
