import { useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { IssueType } from "../../../types"; //enum
import { contactFormSchema } from "../../../validators/contactFormSchema";
import { Formik, Form as FForm, Field, ErrorMessage } from "formik";
import { render } from "@react-email/render";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import ContactEmail from "./ContactEmail";
import type { EmailPayload } from "../../../types";
import { sendEmail } from "../../../actions";

interface Props {
    isSmall: boolean;
}
export default function Contact({ isSmall }: Props) {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [error, setError] = useState("");
    return (
        <div className="container mb-4">
            <Formik
                validationSchema={contactFormSchema}
                initialValues={{
                    name: user.displayName || "",
                    email: user.email || "",
                    issueType: "",
                    message: "",
                }}
                onSubmit={async (values, actions) => {
                    try {
                        setError("");
                        actions.setSubmitting(true);
                        const html = render(<ContactEmail data={values} />);
                        const text = render(<ContactEmail data={values} />, {
                            plainText: true,
                        });
                        const payload: EmailPayload = {
                            sender: "The Ravim Contact Form<contact@theravim.com>",
                            recipient: "contact@theravim.com",
                            subject: `New Contact Submission: ${values.issueType}`,
                            html,
                            text,
                        };
                        console.log("PAYLOAD", payload);
                        await dispatch(sendEmail(payload));
                        actions.resetForm({ values });
                        actions.setSubmitting(false);
                    } catch (error: any) {
                        console.warn(error);
                        console.log(typeof error.code);
                        actions.setSubmitting(false);
                        setError(error.message);
                        // if (
                        //     error.code.includes("user-not-found") ||
                        //     error.code.includes("wrong-password")
                        // ) {
                        //     setError("Bad username or password");
                        // } else {
                        //     setError(error.message);
                        // }
                    }
                }}
            >
                {({ dirty, isSubmitting }) => (
                    <div className="d-flex flex-column align-items-center my-3 my-sm-4 w-100">
                        <FForm
                            noValidate
                            className="d-flex flex-column align-items-center w-100"
                            style={{ maxWidth: 400 }}
                        >
                            <h1>Contact</h1>
                            <div
                                className="d-flex flex-column align-items-center w-100"
                                style={{ maxWidth: 400 }}
                            >
                                <Form.Group className="w-100">
                                    <Form.Label className="mb-1">
                                        {isSmall ? (
                                            <small>Name</small>
                                        ) : (
                                            <span>Name</span>
                                        )}
                                    </Form.Label>
                                    <Field
                                        className={`form-control ${
                                            isSmall ? "form-control-sm" : ""
                                        }`}
                                        name="name"
                                        placeholder="Edgar"
                                        type="text"
                                    />
                                    <ErrorMessage
                                        className="text-danger ms-2"
                                        name="name"
                                        component="small"
                                    />
                                </Form.Group>
                                <Form.Group className="w-100 mt-2">
                                    <Form.Label className="mb-1">
                                        {isSmall ? (
                                            <small>Email</small>
                                        ) : (
                                            <span>Email</span>
                                        )}
                                    </Form.Label>
                                    <Field
                                        className={`form-control ${
                                            isSmall ? "form-control-sm" : ""
                                        }`}
                                        name="email"
                                        placeholder="edgar@theravim.com"
                                        type="email"
                                    />
                                    <ErrorMessage
                                        className="text-danger ms-2"
                                        name="email"
                                        component="small"
                                    />
                                </Form.Group>
                                <Form.Group className="w-100 mt-2">
                                    <Form.Label className="mb-1">
                                        {isSmall ? (
                                            <small>Issue Type</small>
                                        ) : (
                                            <span>Issue Type</span>
                                        )}
                                    </Form.Label>
                                    <Field
                                        as="select"
                                        className={`form-select ${
                                            isSmall ? "form-select-sm" : ""
                                        }`}
                                        name="issueType"
                                        // onChange={()=>{
                                        //     setF
                                        // }}
                                        type="text"
                                    >
                                        <option value="">
                                            Select Issue Type
                                        </option>
                                        <option value={IssueType.BUG}>
                                            Bug Report
                                        </option>
                                        <option
                                            value={IssueType.FEATURE_REQUEST}
                                        >
                                            Feature Request
                                        </option>
                                        <option value={IssueType.FEEDBACK}>
                                            Feature Request
                                        </option>
                                        <option value={IssueType.QUESTION}>
                                            Question
                                        </option>
                                        <option value={IssueType.QUESTION}>
                                            Other
                                        </option>
                                    </Field>
                                    <ErrorMessage
                                        className="text-danger ms-2"
                                        name="issueType"
                                        component="small"
                                    />
                                </Form.Group>
                                <Form.Group className="w-100 mt-2">
                                    <Form.Label className="mb-1">
                                        {isSmall ? (
                                            <small>Message</small>
                                        ) : (
                                            <span>Message</span>
                                        )}
                                    </Form.Label>
                                    <div className="ms-1"></div>
                                    <Field
                                        as="textarea"
                                        rows={5}
                                        className="form-control"
                                        name="message"
                                        placeholder=""
                                        type="text"
                                    />
                                    <ErrorMessage
                                        className="text-danger ms-2"
                                        name="message"
                                        component="small"
                                    />
                                </Form.Group>
                                {error && (
                                    <Alert
                                        className="text-center py-1 w-100 mt-2 mb-0"
                                        variant="warning"
                                    >
                                        <small>{error}</small>
                                    </Alert>
                                )}
                                <Button
                                    className="d-flex align-items-center justify-content-center mt-3 w-100"
                                    disabled={isSubmitting || !dirty}
                                    size="sm"
                                    type="submit"
                                >
                                    {isSubmitting ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <i className="bi bi-send" />
                                    )}
                                    <span className="ms-2">Send Message</span>
                                </Button>
                                {/* <Button
                            className="custom-link-btn-muted"
                            onClick={() =>
                                setState({
                                    ...state,
                                    showForm: false,
                                    selectedAgent: undefined,
                                })
                            }
                            variant="link"
                        >
                            <small>cancel</small>
                        </Button> */}
                            </div>
                        </FForm>
                    </div>
                )}
            </Formik>
        </div>
    );
}
