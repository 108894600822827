import { lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";
import Admin from "../components/admin/Admin";
import AdminRoute from "./AdminRoute";
import NotFound from "./NotFound";
import Home from "../components/home/Home";
import PublicRoute from "./PublicRoute";
import SignIn from "../components/signIn/SignIn";
import PrivateRoute from "./PrivateRoute";
import Help from "../components/help/Help";
import Faq from "../components/help/docs/Faq";

const Editor = lazy(() => import("../components/editor/Editor"));

export default function App() {
    const user = useAuth();
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route
                    path="/"
                    element={
                        user ? (
                            <Suspense>
                                <Editor />
                            </Suspense>
                        ) : (
                            <Home />
                        )
                    }
                    errorElement={<NotFound />}
                />
                <Route
                    path="/admin"
                    element={
                        <AdminRoute user={user}>
                            <Suspense>
                                <Admin />
                            </Suspense>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/faq"
                    element={
                        <PublicRoute>
                            <Faq />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/help"
                    element={
                        <PrivateRoute user={user}>
                            <Suspense>
                                <Help />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                {/* <Route path="/contact" element={<Contact />} />
                <Route path="/docs" element={<Docs />} />
                <Route
                    path="/issue-tracker"
                    element={
                        <PrivateRoute user={user}>
                            <IssueTracker />
                        </PrivateRoute>
                    }
                /> */}
                <Route
                    path="/signIn"
                    element={
                        <PublicRoute>
                            <Suspense>
                                <SignIn />
                            </Suspense>
                        </PublicRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}
