import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAppSelector } from "../../hooks/reduxHooks";
import slugify from "slugify";
import { Placement } from "react-bootstrap/esm/types";
import { useMemo } from "react";
import { isMobile } from "react-device-detect";

interface Props {
    classNames?: string;
    disabled?: boolean;
    fn: () => void;
    icon: string;
    iconFontSize?: string;
    placement?: Placement;
    tooltipText: string;
    type?: string;
}
export default function OverlayTriggerButton({
    classNames = "",
    disabled = false,
    fn,
    icon,
    iconFontSize = "",
    placement = "top",
    tooltipText,
    type,
}: Props) {
    const {
        settings: { showTooltips },
    } = useAppSelector((state) => state.user);

    const id = useMemo(() => {
        return slugify(tooltipText, { strict: true, lower: true });
    }, [tooltipText]);

    return (
        <>
            {showTooltips && !isMobile ? (
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    overlay={<Tooltip id={id}>{tooltipText}</Tooltip>}
                    placement={placement}
                >
                    <Button
                        className={classNames}
                        disabled={disabled}
                        onClick={() => fn()}
                        size="sm"
                        type={type === "submit" ? "submit" : "button"}
                        variant="link"
                    >
                        <i className={`bi bi-${icon} ${iconFontSize}`} />
                    </Button>
                </OverlayTrigger>
            ) : (
                <Button
                    className={classNames}
                    disabled={disabled}
                    onClick={() => fn()}
                    size="sm"
                    type={type === "submit" ? "submit" : "button"}
                    variant="link"
                >
                    <i className={`bi bi-${icon} ${iconFontSize}`} />
                </Button>
            )}
        </>
    );
}
