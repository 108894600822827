import { createAction, createReducer } from "@reduxjs/toolkit";
import { AuthActionType } from "../types/auth";

export interface SizeState {
    sizes: { element: string; height: number; width: number }[];
    totalHeightOffset: number;
}

const initialState = {
    sizes: [],
    totalHeightOffset: 0,
} as SizeState;

export enum SizeElementType {
    NAVBAR = "navbar",
    FOOTER = "footer",
    EDITOR = "editor",
    TOOLBAR = "toolbar",
}
export enum SizeActionType {
    SET_SIZE = "set_sizes",
}

const setSize = createAction<{
    element: SizeElementType;
    height: number;
    width: number;
}>(SizeActionType.SET_SIZE);

const signOut = createAction<null>(AuthActionType.SIGN_OUT_SUCCESS);
const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(setSize, (state, action) => {
            const elements = state.sizes.map((item) => item.element);
            const tempState = {
                ...state,
                sizes: !elements.includes(action.payload.element)
                    ? [...state.sizes, action.payload]
                    : state.sizes.map((item) => {
                          if (item.element === action.payload.element) {
                              return action.payload;
                          } else {
                              return item;
                          }
                      }),
            };
            const height = tempState.sizes.reduce(
                (acc, curr) => acc + curr.height,
                0
            );
            tempState.totalHeightOffset = height;
            return tempState;
        })
        .addCase(signOut, () => {
            return initialState;
        });
});

export default reducer;
