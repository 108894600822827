import { app } from "../firebase";
import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseUser } from "../types";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const isDev = process.env.NODE_ENV === "development";

type User = FirebaseUser | null;
type ContextState = { user: User };

const FirebaseAuthContext = createContext<ContextState | undefined>(undefined);

interface Props {
    children: JSX.Element;
}
function AuthProvider({ children }: Props) {
    const [user, setUser] = useState<User>(null);
    const value = { user };
    const auth = getAuth(app);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (isDev) {
                console.log("AUTH", auth);
                console.log("USER", user);
            }
            setUser(user);
        });
        return () => setUser(null);
    }, [auth]);
    // useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged(setUser);
    //     return unsubscribe;
    // }, [auth]);

    return (
        <FirebaseAuthContext.Provider value={value}>
            {children}
        </FirebaseAuthContext.Provider>
    );
}

function useAuth() {
    const context = useContext(FirebaseAuthContext);
    if (context === undefined) {
        throw new Error(
            "useFirebaseAuth must be used within a FirebaseAuthProvider"
        );
    }
    return context.user;
}

export { AuthProvider, useAuth };
