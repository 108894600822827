export interface ActiveFile {
    path: string;
    text: string;
}

export enum EditMode {
    EDIT = "edit",
    PREVIEW = "preview",
}

export enum ViewMode {
    GRID = "edit",
    LIST = "preview",
}

export enum CreateType {
    MANUSCRIPT = "manuscript",
    SCENE = "scene",
    SECTION = "section",
}

export enum DeleteType {
    MANUSCRIPT = "manuscript",
    SCENE = "scene",
    SECTION = "section",
}

export enum DeletedSceneAction {
    RESTORE = "restore",
    PERMANENTLY_DELETE = "permanently_delete",
}

export interface EditorState {
    createType: CreateType | undefined;
    files: string[];
    isCreateModalOpen: boolean;
    isInfoModalOpen: boolean;
    isListManuscripts: boolean;
    isFullScreen: boolean;
    selectedScene: string | undefined;
    activeSection: Section | undefined;
    activeScene: Scene | undefined;
    activeManuscript: Manuscript | undefined;
    manuscripts: Manuscript[];
    editMode: EditMode;
    viewMode: ViewMode;
}

export interface ManuscriptAuthorInfo {
    email: string;
    penName: string;
    phone: string;
}

export enum ChapterTitleStyle {
    DEFAULT = "default",
    NUMBER_ONLY = "number_only",
}

export interface Character {
    id: string;
    firstName: string;
    lastName: string;
    bio: string;
}

export interface Manuscript {
    agentId?: string;
    authorInfo?: ManuscriptAuthorInfo;
    chapterTitleStyle: ChapterTitleStyle;
    characters: Character[];
    copyright: string;
    coverPagePath?: string;
    createdAt: string;
    dedication: string;
    id: string;
    notes?: string;
    owner: string;
    queryLetter: string;
    sections: Section[];
    storyArcs: StoryArc[];
    submissions: Submission[];
    synopsis?: string;
    title: string;
    updatedAt: string;
}

export interface QueryLetter {
    id: string;
    agentId: string;
    text: string;
    createdAt: string;
    updatedAt: string;
}

export interface Submission {
    id: string;
    agentId: string;
    createdAt: string;
    deliveryMethod: SubmissionDeliveryMethod | string;
    note: string;
    queryLetter?: QueryLetter;
    replyDate?: string;
    status: SubmissionStatus;
    submittedOn: string;
    updatedAt: string;
}

export enum SubmissionDeliveryMethod {
    NOT_SENT = "not_sent",
    SNAIL_MAIL = "snail_mail",
    EMAIL = "email",
    ONLINE_FORM = "online_form",
    IN_PERSON = "in_person",
    WITHDRAWN_BY_AUTHOR = "withdrawn_by_author",
}
export enum SubmissionStatus {
    CLOSED_NO_RESPONSE = "closed_no_response",
    FULL_REQUEST = "full_request",
    NO_RESPONSE = "no_response",
    OFFER_OF_REPRESENTATION = "offer_of_representation",
    PARTIAL_REQUEST = "partial_request",
    REJECTED = "rejected",
    WAITING_FOR_RESPONSE = "waiting_for_response",
    WITHDRAWN_BY_AUTHOR = "withdrawn_by_author",
}
export enum StoryArcBadgeColor {
    PRIMARY = "custom-badge-purple",
    SECONDARY = "custom-badge-gray",
    PINK = "custom-badge-pink",
    RED = "custom-badge-red",
    ORANGE = "custom-badge-orange",
    YELLOW = "custom-badge-yellow",
    GREEN = "custom-badge-green",
    TEAL = "custom-badge-teal",
    CYAN = "custom-badge-cyan",
    BLUE = "custom-badge-blue",
    INDIGO = "custom-badge-indigo",
    PURPLE = "custom-badge-purple",
    LIGHT = "custom-badge-light",
    DARK = "custom-badge-dark",
}

export interface StoryArc {
    title: string;
    color: StoryArcBadgeColor;
}

export interface Section {
    createdAt: string;
    excludeFromCompile: boolean;
    id: string;
    manuscriptId: string;
    notes: string;
    order: number;
    subtitle: string;
    status?: SceneSectionStatus;
    storyArc: string;
    synopsis: string;
    title: string;
    updatedAt: string;
    useSubtitle: boolean;
}

export interface DnDSection extends Section {
    chosen?: boolean;
    selected?: boolean;
    filtered?: boolean;
}

export interface DnDScene extends Scene {
    chosen?: boolean;
    selected?: boolean;
    filtered?: boolean;
}

export interface Scene {
    excludeFromCompile: boolean;
    createdAt: string;
    deletedOn?: string;
    id: string;
    manuscriptId: string;
    notes: string;
    order: number;
    owner: string;
    sectionId: string;
    backups?: SceneBackup[];
    status?: SceneSectionStatus;
    synopsis: string;
    text: string;
    title: string;
    updatedAt: string;
    versions?: SceneVersion[];
    wordCount: number;
}

export interface SceneBackup {
    id: string;
    text: string;
    createdAt: string;
    updatedAt: string;
}

export interface SceneVersion {
    id: string;
    text: string;
    createdAt: string;
}

export enum SceneSectionStatus {
    NO_STATUS = "",
    IN_PROGRESS = "in_progress",
    FIRST_DRAFT = "first_draft",
    REVISED_DRAFT = "revised_draft",
    FINAL_DRAFT = "final_draft",
    DONE = "done",
}

export type SideBarItem = {
    id: string;
    label: string;
    fn?: () => void;
    children?: { id: string; order: number; label: string; fn: () => void }[];
};

export const enum InfoSideBarAction {
    AUTHOR = "author",
    BACKUPS = "backups",
    BOOKMARKS = "bookmarks",
    CHARACTERS = "characters",
    CHAT = "chat",
    COMMENTS = "comments",
    DELETED_SCENES = "deleted_scenes",
    DOWNLOAD = "download",
    METADATA = "metadata",
    NOTES = "notes",
    SETTINGS = "settings",
    SUBMISSIONS = "submissions",
    VERSIONS = "versions",
}

export enum InfoSidebarItemType {
    MANUSCRIPT = "manuscript",
    SCENE = "scene",
    SECTION = "section",
}

export enum SynopsisType {
    MANUSCRIPT = "manuscript",
    SCENE = "scene",
    SECTION = "section",
}

export enum SettingsSideBarAction {
    AGENTS = "agents",
    APP_SETTINGS = "app_settings",
    AUTHOR_INFO = "author-info",
    DANGER_ZONE = "danger_zone",
}

export enum LocalStorageAction {
    DELETE_SCENE = "delete_scene",
    UPDATE_SCENE = "update_scene",
}

export type LocalStorageManuscriptScenes = Record<
    string,
    LocalStorageScenePayload[]
>;

export interface LocalStorageScenePayload {
    sceneId: string;
    text: string;
}
