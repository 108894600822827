import { useState } from "react";
import {
    Alert,
    Button,
    FloatingLabel,
    InputGroup,
    Spinner,
} from "react-bootstrap";
import { createEmailUser, signInUserWithEmail } from "../../actions";
import { Formik, Form as FForm, Field, ErrorMessage } from "formik";
import { signInSchema } from "../../validators/signInSchema";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { ActionType } from "../../types";

export default function SignInForm() {
    const dispatch = useAppDispatch();
    const [error, setError] = useState("");
    const [isNewUser, setIsNewUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="d-flex flex-column align-items-center mt-3 width-100">
            <Formik
                validationSchema={signInSchema}
                initialValues={{
                    email: "",
                    password: "",
                }}
                onSubmit={async (values, actions) => {
                    try {
                        setError("");
                        actions.setSubmitting(true);
                        if (isNewUser) {
                            dispatch({
                                type: ActionType.OPEN_LOADING_OVERLAY,
                                payload: "Welcome to The Ravim...",
                            });
                            await dispatch(createEmailUser(values));
                        } else {
                            dispatch({
                                type: ActionType.OPEN_LOADING_OVERLAY,
                                payload: "Authenticating...",
                            });
                            await dispatch(
                                signInUserWithEmail(
                                    values.email,
                                    values.password
                                )
                            );
                        }
                        actions.setSubmitting(false);
                    } catch (error: any) {
                        console.warn(error);
                        dispatch({ type: ActionType.CLOSE_LOADING_OVERLAY });
                        actions.setSubmitting(false);
                        if (
                            error.code.includes("user-not-found") ||
                            error.code.includes("wrong-password")
                        ) {
                            setError("Bad username or password");
                        } else {
                            setError(error.message);
                        }
                    }
                }}
            >
                {({ dirty, isSubmitting }) => (
                    <FForm
                        noValidate
                        className="d-flex flex-column align-items-center "
                        style={{ width: 300 }}
                    >
                        <FloatingLabel
                            className="w-100"
                            controlId="email-label"
                            label="Email"
                        >
                            <Field
                                className="form-control"
                                name="email"
                                placeholder="edgar@theravim.com"
                                type="email"
                            />

                            <ErrorMessage
                                className="text-danger ms-2"
                                name="email"
                                component="small"
                            />
                        </FloatingLabel>
                        <InputGroup>
                            <FloatingLabel
                                className="d-flex mt-3 w-100"
                                controlId="password-label"
                                label="Password"
                            >
                                <Field
                                    className="form-control"
                                    name="password"
                                    placeholder="password123"
                                    type={showPassword ? "text" : "password"}
                                />
                                <InputGroup.Text id="show-password-icon">
                                    <i
                                        className={`bi bi-${
                                            showPassword ? "eye-slash" : "eye"
                                        }`}
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    />
                                </InputGroup.Text>
                            </FloatingLabel>
                            <ErrorMessage
                                className="text-danger ms-2"
                                name="password"
                                component="small"
                            />
                        </InputGroup>
                        <Button
                            className="d-flex align-items-center justify-content-center mt-3 w-100"
                            disabled={!dirty || isSubmitting}
                            type="submit"
                        >
                            {isSubmitting ? (
                                <Spinner size="sm" />
                            ) : (
                                <i className="bi bi-box-arrow-in-right" />
                            )}
                            <span className="ms-2">{`Sign ${
                                isNewUser ? "Up" : "In"
                            }`}</span>
                        </Button>
                        {error && (
                            <Alert
                                className="text-center py-2 w-100 mt-3 mb-0"
                                variant="warning"
                            >
                                <small>{error}</small>
                            </Alert>
                        )}
                        <Button
                            className="custom-link-btn-muted mt-2"
                            onClick={() => {
                                setError("");
                                setIsNewUser(!isNewUser);
                            }}
                            variant="link"
                        >
                            <small>
                                {isNewUser ? "Existing User?" : "New User?"}
                            </small>
                        </Button>
                    </FForm>
                )}
            </Formik>
        </div>
    );
}
