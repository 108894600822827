import { useState } from "react";
import { AdminActionType } from "../../types";
import { deleteGoogleAuthUser, deleteUserData } from "../../actions";
import { isMobile } from "react-device-detect";
import { Modal, Button, Spinner, Form, FormGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

const isDev = process.env.NODE_ENV === "development";

export default function DeleteUserModal() {
    const { isDeleteUserModalOpen, selectedUser: user } = useAppSelector(
        (state) => state.admin
    );
    const dispatch = useAppDispatch();
    const [confirmText, setConfirmText] = useState("");
    const [deleting, setDeleting] = useState(false);

    function handleClose() {
        dispatch({
            type: AdminActionType.SET_DELETE_USER_MODAL,
            payload: { isOpen: false, user: undefined },
        });
        resetModal();
    }
    function resetModal() {
        setConfirmText("");
    }
    async function handleDeleteUser(e: React.FormEvent<HTMLFormElement>) {
        try {
            e.preventDefault();
            if (
                user?.email &&
                user?.id &&
                !deleting &&
                confirmText === "delete"
            ) {
                setDeleting(true);
                await dispatch(deleteUserData(user.id));
                await dispatch(deleteGoogleAuthUser(user));
                setDeleting(false);
                handleClose();
            } else {
                if (isDev) {
                    console.log("Function does not meet enabled conditions");
                }
            }
        } catch (error) {
            setDeleting(false);
            console.warn(error);
        }
    }
    return (
        <Modal
            centered={!isMobile}
            show={isDeleteUserModalOpen}
            onHide={() => handleClose()}
            restoreFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleDeleteUser(e)}>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <div>You are about to delete</div>
                        <div className="fw-bold text-danger mt-3">
                            {user?.displayName || user?.email || user?.id}
                        </div>
                        <FormGroup className="mt-3">
                            <Form.Control
                                autoFocus
                                placeholder="Enter 'delete' to confirm"
                                value={confirmText}
                                onChange={(e) => setConfirmText(e.target.value)}
                            />
                        </FormGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`d-flex justify-content-between w-100`}>
                        <Button
                            className="custom-link-btn-hover-primary"
                            disabled={deleting}
                            variant="link"
                            onClick={() => handleClose()}
                        >
                            close
                        </Button>
                        <Button
                            className="d-flex align-items-center justify-content-center"
                            disabled={deleting || confirmText !== "delete"}
                            type="submit"
                            variant={"danger"}
                        >
                            {deleting && <Spinner className="me-2" size="sm" />}
                            {!deleting && <i className={`bi bi-trash me-2`} />}
                            <span>Delete User</span>
                        </Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
