import { AuthActionType } from "../types/auth";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { AdminActionType, AdminUser, Manuscript, Scene } from "../types";
import uniqBy from "lodash.uniqby";
import { DocumentData, Query } from "firebase/firestore";

// const isDev = process.env.NODE_ENV === "development";

interface AdminState {
    loading: boolean;
    manuscripts: Manuscript[];
    manuscriptsNext: Query<DocumentData> | undefined;
    scenes: Scene[];
    selectedUser: AdminUser | undefined;
    searching: boolean;
    searchString: string;
    searchStringError: string;
    isEditUserModalOpen: boolean;
    isDeleteUserModalOpen: boolean;
    isDisableUserModalOpen: boolean;
    users: AdminUser[];
}
const initialState: AdminState = {
    loading: false,
    manuscripts: [],
    manuscriptsNext: undefined,
    scenes: [],
    selectedUser: undefined,
    searching: false,
    searchString: "",
    searchStringError: "",
    isEditUserModalOpen: false,
    isDeleteUserModalOpen: false,
    isDisableUserModalOpen: false,
    users: [],
};

const deleteAuthUserSuccess = createAction<AdminUser>(
    AdminActionType.DELETE_GOOGLE_AUTH_USER_SUCCESS
);
const disableAuthUserSuccess = createAction<AdminUser>(
    AdminActionType.DISABLE_GOOGLE_AUTH_USER_SUCCESS
);
const enableAuthUserSuccess = createAction<AdminUser>(
    AdminActionType.ENABLE_GOOGLE_AUTH_USER_SUCCESS
);
const getAllManuscriptsSuccess = createAction<{
    manuscripts: Manuscript[];
    next: Query<DocumentData>;
}>(AdminActionType.GET_ALL_MANUSCRIPTS_SUCCESS);
const getManuscriptScenesSuccess = createAction<Scene[]>(
    AdminActionType.GET_MANUSCRIPT_SCENES_SUCCESS
);
const setEditUserModal = createAction<{
    isOpen: boolean;
    user: AdminUser | undefined;
}>(AdminActionType.SET_EDIT_USER_MODAL);
const setDeleteUserModal = createAction<{
    isOpen: boolean;
    user: AdminUser | undefined;
}>(AdminActionType.SET_DELETE_USER_MODAL);
const setDisableUserModal = createAction<{
    isOpen: boolean;
    user: AdminUser | undefined;
}>(AdminActionType.SET_DISABLE_USER_MODAL);
const setUsers = createAction<AdminUser[]>(AdminActionType.SET_USERS);
const setSelectedUser = createAction<AdminUser>(
    AdminActionType.SET_SELECTED_USER
);
const signOutSuccess = createAction<null>(AuthActionType.SIGN_OUT_START);

const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(deleteAuthUserSuccess, (state, action) => {
            return {
                ...state,
                users: state.users.filter(
                    (item) => item.id !== action.payload.id
                ),
            };
        })
        .addCase(disableAuthUserSuccess, (state, action) => {
            return {
                ...state,
                users: state.users.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
            };
        })
        .addCase(enableAuthUserSuccess, (state, action) => {
            return {
                ...state,
                users: state.users.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
            };
        })
        .addCase(getAllManuscriptsSuccess, (state, action) => {
            return {
                ...state,
                manuscripts: uniqBy(
                    [...state.manuscripts, ...action.payload.manuscripts],
                    "id"
                ),
                manuscriptsNext: action.payload.next,
            };
        })
        .addCase(getManuscriptScenesSuccess, (state, action) => {
            return {
                ...state,
                scenes: uniqBy(action.payload, "id"),
            };
        })
        .addCase(setUsers, (state, action) => {
            return { ...state, users: action.payload };
        })
        .addCase(setSelectedUser, (state, action) => {
            return { ...state, selectedUser: action.payload };
        })
        .addCase(setEditUserModal, (state, action) => {
            return {
                ...state,
                isEditUserModalOpen: action.payload.isOpen,
                selectedUser: action.payload.user,
            };
        })
        .addCase(setDeleteUserModal, (state, action) => {
            return {
                ...state,
                isDeleteUserModalOpen: action.payload.isOpen,
                selectedUser: action.payload.user,
            };
        })
        .addCase(setDisableUserModal, (state, action) => {
            return {
                ...state,
                isDisableUserModalOpen: action.payload.isOpen,
                selectedUser: action.payload.user,
            };
        })
        .addCase(signOutSuccess, () => {
            return {
                ...initialState,
            };
        });
});

export default reducer;
