import { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeProvider";

interface Props {
    item: {
        id: number;
        icon: string;
        lead: string;
        text: string;
    };
}
export default function Feature({ item }: Props) {
    const { isDark } = useContext(ThemeContext);
    const { icon, lead, text } = item;
    return (
        <div
            className="d-flex flex-column mx-3 mb-4"
            style={{ width: 280, maxWidth: 280 }}
        >
            <i
                className={`bi bi-${icon} fs-3`}
                style={{ color: "var(--bs-link-color)" }}
            />
            <div className="lead" style={{ fontWeight: 500 }}>
                {lead}
            </div>
            <div className="text" style={{ fontWeight: isDark ? 200 : 300 }}>
                {text}
            </div>
        </div>
    );
}
