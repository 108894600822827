import { Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import { Issue } from "../../../types";
import poe from "../../../assets/images/poe-icon.png";
import { useContext } from "react";
import { ThemeContext } from "../../../contexts/ThemeProvider";

interface Props {
    issues: Issue[];
}
export default function IssueList({ issues }: Props) {
    const { isDark } = useContext(ThemeContext);
    function renderIssues() {
        return issues.map((item) => (
            <ListGroupItem
                key={item.id}
                className="d-flex justify-content-between w-100"
            >
                <div>{item.title}</div>
                <small>
                    <Badge>{item.status}</Badge>
                </small>
            </ListGroupItem>
        ));
    }
    return (
        <div className="d-flex flex-column align-items-center mt-3 w-100">
            {issues.length === 0 ? (
                <div className="d-flex flex-column align-items-center mt-3 w-100">
                    <img
                        height={128}
                        width={128}
                        alt="The Ravim Logo"
                        src={poe}
                        style={{
                            filter: `invert(${isDark ? 1 : 0})`,
                            opacity: isDark ? 0.6 : 1,
                        }}
                    />
                    <div className="lead mt-3">You have no issues</div>
                    <small className="mt-1">(That's a good thing)</small>
                </div>
            ) : (
                <ListGroup className="w-100">{renderIssues()}</ListGroup>
            )}
        </div>
    );
}
