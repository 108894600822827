import { useState } from "react";
import { signOut } from "../../actions";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import AdminNavbar from "./AdminNavbar";
import Maintenance from "./Manuscripts";
import Users from "./Users";

export enum AdminFeature {
    MANUSCRIPTS = "manuscripts",
    USERS = "users",
}
export default function Admin() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { width: viewPortWidth } = useWindowSize();
    const isSmall = viewPortWidth <= 576;
    const isMed = viewPortWidth < 768;
    const isLarge = viewPortWidth >= 768;
    const [selectedFeature, setSelectedFeature] = useState<AdminFeature>(
        AdminFeature.USERS
    );

    async function handleSecuritySignOut() {
        try {
            await dispatch(signOut());
            navigate("/", { replace: true });
        } catch (error) {
            console.warn(error);
        }
    }

    return (
        <>
            <div className="container-fluid d-flex flex-column align-items-center justify-content-between mt-1 w-100">
                <AdminNavbar
                    isSmall={isSmall}
                    isMed={isMed}
                    isLarge={isLarge}
                    selectedFeature={selectedFeature}
                    setSelectedFeature={setSelectedFeature}
                />
                {selectedFeature === AdminFeature.USERS && (
                    <Users handleSecuritySignOut={handleSecuritySignOut} />
                )}
                {selectedFeature === AdminFeature.MANUSCRIPTS && (
                    <Maintenance
                        handleSecuritySignOut={handleSecuritySignOut}
                    />
                )}
            </div>
        </>
    );
}
