import { useMemo } from "react";
import { Badge, Card } from "react-bootstrap";
import { useAppSelector } from "../../hooks/reduxHooks";
import UserForm from "./UserForm";

interface Props {
    handleClose: () => void;
}
export default function UserCard({ handleClose }: Props) {
    const { selectedUser: user } = useAppSelector((state) => state.admin);
    const { disabled, title } = useMemo(() => {
        const title = user?.displayName || user?.email || "No user!";
        const disabled = user?.disabled;
        return { disabled, title };
    }, [user]);

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="d-flex align-items-center justify-content-between w-100">
                    {title}
                    <small>
                        <Badge bg={disabled ? "danger" : "success"}>
                            {disabled ? "disabled" : "enabled"}
                        </Badge>
                    </small>
                </Card.Title>
                <UserForm handleClose={handleClose} />
            </Card.Body>
        </Card>
    );
}
