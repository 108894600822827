import { useEffect, useState } from "react";
import { getIssues } from "../../../actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { useAuth } from "../../../contexts/AuthProvider";
import { User } from "firebase/auth";
import IssueForm from "./IssueForm";
import IssueList from "./IssueList";
import IssuesNavButtons from "./IssuesNavButtons";

export enum IssuesFeature {
    ISSUE_FORM = "issue_form",
    ISSUE_LIST = "issue_list",
}

interface Props {
    isSmall: boolean;
}
export default function Issues({ isSmall }: Props) {
    const issues = useAppSelector((state) => state.issues);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState<IssuesFeature>(
        IssuesFeature.ISSUE_FORM
    );
    const user = useAuth();
    useEffect(() => {
        async function init(user: User) {
            try {
                setLoading(true);
                await dispatch(getIssues(user));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.warn(error);
            }
        }
        if (user) {
            init(user);
        }
    }, [dispatch, user]);
    return (
        <div className="d-flex flex-column align-items-center w-100" style={{maxWidth: 400}}>
            <IssuesNavButtons
                loading={loading}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
            />
            {selectedFeature === IssuesFeature.ISSUE_FORM ? (
                <IssueForm isSmall={isSmall} />
            ) : (
                <IssueList issues={issues} />
            )}
        </div>
    );
}
