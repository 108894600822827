import { UserState } from "./user";

export enum AdminActionType {
    DELETE_GOOGLE_AUTH_USER_FAIL = "delete_google_auth_user_fail",
    DELETE_GOOGLE_AUTH_USER_START = "delete_google_auth_user_start",
    DELETE_GOOGLE_AUTH_USER_SUCCESS = "delete_google_auth_user_success",
    DISABLE_GOOGLE_AUTH_USER_FAIL = "disable_google_auth_user_fail",
    DISABLE_GOOGLE_AUTH_USER_START = "disable_google_auth_user_start",
    DISABLE_GOOGLE_AUTH_USER_SUCCESS = "disable_google_auth_user_success",
    ENABLE_GOOGLE_AUTH_USER_FAIL = "enable_google_auth_user_fail",
    ENABLE_GOOGLE_AUTH_USER_START = "enable_google_auth_user_start",
    ENABLE_GOOGLE_AUTH_USER_SUCCESS = "enable_google_auth_user_success",
    FIND_USER_BY_EMAIL_FAIL = "find_user_by_email_fail",
    FIND_USER_BY_EMAIL_START = "find_user_by_email_start",
    FIND_USER_BY_EMAIL_SUCCESS = "find_user_by_email_success",
    GET_ALL_MANUSCRIPTS_FAIL = "get_all_manuscripts_fail",
    GET_ALL_MANUSCRIPTS_START = "get_all_manuscripts_start",
    GET_ALL_MANUSCRIPTS_SUCCESS = "get_all_manuscripts_success",
    GET_GOOGLE_USER_INFO_FAIL = "get_google_user_info_fail",
    GET_GOOGLE_USER_INFO_START = "get_google_user_info_start",
    GET_GOOGLE_USER_INFO_SUCCESS = "get_google_user_info_success",
    GET_MANUSCRIPT_SCENES_FAIL = "get_manuscript_scenes_fail",
    GET_MANUSCRIPT_SCENES_START = "get_manuscript_scenes_start",
    GET_MANUSCRIPT_SCENES_SUCCESS = "get_manuscript_scenes_success",
    GET_ALL_USERS_FAIL = "get_all_users_fail",
    GET_ALL_USERS_START = "get_all_users_start",
    GET_ALL_USERS_SUCCESS = "get_all_users_success",
    GET_USERS_WITH_CLAIM_FAIL = "get_users_with_claim_fail",
    GET_USERS_WITH_CLAIM_START = "get_users_with_claim_start",
    GET_USERS_WITH_CLAIM_SUCCESS = "get_users_with_claim_success",
    SET_EDIT_USER_MODAL = "set_edit_user_modal",
    SET_DELETE_USER_MODAL = "set_delete_user_modal",
    SET_DISABLE_USER_MODAL = "set_disable_user_modal",
    SEARCH_FOR_USER_FAIL = "search_for_user_fail",
    SEARCH_FOR_USER_START = "search_for_user_start",
    SEARCH_FOR_USER_SUCCESS = "search_for_user_success",
    SEND_EMAIL_FAIL = "send_email_fail",
    SEND_EMAIL_START = "send_email_start",
    SEND_EMAIL_SUCCESS = "send_email_success",
    SET_CUSTOM_CLAIM_FAIL = "set_custom_claim_fail",
    SET_CUSTOM_CLAIM_START = "set_custom_claim_start",
    SET_CUSTOM_CLAIM_SUCCESS = "set_custom_claim_success",
    SET_USERS = "set_users",
    SET_SEARCH_STRING = "set_search_string",
    SET_SELECTED_USER = "set_selected_user",
    UPDATE_USER_FAIL = "update_user_fail",
    UPDATE_USER_START = "update_user_start",
    UPDATE_USER_SUCCESS = "update_user_success",
    UPDATE_USER = "update_user_success",
}

export interface AdminUser extends UserState {
    disabled: boolean;
    claims: Record<string, boolean>[];
}
