import { Navigate } from "react-router-dom";
import { User } from "firebase/auth";
import { useAppSelector } from "../hooks/reduxHooks";
import { UserRole } from "../types";

interface Props {
    user: User | null;
    children: JSX.Element;
}
export default function AdminRoute({ children, user }: Props) {
    const { role } = useAppSelector((state) => state.user);
    if (!user || role !== UserRole.ADMIN) {
        return <Navigate to="/" replace />;
    }

    return children;
}
