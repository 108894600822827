import { useEffect } from "react";
import { Manuscript } from "../../types";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { getManuscriptScenesAdmin } from "../../actions";
import { Button } from "react-bootstrap";

interface Props {
    selectedManuscript: Manuscript;
    setSelectedManuscript: (manuscript: Manuscript | undefined) => void;
}
export default function ManuscriptDetail({ selectedManuscript, setSelectedManuscript }: Props) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getManuscriptScenesAdmin(selectedManuscript));
            } catch (error) {
                console.warn(error);
            }
        }
        fetchData();
    }, [dispatch, selectedManuscript]);
    return (
        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
            <h2>Manuscript Detail</h2>
            <h3>{selectedManuscript.title}</h3>
            <h4>
                Author:{" "}
                {selectedManuscript.authorInfo?.penName ||
                    selectedManuscript.authorInfo?.email ||
                    selectedManuscript.owner}
            </h4>
            <Button className="custom-link-btn-hover-primary" 
           onClick={() => setSelectedManuscript(undefined)} 
            variant="link">
                close
            </Button>
        </div>
    );
}
