import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import poe from "../../assets/images/poe-icon.png";
import poe from "../assets/images/poe-icon.png";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeProvider";

export default function NotFound() {
    const navigate = useNavigate();
    const { isDark } = useContext(ThemeContext);
    const quotes = [
        "I became insane, with long intervals of horrible sanity.",
        "All that we see or seem is but a dream within a dream.",
        "We loved with a love that was more than love.",
        "I have great faith in fools - self-confidence my friends will call it.",
        "Believe nothing you hear, and only one half that you see.",
    ];
    function getQuote() {
        return quotes[Math.floor(Math.random() * quotes.length)];
    }

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center vh-100"
            id="error-page"
        >
            <img
                height={300}
                width={300}
                alt="poe-logo"
                src={poe}
                style={{
                    filter: `invert(${isDark ? 1 : 0})`,
                    opacity: isDark ? 0.6 : 1,
                }}
            />
            <em className="lead">{getQuote()}</em>
            <Button
                className="custom-link-btn-hover-primary mt-3"
                variant="link"
                onClick={() => navigate("/")}
            >
                Go Home
            </Button>
        </div>
    );
}
