export enum AuthActionType {
    CREATE_USER_WITH_EMAIL_FAIL = "create_user_with_email_fail",
    CREATE_USER_WITH_EMAIL_START = "create_user_with_email_start",
    CREATE_USER_WITH_EMAIL_SUCCESS = "create_user_with_email_success",
    SIGN_IN_USER_WITH_EMAIL_FAIL = "sign_in_user_with_email_fail",
    SIGN_IN_USER_WITH_EMAIL_START = "sign_in_user_with_email_start",
    SIGN_IN_USER_WITH_EMAIL_SUCCESS = "sign_in_user_with_email_success",
    SIGN_OUT_FAIL = "sign_out_fail",
    SIGN_OUT_SUCCESS = "sign_out_success",
    SIGN_OUT_START = "sign_out_start",
}
