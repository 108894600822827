// import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";


// interface Props {
//     state: AgentsState;
//     setState: (s: AgentsState) => void;
// }
export default function Docs() {
    // const user = useAppSelector((state) => state.user);
    // const dispatch = useAppDispatch();
    // const [error, setError] = useState("");
    return (
        <div className="container d-flex flex-column align-items-center">
            <h1>DOCS</h1>
        </div>
    );
}
