import { useState } from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Form,
    InputGroup,
    Spinner,
} from "react-bootstrap";
import { findUserByEmail, getAllUsers, getUsersWithClaim } from "../../actions";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { UserRole } from "../../types";
import * as yup from "yup";
import DisableUserModal from "./DisableUserModal";
import UserList from "./UserList";
import UserModal from "./UserModal";
import DeleteUserModal from "./DeleteUserModal";

const isDev = process.env.NODE_ENV === "development";

enum GetUserType {
    ADMINS = "admins",
    INTERESTED = "interested",
    SUBSCRIBERS = "subscribers",
}
interface Props {
    handleSecuritySignOut: () => void;
}
export default function Users({ handleSecuritySignOut }: Props) {
    const admin = useAppSelector((state) => state.admin);
    const dispatch = useAppDispatch();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [searchStringError, setSearchStringError] = useState("");
    const schema = yup.object().shape({
        email: yup.string().email(),
    });

    async function getUsers(type?: GetUserType) {
        try {
            setError("");
            setLoading(true);
            if (type === GetUserType.ADMINS) {
                await dispatch(getUsersWithClaim(UserRole.ADMIN));
            } else if (type === GetUserType.INTERESTED) {
                await dispatch(getUsersWithClaim(UserRole.INTERESTED));
            } else if (type === GetUserType.SUBSCRIBERS) {
                await dispatch(getUsersWithClaim(UserRole.SUBSCRIBER));
            } else {
                await dispatch(getAllUsers());
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error && error.message === "permission-denied") {
                handleSecuritySignOut();
            } else {
                setError(error.message);
                console.warn(error);
            }
        }
    }

    async function handleSearch(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        try {
            if (
                searchString.length >= 5 &&
                searchString.includes("@") &&
                searchString.includes(".")
            ) {
                setError("");
                setSearchStringError("");
                setSearching(true);
                const result = await schema.isValid({ email: searchString });
                if (!result) {
                    setSearchStringError("A valid email is required");
                } else {
                    await dispatch(findUserByEmail(searchString));
                }
                setSearching(false);
            } else {
                if (isDev) {
                    console.log(
                        "Search string does not meet enabled conditions"
                    );
                }
            }
        } catch (error: any) {
            if (error && error.message === "permission-denied") {
                handleSecuritySignOut();
            } else {
                setError(error.message);
                setSearching(false);
            }
        }
    }
    return (
        <>
            <div className="container d-flex flex-column align-items-center my-3 w-100">
                <h1>Users</h1>
                <ButtonGroup className="mt-2" aria-label="Get User Buttons">
                    <Button
                        onClick={() => getUsers()}
                        size="sm"
                        style={{ width: 100 }}
                        variant="primary"
                    >
                        All
                    </Button>
                    <Button
                        onClick={() => getUsers(GetUserType.ADMINS)}
                        size="sm"
                        style={{ width: 100 }}
                        variant="danger"
                    >
                        Admins
                    </Button>
                    <Button
                        onClick={() => getUsers(GetUserType.INTERESTED)}
                        size="sm"
                        style={{ width: 100 }}
                        variant="secondary"
                    >
                        Interested
                    </Button>
                    <Button
                        onClick={() => getUsers(GetUserType.SUBSCRIBERS)}
                        size="sm"
                        style={{ width: 100 }}
                        variant="success"
                    >
                        Subscribers
                    </Button>
                </ButtonGroup>
                <Form
                    className="d-flex justify-content-center w-100"
                    onSubmit={(e) => handleSearch(e)}
                >
                    <InputGroup className="mt-3" size="sm" style={{ maxWidth: 400 }}>
                        <InputGroup.Text>@</InputGroup.Text>
                        <Form.Control
                            className={
                                searchStringError !== "" ? "is-invalid" : ""
                            }
                            onChange={(e) => setSearchString(e.target.value)}
                            placeholder="Search by email address"
                            type="search"
                            value={searchString}
                        />
                        <Button
                            className="d-flex align-items-center justify-content-center"
                            disabled={
                                searchString.length < 5 ||
                                !searchString.includes("@") ||
                                !searchString.includes(".")
                            }
                            type="submit"
                            variant="primary"
                            id="search-button-addon"
                        >
                            {searching ? (
                                <Spinner size="sm" />
                            ) : (
                                <i className="bi bi-search" />
                            )}
                            <span className="ms-2">Search</span>
                        </Button>
                    </InputGroup>
                </Form>
                {error && (
                    <Alert
                        className="py-2 mt-3 mb-0 text-center w-100"
                        style={{ maxWidth: 400 }}
                        variant="warning"
                    >
                        {error}
                    </Alert>
                )}
                {loading ? (
                    <>
                        <Spinner className="mt-5" />
                        <span className="mt-2 text-body-secondary">
                            Loading Users...
                        </span>
                    </>
                ) : (
                    <div className="d-flex justify-content-center w-100">
                        <UserList />
                    </div>
                )}
            </div>

            <DisableUserModal
                actionText={`${
                    admin.selectedUser?.enabled ? "Disable" : "Enable"
                } User`}
                bodyText={`You are about to ${
                    admin.selectedUser?.enabled ? "disable" : "enable"
                }:`}
                bodySubtext={
                    admin.selectedUser?.displayName ||
                    admin.selectedUser?.email ||
                    admin.selectedUser?.id ||
                    "WTF?"
                }
                buttonIcon={`person-${
                    admin.selectedUser?.enabled ? "down" : "up"
                }`}
                destructive={admin.selectedUser?.enabled === true}
                emoji={""}
                headerText={`${
                    admin.selectedUser?.enabled ? "Disable" : "Enable"
                } User`}
            />
            <DeleteUserModal />
            <UserModal />
        </>
    );
}
