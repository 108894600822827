import { useCallback, useEffect, useState } from "react";
import { AdminActionType, type Manuscript } from "../../types";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import { getAllManuscripts } from "../../actions";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import dayjs from "dayjs";

const isDev = process.env.NODE_ENV === "development";

enum LoadingType {
    LOADING = "loading",
    LOADING_MORE = "loading_more",
    REFRESHING = "refreshing",
}
interface Props {
    setSelectedManuscript: (manuscript: Manuscript | undefined) => void;
    handleSecuritySignOut: () => void;
}
export default function ManuscriptList({
    handleSecuritySignOut,
    setSelectedManuscript,
}: Props) {
    const { manuscripts, manuscriptsNext } = useAppSelector(
        (state) => state.admin
    );
    const dispatch = useAppDispatch();
    const [limit, setLimit] = useState<number>(25);
    const [loading, setLoading] = useState<LoadingType>();

    async function getMoreManuscripts() {
        try {
            setLoading(LoadingType.LOADING_MORE);
            await dispatch(getAllManuscripts(limit, manuscriptsNext));
            setLoading(undefined);
        } catch (error) {
            setLoading(undefined);
            if (isDev) {
                console.warn("load-more error", error);
            }
        }
    }
    const getManuscripts = useCallback(
        async (loadingType: LoadingType) => {
            try {
                setLoading(loadingType);
                await dispatch(getAllManuscripts(limit));
                setLoading(undefined);
            } catch (error: any) {
                setLoading(undefined);
                if (
                    error &&
                    (error.message === "Missing or insufficient permissions." ||
                        (error && error.message === "Not an admin!"))
                ) {
                    handleSecuritySignOut();
                }
                if (isDev) {
                    console.warn("get-error", error.message);
                }
            }
        },
        [dispatch, limit, handleSecuritySignOut]
    );

    useEffect(() => {
        if (manuscripts.length === 0) {
            getManuscripts(LoadingType.LOADING);
        }
    }, [dispatch, getManuscripts, manuscripts.length]);

    async function refreshManuscripts() {
        dispatch({
            type: AdminActionType.GET_ALL_MANUSCRIPTS_SUCCESS,
            payload: { manuscripts: [], manuscriptsNext: undefined },
        });
        await getManuscripts(LoadingType.REFRESHING);
    }

    function renderManuscripts() {
        const sorted = [...manuscripts].sort((a, b) => {
            if (a.owner === b.owner) {
                return dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix();
            }
            return a.owner > b.owner ? 1 : -1;
        });
        return sorted.map((manuscript, index) => {
            return (
                <tr
                    key={manuscript.id}
                    onClick={() => {
                        setSelectedManuscript(manuscript);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <td>{index + 1}</td>
                    <td>{manuscript.title}</td>
                    <td>
                        {manuscript.authorInfo?.penName ||
                            manuscript.authorInfo?.email ||
                            manuscript.owner}
                    </td>
                    <td> {manuscript.sections.length}</td>
                    <td>
                        {dayjs(manuscript.updatedAt).format("MMMM D, YYYY")}
                    </td>
                </tr>
            );
        });
    }
    return (
        <div className="container w-100">
            {loading === LoadingType.LOADING && (
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <Spinner className="mt-5" />
                    <span className="mt-2 text-body-secondary">
                        Loading Manuscripts...
                    </span>
                </div>
            )}
            {!loading && manuscripts.length === 0 && (
                <div>No manuscripts found</div>
            )}
            {(!loading || loading !== LoadingType.LOADING) &&
                manuscripts.length > 0 && (
                    <div className="d-flex flex-column align-items-center justify-content-center my-3 w-100">
                        <div className="d-flex align-items-center justify-content-end w-100">
                            <Form.Select
                                onChange={(e) =>
                                    setLimit(parseInt(e.target.value))
                                }
                                size="sm"
                                style={{ maxWidth: 72 }}
                                value={limit}
                            >
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                            <Button
                                onClick={() => refreshManuscripts()}
                                variant="link"
                            >
                                {loading === LoadingType.REFRESHING ? (
                                    <Spinner size="sm" />
                                ) : (
                                    <i className="bi bi-arrow-clockwise" />
                                )}
                            </Button>
                            {manuscriptsNext && (
                                <Button
                                    onClick={() => getMoreManuscripts()}
                                    variant="link"
                                >
                                    {loading === LoadingType.LOADING_MORE ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <i className="bi bi-journal-arrow-down" />
                                    )}
                                </Button>
                            )}
                        </div>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Author</th>
                                    <th>Sections</th>
                                    <th>Updated At</th>
                                </tr>
                            </thead>
                            <tbody>{renderManuscripts()}</tbody>
                        </Table>
                        {/* <ListGroup>{renderManuscripts()}</ListGroup> */}
                    </div>
                )}
        </div>
    );
}
