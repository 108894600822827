import { Button, ButtonGroup } from "react-bootstrap";
import { IssuesFeature } from "./Issues";

interface Props {
    loading: boolean;
    selectedFeature: IssuesFeature;
    setSelectedFeature: (f: IssuesFeature) => void;
}
export default function IssuesNavButtons({
    loading,
    selectedFeature,
    setSelectedFeature,
}: Props) {
    return (
        <ButtonGroup className="mt-3 mt-sm-4">
            <Button
                active={selectedFeature === IssuesFeature.ISSUE_FORM}
                className="d-flex align-text-center justify-content-center"
                onClick={() => setSelectedFeature(IssuesFeature.ISSUE_FORM)}
                variant="danger"
                size="sm"
            >
                New Issue
            </Button>
            <Button
                active={selectedFeature === IssuesFeature.ISSUE_LIST}
                className="d-flex align-text-center justify-content-center"
                disabled={loading}
                onClick={() => setSelectedFeature(IssuesFeature.ISSUE_LIST)}
                variant="primary"
                size="sm"
            >
                My Issues
            </Button>
        </ButtonGroup>
    );
}
