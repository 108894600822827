import { useContext, useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import rehypeRaw from "rehype-raw";
import {
    dracula as dark,
    materialLight as light,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { ThemeContext } from "../../../contexts/ThemeProvider";

interface Props {
    header: string;
    path: string;
}
export default function FaqItem({ header, path }: Props) {
    const headerRef = useRef<HTMLDivElement>(null);
    const [text, setText] = useState("");
    const { isDark } = useContext(ThemeContext);

    useEffect(() => {
        async function init() {
            try {
                const resp = await fetch(path);
                const txt = await resp.text();
                setText(txt);
            } catch (error) {
                console.warn(error);
            }
        }
        if (path) {
            init();
        }
    }, [path]);

    const handleTransitionEnd = () => {
        if (headerRef.current) {
            const rect = headerRef.current.getBoundingClientRect();
            const distance = rect.top + window.scrollY;

            window.scrollTo({ top: distance, behavior: "smooth" });
        }
    };

    return (
        <Accordion className="w-100" flush>
            <Accordion.Item className="border-bottom w-100" eventKey="0">
                <Accordion.Header ref={headerRef}>{header}</Accordion.Header>
                <Accordion.Body
                    onEntered={handleTransitionEnd}
                    onExited={handleTransitionEnd}
                >
                    <ReactMarkdown
                        children={text}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            a: ({ node, ...props }) => (
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                    }}
                                    {...props}
                                />
                            ),
                            code({
                                node,
                                inline,
                                className,
                                children,
                                ...props
                            }) {
                                const match = /language-(\w+)/.exec(
                                    className || ""
                                );
                                return !inline && match ? (
                                    <SyntaxHighlighter
                                        {...props}
                                        children={String(children).replace(
                                            /\n$/,
                                            ""
                                        )}
                                        style={isDark ? dark : light}
                                        language={match[1]}
                                        PreTag="div"
                                    />
                                ) : (
                                    <code {...props} className={className}>
                                        {children}
                                    </code>
                                );
                            },
                        }}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}
