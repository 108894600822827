import Feature from "./Feature";

const features = [
    {
        id: 0,
        icon: "vector-pen",
        lead: "Write with Vim",
        text: "Vim is by far the most efficient way to edit text on a computer, unless of course, you don't know Vim.  If you do know Vim, this is the writing app you've been waiting for!",
    },
    {
        id: 1,
        icon: "shuffle",
        lead: "Organize Your Thoughts",
        text: "Create your story one scene at a time, organize scenes into chapters, then mix it up.  You can effortlessly rearrange your story any way you like until you get it just right. Then tweak it some more.",
    },
    {
        id: 2,
        icon: "file-pdf",
        lead: "Pro Manuscript Generation",
        text: "Generate PDF versions of your manuscript in the format that agents want, including a cover page, margins, spacing, page headings, etc.  Creating manuscript submissions is super easy.  A couple clicks, and you're ready to go.",
    },
    {
        id: 3,
        icon: "search",
        lead: "Submission Tracking",
        text: "Maintain a list of agents and stay on top of to whom you've submitted your manuscripts and those who've accepted or rejected your work.  Bird-dog them into submission, and you'll be sitting in the catbird seat.",
    },
];
export default function Features() {
    return (
        <div
            className="d-flex flex-column align-items-center w-100 my-3"
            style={{ maxWidth: 640 }}
        >
            <div className="d-flex flex-wrap justify-content-center w-100">
                {features.map((item) => (
                    <Feature key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
}
