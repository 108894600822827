import { Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import { AdminUser, UserRole } from "../../types";
import AdminActionBar from "./AdminActionBar";
import { useAppSelector } from "../../hooks/reduxHooks";

export default function UserList() {
    const admin = useAppSelector((state) => state.admin);
    function getBadge(user: AdminUser) {
        const keys = Object.keys(user.claims);
        if (keys.length === 0) {
            return null;
        }
        const roles = keys.filter(
            (key) => user.claims[key as keyof typeof user.claims]
        );
        if (roles.length === 0) {
            return null;
        }
        const claim = roles[0];
        if (claim === UserRole.ADMIN) {
            return <Badge bg="danger">A</Badge>;
        }
        if (claim === UserRole.INTERESTED) {
            return <Badge bg="secondary">I</Badge>;
        }
        if (claim === UserRole.SUBSCRIBER) {
            return <Badge bg="success">S</Badge>;
        }
        return null;
    }
    function renderUsers() {
        return admin.users.map((item) => (
            <ListGroupItem
                className="d-flex align-items-center justify-content-between"
                key={item.id}
            >
                <div className="d-flex align-items-center">
                    <small>{item.email}</small>
                    <small className="ms-2">{getBadge(item)}</small>
                </div>
                <AdminActionBar user={item} />
            </ListGroupItem>
        ));
    }
    return (
        <ListGroup className="mt-3 w-100" style={{ maxWidth: 400 }}>
            {renderUsers()}
        </ListGroup>
    );
}
