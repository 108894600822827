import { AppThunk } from "../store/store";
import { AuthActionType } from "../types/auth"; // enum
import { doc, getDoc } from "firebase/firestore";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    User,
} from "firebase/auth";
import db from "../firebase/firebase";
import { ActionType, UserActionType } from "../types";

export const createEmailUser =
    (values: { email: string; password: string }): AppThunk<Promise<User>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: AuthActionType.CREATE_USER_WITH_EMAIL_START });
            const { isLoadingOverlayOpen } = getState().app;
            if (isLoadingOverlayOpen) {
                dispatch({
                    type: ActionType.SET_LOADING_MESSAGE,
                    payload: "Welcome to The Ravim...",
                });
            } else {
                dispatch({
                    type: ActionType.OPEN_LOADING_OVERLAY,
                    payload: "Welcome to The Ravim...",
                });
            }
            const auth = getAuth();
            const { email, password } = values;
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;
            dispatch({ type: AuthActionType.CREATE_USER_WITH_EMAIL_SUCCESS });
            return Promise.resolve(user);
        } catch (error: any) {
            // const errorCode = error.code;
            // console.log(errorMessage);
            dispatch({ type: AuthActionType.CREATE_USER_WITH_EMAIL_FAIL });
            return Promise.reject(error.code);
        }
    };

export const signInUserWithEmail =
    (email: string, password: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: AuthActionType.SIGN_IN_USER_WITH_EMAIL_START });
            // dispatch({
            //     type: ActionType.OPEN_LOADING_OVERLAY,
            //     // payload: "Signing in...",
            // });
            const auth = getAuth();
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;
            const existingUser = await getDoc(doc(db, "users", user.uid));
            if (existingUser.exists()) {
                dispatch({
                    type: UserActionType.SET_USER_DATA,
                    payload: existingUser.data(),
                });
                dispatch({
                    type: AuthActionType.SIGN_IN_USER_WITH_EMAIL_SUCCESS,
                });
            } else {
                throw new Error("Could not find user in firebase!");
            }
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: AuthActionType.SIGN_IN_USER_WITH_EMAIL_FAIL });
            dispatch({
                type: ActionType.CLOSE_LOADING_OVERLAY,
            });
            return Promise.reject(error);
        }
    };
