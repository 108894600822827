import { useMemo, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Formik, Form as FForm, Field, ErrorMessage } from "formik";
import { UserRole, AdminActionType } from "../../types"; // enum
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setCustomClaim, updateUser } from "../../actions";

interface Props {
    handleClose: () => void;
}
export default function UserForm({ handleClose }: Props) {
    const { selectedUser: user, users } = useAppSelector(
        (state) => state.admin
    );
    const dispatch = useAppDispatch();
    const role = useMemo(() => {
        if (!user) {
            return UserRole.NORMIE;
        }
        const keys = Object.keys(user.claims);
        if (keys.length === 0) {
            return UserRole.NORMIE;
        }
        const roles = keys.filter(
            (key) => user.claims[key as keyof typeof user.claims]
        );
        if (roles.length === 0) {
            return UserRole.NORMIE;
        } else {
            const claim = roles[0];
            return claim as UserRole;
        }
    }, [user]);
    const [error, setError] = useState("");
    return (
        <div className="container">
            <Formik
                initialValues={{
                    displayName: user?.displayName || "",
                    email: user?.email || "",
                    // phone: user?.phone || "",
                    role,
                }}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    try {
                        setError("");
                        actions.setSubmitting(true);
                        if (!user) {
                            throw new Error("No user!");
                        }
                        const oldRole = role;
                        const newRole = values.role;
                        const adminUserPayload = {
                            ...user,
                            claims: { [values.role]: true },
                        };
                        if (oldRole !== newRole) {
                            console.log(
                                `%cChanging user role for ${
                                    values.displayName || values.email
                                } to: ${values.role}`,
                                "color:cyan"
                            );
                            if (!user.id) {
                                throw new Error("No user id!");
                            }
                            await dispatch(
                                setCustomClaim(user.id, values.role)
                            );
                            const payload: any = {
                                id: user.id,
                                role: values.role,
                            };
                            delete payload.claims;
                            console.log("fucking payload", payload);
                            await dispatch(updateUser(payload));
                            dispatch({
                                type: AdminActionType.SET_SELECTED_USER,
                                adminUserPayload,
                            });
                            dispatch({
                                type: AdminActionType.SET_USERS,
                                payload: users.map((item) => {
                                    if (item.id === adminUserPayload.id) {
                                        return adminUserPayload;
                                    } else {
                                        return item;
                                    }
                                }),
                            });
                        }
                        actions.setSubmitting(false);
                        handleClose();
                    } catch (error: any) {
                        console.warn(error);
                        actions.setSubmitting(false);
                        setError(error.message);
                        // if (
                        //     error.code.includes("user-not-found") ||
                        //     error.code.includes("wrong-password")
                        // ) {
                        //     setError("Bad username or password");
                        // } else {
                        //     setError(error.message);
                        // }
                    }
                }}
            >
                {({ dirty, isSubmitting, setFieldValue, values }) => (
                    <FForm noValidate className="d-flex flex-column mt-3">
                        <Form.Group className="d-flex align-items-center ">
                            <Form.Check
                                onChange={() => {
                                    setFieldValue(
                                        "role",
                                        UserRole.ADMIN,
                                        false
                                    );
                                }}
                                inline
                                name=""
                                label="Admin"
                                type="radio"
                                id="admin-radio"
                                checked={values.role === UserRole.ADMIN}
                            />
                            <Form.Check
                                onChange={() => {
                                    setFieldValue(
                                        "role",
                                        UserRole.INTERESTED,
                                        false
                                    );
                                }}
                                inline
                                name=""
                                label="Interested"
                                type="radio"
                                id="interested-radio"
                                checked={values.role === UserRole.INTERESTED}
                            />
                            <Form.Check
                                onChange={() => {
                                    setFieldValue(
                                        "role",
                                        UserRole.SUBSCRIBER,
                                        false
                                    );
                                }}
                                inline
                                name=""
                                label="Subscriber"
                                type="radio"
                                id="subscriber-radio"
                                checked={values.role === UserRole.SUBSCRIBER}
                            />
                            <Form.Check
                                onChange={() => {
                                    setFieldValue(
                                        "role",
                                        UserRole.NORMIE,
                                        false
                                    );
                                }}
                                inline
                                name=""
                                label="Normie"
                                type="radio"
                                id="normie-radio"
                                checked={values.role === UserRole.NORMIE}
                            />
                        </Form.Group>
                        <Form.Group className="w-100 mt-2">
                            <Form.Label className="mb-1">
                                <small>Display Name</small>
                            </Form.Label>
                            <Field
                                readOnly
                                className="form-control form-control-sm"
                                name="displayName"
                                type="text"
                            />
                            <ErrorMessage
                                className="text-danger ms-2"
                                name="displayName"
                                component="small"
                            />
                        </Form.Group>
                        <Form.Group className="w-100 mt-2">
                            <Form.Label className="mb-1">
                                <small>Email</small>
                            </Form.Label>
                            <Field
                                readOnly
                                className="form-control form-control-sm"
                                name="email"
                                placeholder="edgar@theravim.com"
                                type="email"
                            />
                            <ErrorMessage
                                className="text-danger ms-2"
                                name="email"
                                component="small"
                            />
                        </Form.Group>
                        {/* <Form.Group className="w-100 mt-2">
                            <Form.Label className="mb-1">
                                <small>Phone</small>
                            </Form.Label>
                            <Field
                                readOnly
                                className="form-control form-control-sm"
                                name="phone"
                                placeholder="+1 (555) 123-1234"
                                type="text"
                            />
                            <ErrorMessage
                                className="text-danger ms-2"
                                name="phone"
                                component="small"
                            />
                        </Form.Group> */}
                        {error && (
                            <Alert
                                className="text-center py-1 w-100 mt-2 mb-0"
                                variant="warning"
                            >
                                <small>{error}</small>
                            </Alert>
                        )}
                        <Button
                            className="d-flex align-items-center justify-content-center mt-2 w-100"
                            disabled={isSubmitting || !dirty}
                            size="sm"
                            type="submit"
                        >
                            {isSubmitting ? (
                                <Spinner size="sm" />
                            ) : (
                                <i className="bi bi-cloud-arrow-up" />
                            )}
                            <span className="ms-2">Save</span>
                        </Button>
                        <Button
                            className="custom-link-btn-muted"
                            onClick={() => {
                                dispatch({
                                    type: AdminActionType.SET_EDIT_USER_MODAL,
                                    payload: { isOpen: false, user: undefined },
                                });
                            }}
                            variant="link"
                        >
                            <small>close</small>
                        </Button>
                    </FForm>
                )}
            </Formik>
        </div>
    );
}
