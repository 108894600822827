import { object, string } from "yup";

export const issueSchema = object().shape({
    name: string().required("Name is required"),
    email: string()
        .required("Email is required")
        .email("Please use a valid email address"),
    issueType: string().required("Issue Type is required"),
    description: string()
        .required("Description is required")
        .max(1000, "Must not exceed 1000 characters")
        .min(10, "Must be at least 10 characters"),
});
