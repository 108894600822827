import { AuthActionType } from "../types/auth";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { UserActionType } from "../types/user"; // enum
import type { UserSettings, UserState } from "../types/user";

// const isDev = process.env.NODE_ENV === "development";

const initialState: UserState = {
    agents: [],
    displayName: undefined,
    email: undefined,
    enabled: undefined,
    id: undefined,
    penName: undefined,
    settings: {
        editorFontSize: 16,
        justifyPreview: true,
        previewFontSize: 16,
        showTooltips: true,
        useDavinci: false,
        useVim: true,
    },
};

const setUserData = createAction<UserState>(UserActionType.SET_USER_DATA);
const updateUserSettings = createAction<UserSettings>(
    UserActionType.SET_USER_SETTINGS
);
const updateUserSettingsSuccess = createAction<UserSettings>(
    UserActionType.UPDATE_USER_SETTINGS_SUCCESS
);
const signOutSuccess = createAction<null>(AuthActionType.SIGN_OUT_START);

const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(setUserData, (state, action) => {
            return {
                ...state,
                ...action.payload,
                settings: {
                    ...state.settings,
                    ...action.payload.settings,
                },
            };
        })
        .addCase(updateUserSettings, (state, action) => {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload,
                },
            };
        })
        .addCase(updateUserSettingsSuccess, (state, action) => {
            return {
                ...state,
                settings: { ...state.settings, ...action.payload },
            };
        })
        .addCase(signOutSuccess, () => {
            return {
                ...initialState,
            };
        });
});

export default reducer;
