import { useState } from "react";
import Docs from "./docs/Docs";
import Contact from "./contact/Contact";
import HelpNavbar from "./HelpNavbar";
import { useWindowSize } from "usehooks-ts";
import Issues from "./issues/Issues";
import Faq from "./docs/Faq";

export enum HelpFeature {
    DOCS = "docs",
    FAQ = "faq",
    ISSUES = "issues",
    CONTACT = "contact",
}
export default function Help() {
    const { width: viewPortWidth } = useWindowSize();
    const isSmall = viewPortWidth <= 576;
    const isMed = viewPortWidth <= 768;
    const [selectedFeature, setSelectedFeature] = useState<HelpFeature>(
        HelpFeature.FAQ
    );
    return (
        <div
            className="container-fluid d-flex flex-column align-items-center w-100"
            style={{
                paddingBottom: "env(safe-area-inset-bottom)",
                paddingTop: "env(safe-area-inset-bottom)",
            }}
        >
            <HelpNavbar
                isSmall={isSmall}
                isMed={isMed}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
            />
            <div
                className="d-flex flex-column align-items-center w-100"
                // style={{ maxWidth: 400 }}
            >
                {selectedFeature === HelpFeature.DOCS && <Docs />}
                {selectedFeature === HelpFeature.FAQ && <Faq />}
                {selectedFeature === HelpFeature.ISSUES && (
                    <Issues isSmall={isSmall} />
                )}
                {selectedFeature === HelpFeature.CONTACT && (
                    <Contact isSmall={isSmall} />
                )}
            </div>
        </div>
    );
}
