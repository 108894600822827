import { AppThunk } from "../store/store";
import {
    DocumentData,
    Query,
    collection,
    getDocs,
    limit,
    query,
    startAfter,
    where,
} from "firebase/firestore";
import { UserRole } from "../types/user";
import db, { functions } from "../firebase/firebase";
import { AdminActionType, AdminUser, Manuscript, Scene } from "../types"; // enum
import { httpsCallable } from "firebase/functions";

const isDev = process.env.NODE_ENV === "development";

export const deleteGoogleAuthUser =
    (user: AdminUser): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            if (!user.email) {
                throw new Error("No user email!");
            }
            dispatch({
                type: AdminActionType.DELETE_GOOGLE_AUTH_USER_START,
            });
            const deleteUser = httpsCallable(functions, "deleteUser");
            const resp = await deleteUser({ email: user.email });
            if (isDev) {
                console.log(
                    "%cDelete Google Auth User Response",
                    "color:orange",
                    resp
                );
            }
            dispatch({
                type: AdminActionType.DELETE_GOOGLE_AUTH_USER_SUCCESS,
                payload: user,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: AdminActionType.DELETE_GOOGLE_AUTH_USER_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const disableGoogleAuthUser =
    (user: AdminUser): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: AdminActionType.DISABLE_GOOGLE_AUTH_USER_START,
            });
            const disableUser = httpsCallable(functions, "disableUser");
            const resp = await disableUser({ email: user.email });
            if (isDev) {
                console.log(
                    "%cDisable Google User Response",
                    "color:orange",
                    resp
                );
            }
            dispatch({
                type: AdminActionType.DISABLE_GOOGLE_AUTH_USER_SUCCESS,
                payload: user,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: AdminActionType.DISABLE_GOOGLE_AUTH_USER_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const enableGoogleAuthUser =
    (user: AdminUser): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: AdminActionType.ENABLE_GOOGLE_AUTH_USER_START,
            });
            const enableUser = httpsCallable(functions, "enableUser");
            const resp = await enableUser({ email: user.email });
            if (isDev) {
                console.log(
                    "%cEnable Google User Response",
                    "color:orange",
                    resp
                );
            }
            dispatch({
                type: AdminActionType.ENABLE_GOOGLE_AUTH_USER_SUCCESS,
                payload: user,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: AdminActionType.ENABLE_GOOGLE_AUTH_USER_FAIL,
            });
            return Promise.reject(error);
        }
    };

export const findUserByEmail =
    (email: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: AdminActionType.FIND_USER_BY_EMAIL_START });
            const findUserByEmailFn = httpsCallable(functions, "getUserInfo");
            const resp: any = await findUserByEmailFn({ email });
            dispatch({
                type: AdminActionType.SET_USERS,
                payload: resp.data.users,
            });
            dispatch({ type: AdminActionType.SET_USERS, payload: [resp.data] });
            dispatch({ type: AdminActionType.FIND_USER_BY_EMAIL_SUCCESS });
            return Promise.resolve();
        } catch (error: any) {
            dispatch({ type: AdminActionType.FIND_USER_BY_EMAIL_FAIL });
            if (error.message && error.message === "not-found") {
                dispatch({ type: AdminActionType.SET_USERS, payload: [] });
                return Promise.reject(new Error("User not found"));
            } else {
                if (isDev) {
                    console.error("Failed to find user by email.");
                }
            }
            return Promise.reject(error);
        }
    };

export const getAllUsers =
    (
        nextPageToken: string | null | undefined = undefined
    ): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: AdminActionType.GET_ALL_USERS_START });

            const getAllUsersFn = httpsCallable(
                functions,
                "getAllUsersWithClaims"
            );
            const resp: any = await getAllUsersFn({ nextPageToken });
            // const { nextPageToken, users } = resp.data;

            dispatch({
                type: AdminActionType.SET_USERS,
                payload: resp.data.users,
            });
            dispatch({ type: AdminActionType.GET_ALL_USERS_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: AdminActionType.GET_ALL_USERS_FAIL });
            return Promise.reject(error);
        }
    };

export const getAllUsers2 = (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
        dispatch({ type: AdminActionType.GET_ALL_USERS_START });

        const q = query(collection(db, "users"), limit(50));
        const querySnapshot = await getDocs(q);
        const users: AdminUser[] = [];
        querySnapshot.forEach((doc) => {
            const user = doc.data() as AdminUser;
            users.push(user);
        });

        dispatch({ type: AdminActionType.SET_USERS, payload: users });
        dispatch({ type: AdminActionType.GET_ALL_USERS_SUCCESS });
        return Promise.resolve();
    } catch (error) {
        dispatch({ type: AdminActionType.GET_ALL_USERS_FAIL });
        return Promise.reject(error);
    }
};

export const getAllManuscripts =
    (
        qLimit: number,
        next?: Query<DocumentData>
    ): AppThunk<Promise<Manuscript[]>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: AdminActionType.GET_ALL_MANUSCRIPTS_START });
            const { role } = getState().user;
            if (role !== UserRole.ADMIN) {
                throw new Error("Not an admin!");
            }
            const q =
                next || query(collection(db, "manuscripts"), limit(qLimit + 1));
            const querySnapshot = await getDocs(q);
            const manuscripts: DocumentData[] = [];
            querySnapshot.forEach((doc) => {
                const id = doc.ref.id;
                manuscripts.push({ ...doc.data(), id });
            });
            if (manuscripts.length > qLimit) {
                manuscripts.pop();
            }
            const hasNext = querySnapshot.docs.length > qLimit;
            const lastVisible =
                querySnapshot.docs[querySnapshot.docs.length - 2];

            const nextQ = query(
                collection(db, "manuscripts"),
                startAfter(lastVisible),
                limit(qLimit)
            );

            dispatch({
                type: AdminActionType.GET_ALL_MANUSCRIPTS_SUCCESS,
                payload: { manuscripts, next: hasNext ? nextQ : undefined },
            });
            return Promise.resolve(manuscripts as Manuscript[]);
        } catch (error) {
            console.warn("Could not get manuscripts", error);
            dispatch({ type: AdminActionType.GET_ALL_MANUSCRIPTS_FAIL });
            return Promise.reject(error);
        }
    };

export const getManuscriptScenesAdmin =
    (manuscript: Manuscript): AppThunk<Promise<Scene[]>> =>
    async (dispatch) => {
        try {
            dispatch({ type: AdminActionType.GET_MANUSCRIPT_SCENES_START });
            const q = query(
                collection(db, "scenes"),
                where("owner", "==", manuscript.owner),
                where("manuscriptId", "==", manuscript.id)
            );
            const querySnapshot = await getDocs(q);
            const scenes: DocumentData[] = [];
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const id = doc.ref.id;
                    scenes.push({ ...doc.data(), id });
                });
            }
            /* DO NOT SORT SCENES HERE!
               Scenes should only be sorted once they have been filtered by section.
            */
            dispatch({
                type: AdminActionType.GET_MANUSCRIPT_SCENES_SUCCESS,
                payload: scenes,
            });
            return Promise.resolve(scenes as Scene[]);
        } catch (error) {
            console.warn("Could not get manuscript scenes", error);
            dispatch({ type: AdminActionType.GET_MANUSCRIPT_SCENES_FAIL });
            return Promise.reject(error);
        }
    };

export const getUsersWithClaim =
    (claim: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: AdminActionType.GET_USERS_WITH_CLAIM_START });
            const getUsersWithClaimFn = httpsCallable(
                functions,
                "getUsersWithClaim"
            );
            const resp: any = await getUsersWithClaimFn({
                claim: claim,
            });
            if (isDev) {
                console.log("getUsersWithClaim resp", resp);
            }
            dispatch({ type: AdminActionType.GET_USERS_WITH_CLAIM_SUCCESS });
            dispatch({
                type: AdminActionType.SET_USERS,
                payload: resp.data.users,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: AdminActionType.GET_USERS_WITH_CLAIM_FAIL });
            return Promise.reject(error);
        }
    };

export const setCustomClaim =
    (uid: string, claim: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: AdminActionType.SET_CUSTOM_CLAIM_START });
            const setCustomClaimFn = httpsCallable(functions, "setCustomClaim");
            await setCustomClaimFn({
                uid: uid,
                claim: claim,
            });
            dispatch({ type: AdminActionType.SET_CUSTOM_CLAIM_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: AdminActionType.SET_CUSTOM_CLAIM_FAIL });
            return Promise.reject(error);
        }
    };
