import { isMobile } from "react-device-detect";
import { Modal } from "react-bootstrap";
import UserCard from "./UserCard";
import { AdminActionType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export default function UserModal() {
    const admin = useAppSelector((state) => state.admin);
    const dispatch = useAppDispatch();
    function handleClose() {
        dispatch({
            type: AdminActionType.SET_EDIT_USER_MODAL,
            payload: {
                isOpen: false,
                user: undefined,
            },
        });
        resetModal();
    }
    function resetModal() {
        //
    }
    return (
        <Modal
            centered={!isMobile}
            show={admin.isEditUserModalOpen}
            onHide={() => handleClose()}
            restoreFocus={false}
        >
            <Modal.Body>
                <div className="d-flex flex-column align-items-center w-100">
                    <UserCard handleClose={handleClose} />
                </div>
            </Modal.Body>
        </Modal>
    );
}
