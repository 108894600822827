import { Navigate } from "react-router-dom";
import { User } from "firebase/auth";

interface Props {
    user: User | null;
    children: JSX.Element;
}
export default function PrivateRoute({ children, user }: Props) {
    if (!user) {
        return <Navigate to="/" replace />;
    }

    return children;
}
