import { useContext, useState } from "react";
import { appleAuthProvider, googleAuthProvider } from "../../firebase";
import { Alert, Button } from "react-bootstrap";
import { getAuth, signInWithPopup } from "firebase/auth";
import { ThemeContext } from "../../contexts/ThemeProvider";
import { useHotkeys } from "react-hotkeys-hook";
import poe from "../../assets/images/poe-icon.png";
import SignInForm from "./SignInForm";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { ActionType } from "../../types";
// import LoadingOverlay from "../shared/loadingOverlay/LoadingOverlay";

const isDev = process.env.NODE_ENV === "development";

export default function SignIn() {
    const { isDark, toggleTheme } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [useEmail, setUseEmail] = useState(false);
    const [error, setError] = useState("");
    useHotkeys("meta+j", () => toggleTheme());

    async function handleSignIn(provider: string) {
        try {
            dispatch({
                type: ActionType.OPEN_LOADING_OVERLAY,
                payload: "Authenticating...",
            });
            setLoading(true);
            setError("");
            const auth = getAuth();

            if (provider === "apple") {
                await signInWithPopup(auth, appleAuthProvider);
            } else if (provider === "google") {
                await signInWithPopup(auth, googleAuthProvider);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            dispatch({
                type: ActionType.CLOSE_LOADING_OVERLAY,
            });
            if (isDev) {
                console.warn(error);
            }
            if (error.code.includes("disabled")) {
                setError("Your account has been disabled");
            } else {
                setError(error.code);
            }
        }
    }
    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center full-height-container">
                <div
                    className="d-flex flex-column align-items-center"
                    style={{ width: 300 }}
                >
                    <img
                        height={useEmail ? 150 : 240}
                        width={useEmail ? 150 : 240}
                        alt="poe-logo"
                        src={poe}
                        style={{
                            filter: `invert(${isDark ? 1 : 0})`,
                            opacity: isDark ? 0.6 : 1,
                        }}
                    />
                    {error && (
                        <Alert
                            className="py-2 mb-0 text-center"
                            variant="danger"
                            style={{ width: 300 }}
                        >
                            {error}
                        </Alert>
                    )}
                    {useEmail ? (
                        <SignInForm />
                    ) : (
                        <div>
                            <Button
                                className="w-100 mt-3"
                                disabled={loading}
                                onClick={() => handleSignIn("apple")}
                                variant={isDark ? "outline-light" : "dark"}
                            >
                                <i className="bi bi-apple me-2" />
                                Sign in with Apple
                            </Button>
                            <Button
                                className={`w-100 mt-${isDark ? 3 : 2}`}
                                disabled={loading}
                                onClick={() => handleSignIn("google")}
                                variant={isDark ? "outline-danger" : "danger"}
                            >
                                <i className="bi bi-google me-2" />
                                Sign in with Google
                            </Button>
                        </div>
                    )}
                    <Button
                        className="custom-link-btn-muted mt-1"
                        onClick={() => {
                            setUseEmail(!useEmail);
                            setError("");
                            setLoading(false);
                        }}
                        variant="link"
                    >
                        <small>{`Sign in with ${
                            useEmail ? "Apple or Google" : "Email"
                        } `}</small>
                    </Button>
                </div>
            </div>
            {/* <LoadingOverlay /> */}
        </>
    );
}
