export enum ChatActionType {
    ADD_MESSAGE = "add_message",
    SEND_CHAT_MESSAGE_FAIL = "send_chat_message_fail",
    SEND_CHAT_MESSAGE_START = "send_chat_message_start",
    SEND_CHAT_MESSAGE_SUCCESS = "send_chat_message_success",
}

export enum ChatDirection {
    INCOMING = "incoming",
    OUTGOING = "outgoing",
}
export enum ChatRole {
    ASSISTANT = "assistant",
    USER = "user",
    SYSTEM = "system",
}
export enum ChatSender {
    CHAT_GPT = "Chat_GPT",
    USER = "user",
}
export interface ChatAPIRequestBody {
    model: ChatModel;
    messages: ChatAPIMessage[];
    useDavinci: boolean
}
export interface ChatAPIResponse {
    data: ChatAPIResponseData;
}
export interface ChatAPIResponseData {
    choices: ChatAPIChoice[];
    createdAt: number;
    id: string;
    model: string;
    object: string;
    usage: ChatAPIUsage;
}
export interface ChatAPIChoice {
    finish_reason: string;
    index: number;
    message: ChatAPIMessage;
}
export interface ChatAPIMessage {
    role: ChatRole;
    content: string;
}
export interface ChatAPIUsage {
    prompt_tokens: number;
    completion_tokens: number;
    totalTokens: number;
}
export interface ChatMessage {
    sender: ChatSender;
    message: string;
    direction: ChatDirection;
    position: "single" | "first" | "normal" | "last" | 0 | 1 | 2 | 3;
}
export enum ChatModel {
    DAVINCI = "text-davinci-003",
    TURBO = "gpt-3.5-turbo",
}
export interface ChatMessagePayload extends ChatMessage {
    useDavinci: boolean;
    userId: string;
}
