import { AuthActionType } from "../types/auth";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { Issue, IssueAction } from "../types";
import uniqBy from "lodash.uniqby";

// const isDev = process.env.NODE_ENV === "development";

const initialState: Issue[] = [];

const createIssueSuccess = createAction<Issue>(
    IssueAction.CREATE_ISSUE_SUCCESS
);
const deleteIssueSuccess = createAction<Issue>(
    IssueAction.DELETE_ISSUE_SUCCESS
);
const updateIssueSuccess = createAction<Issue>(
    IssueAction.UPDATE_ISSUE_SUCCESS
);
const getIssuesSuccess = createAction<Issue[]>(IssueAction.GET_ISSUES_SUCCESS);
const signOutSuccess = createAction<null>(AuthActionType.SIGN_OUT_START);

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getIssuesSuccess, (state, action) => {
            return uniqBy([...state, ...action.payload], "id");
        })
        .addCase(createIssueSuccess, (state, action) => {
            return [...state, action.payload];
        })
        .addCase(deleteIssueSuccess, (state, action) => {
            // return { ...state, issues: [...state.issues, action.payload] };
        })
        .addCase(updateIssueSuccess, (state, action) => {
            // return { ...state, issues: [...state.issues, action.payload] };
        })
        .addCase(signOutSuccess, () => {
            return initialState;
        });
});

export default reducer;
