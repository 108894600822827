import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { ThemeContext } from "../../../contexts/ThemeProvider";
import { useAuth } from "../../../contexts/AuthProvider";
import FaqItem from "./FaqItem";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";

export default function Faq() {
    const { isDark } = useContext(ThemeContext);
    const user = useAuth();
    const navigate = useNavigate();
    const [searchString, setSearchString] = useState("");
    const faqs = useMemo(() => {
        return [
            { header: "Why The Ravim?", path: "./faqs/why_the_ravim.md" },
            {
                header: "How do I get started?",
                path: "./faqs/basic_manuscript_structure.md",
            },
            {
                header: "What is the Story Panel?",
                path: "./faqs/story_panel.md",
            },
            {
                header: "What is the Info Action Panel?",
                path: "./faqs/info_sidebar.md",
            },
            {
                header: "How do I edit with Vim?",
                path: "./faqs/editing_with_vim.md",
            },
            {
                header: "How can I rearrange my manuscript?",
                path: "./faqs/reordering.md",
            },
            {
                header: "How can I check spelling and grammar?",
                path: "./faqs/spell_check.md",
            },
            {
                header: "Who is Edgar?",
                path: "./faqs/edgar.md",
            },
            {
                header: "Can I create character profiles?",
                path: "./faqs/character_profiles.md",
            },
            {
                header: "Can I add a status to a scene or section?",
                path: "./faqs/scene_section_status.md",
            },
            {
                header: "Can I exclude a scene or section from my manuscript?",
                path: "./faqs/exclude.md",
            },
            {
                header: "Can I recover deleted scenes?",
                path: "./faqs/trash.md",
            },
            {
                header: "How can I undo edits to a scene?",
                path: "./faqs/time-travel.md",
            },
            {
                header: "How can I create backup versions of my scenes?",
                path: "./faqs/backups.md",
            },
            {
                header: "How do I search my manuscript?",
                path: "./faqs/search.md",
            },
            {
                header: "How can I style chapter titles and subtitles in my manuscript?",
                path: `./faqs/markdown-${isDark ? "dark" : "light"}.md`,
            },
            {
                header: "How can I preview my manuscript submission?",
                path: "./faqs/preview_manuscript.md",
            },
            {
                header: "How do I track my manuscript submissions to agents?",
                path: "./faqs/submissions.md",
            },
            {
                header: "Can you help me write a query letter?",
                path: "./faqs/query_letter.md",
            },
            {
                header: "How to create an eBook or ePub?",
                path: "./faqs/ebooks.md",
            },
            {
                header: "Can I run The Ravim on my phone, mobile, or tablet?",
                path: "./faqs/mobile.md",
            },
            {
                header: "How does synchronization work?",
                path: "./faqs/synchronization.md",
            },
            {
                header: "Does The Ravim have keyboard shortcuts?",
                path: "./faqs/keyboard_shortcuts.md",
            },
            {
                header: "What is the Diff Editor?",
                path: "./faqs/diff_editor.md",
            },
            {
                header: "What is  Storyline?",
                path: "./faqs/storyline.md",
            },
            {
                header: "WTF is Vim?",
                path: "./faqs/vim.md",
            },
            {
                header: "Where is my data stored?",
                path: "./faqs/where_is_my_stuff.md",
            },
            {
                header: "How can I delete my account?",
                path: "./faqs/delete_account.md",
            },
        ];
    }, [isDark]);

    const [faqItems, setFaqItems] = useState(faqs);

    useEffect(() => {
        if (searchString.length === 0) {
            setFaqItems(faqs);
        } else {
            setFaqItems(
                faqs.filter((item) =>
                    item.header
                        .toLowerCase()
                        .includes(searchString.toLowerCase())
                )
            );
        }
    }, [faqs, searchString]);

    function renderFaqItems() {
        return faqItems.map((item) => (
            <FaqItem key={item.path} header={item.header} path={item.path} />
        ));
    }
    return (
        <div
            className="container d-flex flex-column align-items-center mt-3 mt-sm-4 mb-4 w-100"
            id="faq-container"
            style={{ maxWidth: 600 }}
        >
            <Helmet>
                <title>The Ravim FAQ</title>
                <link rel="canonical" href="https://theravim.com/faq" />

                <meta property="title" content="The Ravim FAQ" />
                <meta
                    name="description"
                    content="The only writing app exclusively created for authors and storytellers that leverages the efficiency of Vim. Custom-tailored features to help you take your novel from concept to publication.  Start your hero’s journey and write the next bestseller with The Ravim."
                />

                <meta property="og:title" content="The Ravim FAQ" />
                <meta
                    property="og:description"
                    content="The only writing app exclusively created for authors and storytellers that leverages the efficiency of Vim. Custom-tailored features to help you take your novel from concept to publication.  Start your hero’s journey and write the next bestseller with The Ravim."
                />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://theravim.com/faq" />
                <meta
                    property="og:image"
                    content="https://theravim.com/the_ravim_logo_200.jpg"
                />
                <meta property="og:image:alt" content="The Ravim" />
            </Helmet>
            <h1 className="mb-3">The Ravim FAQ</h1>
            <div className="d-flex justify-content-center w-100">
                <InputGroup className="mt-3">
                    <InputGroup.Text>
                        <i className="bi bi-filter" />
                    </InputGroup.Text>
                    <Form.Control
                        autoFocus={!isMobile}
                        onChange={(e) => setSearchString(e.target.value)}
                        placeholder="Type to filter"
                        type="search"
                        value={searchString}
                    />
                </InputGroup>
            </div>
            <div className="mt-2 w-100">
                {faqItems.length > 0 ? renderFaqItems() : null}
            </div>
            {!user && (
                <Button
                    className="custom-link-btn-hover-primary mt-3"
                    onClick={() => navigate("/")}
                    variant="link"
                >
                    home
                </Button>
            )}
        </div>
    );
}
