import { httpsCallable } from "firebase/functions";
import { AppThunk } from "../store/store";
import { functions } from "../firebase/firebase";
import { AdminActionType, EmailPayload } from "../types";

const isDev = process.env.NODE_ENV === "development";

export const sendEmail =
    (payload: EmailPayload | EmailPayload): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: AdminActionType.SEND_EMAIL_START,
            });
            const sendMail = httpsCallable(functions, "sendMail");
            const resp = await sendMail({
                html: payload.html,
                recipient: payload.recipient,
                sender: payload.sender,
                subject: payload.subject,
                text: payload.text,
            });
            if (isDev) {
                console.log("%cSend Email Response", "color:orange", resp);
            }
            dispatch({
                type: AdminActionType.SEND_EMAIL_SUCCESS,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: AdminActionType.SEND_EMAIL_FAIL,
            });
            return Promise.reject(error);
        }
    };
