import { createAction, createReducer } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";
import {
    ActionType,
    Character,
    CreateType,
    DeleteType,
    DeletedSceneAction,
    EditMode,
    Manuscript,
    Scene,
    SceneBackup,
    SceneVersion,
    Section,
    Submission,
    ViewMode,
} from "../types";
import { Unsubscribe } from "firebase/firestore";
import { isMobile } from "react-device-detect";
import { AuthActionType } from "../types/auth";

// const isDev = process.env.NODE_ENV === "development";

export interface AppState {
    abandonedSceneId: string;
    backupScene: SceneBackup | undefined;
    activeManuscript: Manuscript | undefined;
    activeScene: Scene | undefined;
    activeSection: Section | undefined;
    agentIdForPrint: string | undefined;
    characterBeingEdited: Character | undefined;
    createType: CreateType | undefined;
    deleteType: DeleteType | undefined;
    deletedSceneAction: DeletedSceneAction | undefined;
    deletedScene: Scene | undefined;
    deletedScenes: Scene[];
    draftActiveSceneText: string;
    draftManuscript: Manuscript | undefined;
    editMode: EditMode;
    edgarText: string | undefined;
    isAbandonedSceneModalOpen: boolean;
    isCharacterBioOpen: boolean;
    isCreateModalOpen: boolean;
    isDiffing: boolean;
    isDeletedSceneModalOpen: boolean;
    isDeleteModalOpen: boolean;
    isDeleteAccountModalOpen: boolean;
    isFullScreen: boolean;
    isHelpModalOpen: boolean;
    isInfoModalOpen: boolean;
    isInfoSidebarOpen: boolean;
    isInternetUp: boolean;
    isLeftSidebarOpen: boolean;
    isListManuscripts: boolean;
    isLoadingManuscripts: boolean;
    isLoadingOverlayOpen: boolean;
    isManuscriptDifferentInDatabase: boolean;
    isManuscriptPdfOpen: boolean;
    isManuscriptPdfLoading: boolean;
    isQueryLetterOpen: boolean;
    isRenameModalOpen: boolean;
    isRevertSceneModalOpen: boolean;
    isSaving: boolean;
    isSearchModalOpen: boolean;
    isSceneCleanerOpen: boolean;
    isSettingsSidebarOpen: boolean;
    isSigningOut: boolean;
    isStoryArcModalOpen: boolean;
    isStorylineCollapsed: boolean;
    isStorylineOpen: boolean;
    isSubmissionFormOpen: boolean;
    isSubscribeModalOpen: boolean;
    isSynopsisEditorOpen: boolean;
    isUnsavedChangesModalOpen: boolean;
    itemToDelete: Manuscript | Scene | Section | undefined;
    itemToRename: Manuscript | Scene | Section | undefined;
    loadingMessage: string;
    manuscripts: Manuscript[];
    manuscriptsUnsubscribe: Unsubscribe | null;
    scenes: Scene[];
    scenesUnsubscribe: Unsubscribe | null;
    selectedScene: Scene | undefined;
    selectedAgentId: string;
    submission: Submission | undefined;
    navigateToTargetScene: Scene | undefined;
    versionToCompare: SceneVersion | undefined;
    viewMode: ViewMode;
}

const initialState = {
    abandonedSceneId: "",
    backupScene: undefined,
    activeManuscript: undefined,
    activeScene: undefined,
    activeSection: undefined,
    agentIdForPrint: undefined,
    characterBeingEdited: undefined,
    createType: undefined,
    deletedSceneAction: undefined,
    deletedScene: undefined,
    deletedScenes: [],
    deleteType: undefined,
    draftActiveSceneText: "",
    draftManuscript: undefined,
    editMode: EditMode.PREVIEW,
    edgarText: undefined,
    isAbandonedSceneModalOpen: false,
    isCharacterBioOpen: false,
    isCreateModalOpen: false,
    isDiffing: false,
    isDeletedSceneModalOpen: false,
    isDeleteModalOpen: false,
    isDeleteAccountModalOpen: false,
    isFullScreen: false,
    isHelpModalOpen: false,
    isInfoModalOpen: false,
    isInfoSidebarOpen: false,
    isInternetUp: true,
    isLeftSidebarOpen: false,
    isListManuscripts: false,
    isLoadingManuscripts: false,
    isLoadingOverlayOpen: true,
    isQueryLetterOpen: false,
    isManuscriptDifferentInDatabase: false,
    isManuscriptPdfOpen: false,
    isManuscriptPdfLoading: false,
    isRenameModalOpen: false,
    isRevertSceneModalOpen: false,
    isSaving: false,
    isSearchModalOpen: false,
    isSceneCleanerOpen: false,
    isSettingsSidebarOpen: false,
    isSigningOut: false,
    isStoryArcModalOpen: false,
    isStorylineCollapsed: true,
    isStorylineOpen: false,
    isSubscribeModalOpen: false,
    isSubmissionFormOpen: false,
    isSynopsisEditorOpen: false,
    isUnsavedChangesModalOpen: false,
    itemToDelete: undefined,
    itemToRename: undefined,
    loadingMessage: "Authenticating...",
    manuscripts: [],
    manuscriptsUnsubscribe: null,
    scenes: [],
    scenesUnsubscribe: null,
    selectedAgentId: "",
    selectedScene: undefined,
    submission: undefined,
    navigateToTargetScene: undefined,
    versionToCompare: undefined,
    viewMode: ViewMode.LIST,
} as AppState;

const cleanSceneSuccess = createAction<string>(ActionType.CLEAN_SCENE_SUCCESS);
const closeAbandonedSceneModal = createAction<null>(
    ActionType.CLOSE_ABANDONED_SCENE_MODAL
);
const closeActiveScene = createAction<null>(ActionType.CLOSE_ACTIVE_SCENE);
const closeActiveSection = createAction<null>(ActionType.CLOSE_ACTIVE_SECTION);
const closeCreateModal = createAction<null>(ActionType.CLOSE_CREATE_MODAL);
const closeDeleteModal = createAction<null>(ActionType.CLOSE_DELETE_MODAL);
const closeLoadingOverlay = createAction<null>(
    ActionType.CLOSE_LOADING_OVERLAY
);
const closeManuscript = createAction<null>(ActionType.CLOSE_MANUSCRIPT);
const closeRenameModal = createAction<null>(ActionType.CLOSE_RENAME_MODAL);
const closeRevertSceneModal = createAction<null>(
    ActionType.CLOSE_REVERT_SCENE_MODAL
);
const createManuscriptSuccess = createAction<Manuscript>(
    ActionType.CREATE_MANUSCRIPT_SUCCESS
);
const createSceneSuccess = createAction<{ scene: Scene; scenes: Scene[] }>(
    ActionType.CREATE_SCENE_SUCCESS
);
const createSectionSuccess = createAction<{
    manuscript: Manuscript;
    section: Section;
}>(ActionType.CREATE_SECTION_SUCCESS);
const createUserWithEmailStart = createAction<null>(
    AuthActionType.CREATE_USER_WITH_EMAIL_START
);
const createUserWithEmailSuccess = createAction<null>(
    AuthActionType.CREATE_USER_WITH_EMAIL_SUCCESS
);
const deleteManuscriptSuccess = createAction<string>(
    ActionType.DELETE_MANUSCRIPT_SUCCESS
);
const deleteSceneSuccess = createAction<{
    sceneIdToDelete: string;
    scenes: Scene[];
}>(ActionType.DELETE_SCENE_SUCCESS);
const deleteSectionSuccess = createAction<Manuscript>(
    ActionType.DELETE_SECTION_SUCCESS
);
const getDeletedScenesSuccess = createAction<Scene[]>(
    ActionType.GET_DELETED_SCENES_SUCCESS
);
const getManuscriptScenesSuccess = createAction<Scene[]>(
    ActionType.GET_MANUSCRIPT_SCENES_SUCCESS
);
const getManuscriptSuccess = createAction<Manuscript>(
    ActionType.GET_MANUSCRIPT_SUCCESS
);
const getManuscriptsSuccess = createAction<Manuscript[]>(
    ActionType.GET_MANUSCRIPTS_SUCCESS
);
const moveSceneSuccess = createAction<{ scene: Scene; scenes: Scene[] }>(
    ActionType.MOVE_SCENE_SUCCESS
);
const openAbandonedSceneModal = createAction<{ abandonedSceneId: string }>(
    ActionType.OPEN_ABANDONED_SCENE_MODAL
);
const openCreateModal = createAction<CreateType>(ActionType.OPEN_CREATE_MODAL);
const openDeleteModal = createAction<{
    deleteType: DeleteType;
    itemToDelete: Manuscript | Scene | undefined;
}>(ActionType.OPEN_DELETE_MODAL);
const openLoadingOverlay = createAction<string>(
    ActionType.OPEN_LOADING_OVERLAY
);
const openRenameModal = createAction<Manuscript | Scene | Section | undefined>(
    ActionType.OPEN_RENAME_MODAL
);
const openRevertSceneModal = createAction<null>(
    ActionType.OPEN_REVERT_SCENE_MODAL
);
const removeDeletedSceneSuccess = createAction<string>(
    ActionType.REMOVE_DELETED_SCENE_SUCCESS
);
const renameSceneSuccess = createAction<Scene>(ActionType.RENAME_SCENE_SUCCESS);
const restoreDeletedSceneSuccess = createAction<{
    scene: Scene;
    section: Section;
}>(ActionType.RESTORE_DELETED_SCENE_SUCCESS);
const restoreSceneFromLocalStorageStart = createAction<Scene>(
    ActionType.RESTORE_SCENE_FROM_LOCAL_STORAGE_START
);
const restoreSceneFromLocalStorageSuccess = createAction<Scene>(
    ActionType.RESTORE_SCENE_FROM_LOCAL_STORAGE_SUCCESS
);
const revertActiveScene = createAction<null>(ActionType.REVERT_ACTIVE_SCENE);
const saveActiveSceneStart = createAction<string | undefined>(
    ActionType.SAVE_ACTIVE_SCENE_START
);
const saveActiveSceneSuccess = createAction<Scene>(
    ActionType.SAVE_ACTIVE_SCENE_SUCCESS
);
const deleteSceneBackupSuccess = createAction<Scene>(
    ActionType.DELETE_SCENE_BACKUP_SUCCESS
);
const saveSceneBackupSuccess = createAction<Scene>(
    ActionType.SAVE_SCENE_BACKUP_SUCCESS
);
const saveSceneVersionSuccess = createAction<Scene>(
    ActionType.SAVE_SCENE_VERSION_SUCCESS
);
const saveUnsavedSceneSuccess = createAction<{
    navigateToTargetScene: Scene;
    updatedScene: Scene;
}>(ActionType.SAVE_UNSAVED_SCENE_SUCCESS);
const setActiveManuscript = createAction<Manuscript>(
    ActionType.SET_ACTIVE_MANUSCRIPT
);
const setActiveScene = createAction<Scene>(ActionType.SET_ACTIVE_SCENE);
const setActiveSection = createAction<Section>(ActionType.SET_ACTIVE_SECTION);
const setBackupScene = createAction<SceneBackup | undefined>(
    ActionType.SET_BACKUP_SCENE
);
const setAgentForPrint = createAction<string>(ActionType.SET_AGENT_FOR_PRINT);
const setCreateType = createAction<CreateType>(ActionType.SET_CREATE_TYPE);
const setDraftActiveSceneText = createAction<string>(
    ActionType.SET_DRAFT_ACTIVE_SCENE_TEXT
);
const setEdgarText = createAction<string>(ActionType.SET_EDGAR_TEXT);
const setEditMode = createAction<EditMode>(ActionType.SET_EDIT_MODE);
const setIsCharacterBioOpen = createAction<{
    isOpen: boolean;
    characterBeingEdited: Character | undefined;
}>(ActionType.SET_IS_CHARACTER_BIO_OPEN);
const setIsCreateModalOpen = createAction<boolean>(
    ActionType.SET_IS_CREATE_MODAL_OPEN
);
const setIsDeleteSceneModalOpen = createAction<{
    isOpen: boolean;
    deletedSceneAction: DeletedSceneAction | undefined;
    deletedScene: Scene | undefined;
}>(ActionType.SET_IS_DELETED_SCENE_MODAL_OPEN);
const setIsDeleteAccountModalOpen = createAction<boolean>(
    ActionType.SET_IS_DELETE_ACCOUNT_MODAL_OPEN
);
const setIsFullScreen = createAction<boolean>(ActionType.SET_IS_FULLSCREEN);
const setIsInfoModalOpen = createAction<boolean>(
    ActionType.SET_IS_INFO_MODAL_OPEN
);
const setIsInfoSidebarOpen = createAction<boolean>(
    ActionType.SET_IS_INFO_SIDEBAR_OPEN
);
const setIsInternetUp = createAction<boolean>(ActionType.SET_IS_INTERNET_UP);
const setIsLeftSidebarOpen = createAction<boolean>(
    ActionType.SET_IS_LEFT_SIDEBAR_OPEN
);
const setIsManuscriptDifferentInDatabase = createAction<boolean>(
    ActionType.SET_IS_MANUSCRIPT_DIFFERENT_IN_DATABASE
);
// const setIsManuscriptPdfLoading = createAction<boolean>(
//     ActionType.SET_IS_MANUSCRIPT_PDF_LOADING
// );
const setIsManuscriptPdfOpen = createAction<boolean>(
    ActionType.SET_IS_MANUSCRIPT_PDF_OPEN
);
const setIsQueryLetterOpen = createAction<boolean>(
    ActionType.SET_IS_QUERY_LETTER_OPEN
);
const setIsSearchModalOpen = createAction<boolean>(
    ActionType.SET_IS_SEARCH_MODAL_OPEN
);
const setIsSceneCleanerOpen = createAction<boolean>(
    ActionType.SET_IS_SCENE_CLEANER_OPEN
);
const setIsSettingsSidebarOpen = createAction<boolean>(
    ActionType.SET_IS_SETTINGS_SIDEBAR_OPEN
);
const setIsStoryArcModal = createAction<boolean>(
    ActionType.SET_IS_STORY_ARC_MODAL_OPEN
);
const setIsStorylineCollapsed = createAction<boolean>(
    ActionType.SET_IS_STORYLINE_COLLAPSED
);
const setIsStorylineOpen = createAction<boolean>(
    ActionType.SET_IS_STORYLINE_OPEN
);
const setIsSubscribeModalOpen = createAction<boolean>(
    ActionType.SET_IS_SUBSCRIBE_MODAL_OPEN
);
const setIsSubmissionFormOpen = createAction<{
    isOpen: boolean;
    submission: Submission | undefined;
}>(ActionType.SET_IS_SUBMISSION_FORM_OPEN);
const setIsSynopsisEditorOpen = createAction<boolean>(
    ActionType.SET_IS_SYNOPSIS_EDITOR_OPEN
);
const setIsUnsavedChangesModalOpen = createAction<{
    isOpen: boolean;
    navigateToTargetScene: Scene | undefined;
}>(ActionType.SET_IS_UNSAVED_CHANGES_MODAL_OPEN);
const setLoadingMessage = createAction<string>(ActionType.SET_LOADING_MESSAGE);
const setManuscripts = createAction<Manuscript[]>(ActionType.SET_MANUSCRIPTS);
const setMergeEditor = createAction<{ isDiffing: boolean }>(
    ActionType.SET_MERGE_EDITOR
);
const setScenes = createAction<Scene[]>(ActionType.SET_SCENES);
const setSelectedScene = createAction<Scene>(ActionType.SET_SELECTED_SCENE);
const setTargetScene = createAction<Scene>(ActionType.SET_TARGET_SCENE);
const setVersionToCompare = createAction<SceneVersion | undefined>(
    ActionType.SET_VERSION_TO_COMPARE
);
const setViewMode = createAction<ViewMode>(ActionType.SET_VIEW_MODE);
const signInUserWithEmailSuccess = createAction<null>(
    AuthActionType.SIGN_IN_USER_WITH_EMAIL_SUCCESS
);
const updateManuscriptSuccess = createAction<Manuscript>(
    ActionType.UPDATE_MANUSCRIPT_SUCCESS
);
const updateScene = createAction<Scene>(ActionType.UPDATE_SCENE);
const updateSceneOrderSuccess = createAction<Scene[]>(
    ActionType.UPDATE_SCENE_ORDER_SUCCESS
);
const updateSceneSuccess = createAction<{ scene: Scene; section: Section }>(
    ActionType.UPDATE_SCENE_SUCCESS
);
const updateSection = createAction<Section>(ActionType.UPDATE_SECTION);

const signOutStart = createAction<null>(AuthActionType.SIGN_OUT_START);
const signOutSuccess = createAction<null>(AuthActionType.SIGN_OUT_SUCCESS);

const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(cleanSceneSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.filter(
                    (item) => item.id !== action.payload
                ),
            };
        })
        .addCase(getManuscriptSuccess, (state, action) => {
            return {
                ...state,
                manuscripts: state.manuscripts.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeManuscript: action.payload,
                draftManuscript: action.payload,
            };
        })
        .addCase(getManuscriptsSuccess, (state, action) => {
            const temp = { ...state };
            if (action.payload.length === 1) {
                temp.activeManuscript = action.payload[0];
                temp.draftManuscript = action.payload[0];
            }
            return {
                ...temp,
                manuscripts: action.payload,
            };
        })
        .addCase(getDeletedScenesSuccess, (state, action) => {
            /* DO NOT SORT SCENES HERE!
               Scenes should only be sorted once they have been filtered by section.
            */
            return {
                ...state,
                deletedScenes: action.payload,
            };
        })
        .addCase(getManuscriptScenesSuccess, (state, action) => {
            /* DO NOT SORT SCENES HERE!
               Scenes should only be sorted once they have been filtered by section.
            */
            return {
                ...state,
                scenes: action.payload,
            };
        })
        .addCase(updateSceneOrderSuccess, (state, action) => {
            const thing = action.payload.map((item) => ({
                title: item.title,
                order: item.order,
            }));
            console.log("update scene order payload", thing);
            return {
                ...state,
                scenes: state.scenes.map((stateScene) => {
                    const match = action.payload.find(
                        (item) => item.id === stateScene.id
                    );
                    if (match) {
                        return match;
                    } else {
                        return stateScene;
                    }
                }),
            };
        })
        .addCase(setLoadingMessage, (state, action) => {
            return {
                ...state,
                loadingMessage: action.payload,
            };
        })
        .addCase(createManuscriptSuccess, (state, action) => {
            return {
                ...state,
                manuscripts: uniqBy(
                    [...state.manuscripts, action.payload],
                    "title"
                ),
                activeManuscript: action.payload,
                draftManuscript: action.payload,
                activeScene: undefined,
                activeSection: undefined,
                createType: undefined,
                isCreateModalOpen: false,
            };
        })
        .addCase(deleteManuscriptSuccess, (state, action) => {
            return {
                ...state,
                manuscripts: state.manuscripts.filter(
                    (item) => item.id !== action.payload
                ),
                activeManuscript: undefined,
                draftManuscript: undefined,
                activeSection: undefined,
                activeScene: undefined,
                draftActiveSceneText: "",
                deleteType: undefined,
                isDeleteModalOpen: false,
            };
        })
        .addCase(updateManuscriptSuccess, (state, action) => {
            return {
                ...state,
                manuscripts: state.manuscripts.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeManuscript: action.payload,
                draftManuscript: action.payload,
            };
        })
        .addCase(createSectionSuccess, (state, action) => {
            return {
                ...state,
                draftManuscript: action.payload.manuscript,
                activeSection: action.payload.section,
                createType: undefined,
                isCreateModalOpen: false,
            };
        })
        .addCase(deleteSectionSuccess, (state, action) => {
            return {
                ...state,
                manuscripts: state.manuscripts.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeManuscript: action.payload,
                draftManuscript: action.payload,
                activeSection: undefined,
                deleteType: undefined,
            };
        })
        .addCase(createSceneSuccess, (state, action) => {
            const updatedScenes = state.scenes.map((stateScene) => {
                const match = action.payload.scenes.find(
                    (item) => item.id === stateScene.id
                );
                if (match) {
                    return match;
                } else {
                    return stateScene;
                }
            });
            return {
                ...state,
                scenes: [...updatedScenes, action.payload.scene],
                // I may have fixed this, remove commentary if confirmed
                // this isn't adding new scene because new scene isn't in state yet.
                // scenes: state.scenes.map((stateScene) => {
                //     const match = action.payload.scenes.find(
                //         (item) => item.id === stateScene.id
                //     );
                //     if (match) {
                //         return match;
                //     } else {
                //         return stateScene;
                //     }
                // }),
                activeScene: action.payload.scene,
                draftActiveSceneText: action.payload.scene.text,
                createType: undefined,
                isCreateModalOpen: false,
            };
        })
        .addCase(moveSceneSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((stateScene) => {
                    const match = action.payload.scenes.find(
                        (item) => item.id === stateScene.id
                    );
                    if (match) {
                        return match;
                    } else {
                        return stateScene;
                    }
                }),
            };
        })
        .addCase(deleteSceneSuccess, (state, action) => {
            const deleted = state.scenes.find(
                (item) => item.id === action.payload.sceneIdToDelete
            );
            const filteredScenes = state.scenes.filter(
                (item) => item.id !== action.payload.sceneIdToDelete
            );
            return {
                ...state,
                deletedScenes:
                    deleted !== undefined
                        ? [...state.deletedScenes, deleted]
                        : [...state.deletedScenes],
                scenes: filteredScenes.map((stateScene) => {
                    const match = action.payload.scenes.find(
                        (item) => item.id === stateScene.id
                    );
                    if (match) {
                        return match;
                    } else {
                        return stateScene;
                    }
                }),
                activeScene: undefined,
                draftActiveSceneText: "",
                createType: undefined,
                isDeleteModalOpen: false,
            };
        })
        .addCase(saveActiveSceneStart, (state, action) => {
            return {
                ...state,
                isSaving: true,
                isLoadingOverlayOpen: true,
                loadingMessage: `Saving ${action.payload || "scene"}...`,
            };
        })
        .addCase(removeDeletedSceneSuccess, (state, action) => {
            return {
                ...state,
                deletedScenes: state.deletedScenes.filter(
                    (item) => item.id !== action.payload
                ),
            };
        })
        .addCase(renameSceneSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                isSaving: false,
                isLoadingOverlayOpen: false,
                loadingMessage: "",
            };
        })
        .addCase(saveActiveSceneSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeScene: action.payload,
                draftActiveSceneText: action.payload.text,
                isSaving: false,
                isLoadingOverlayOpen: false,
                loadingMessage: "",
            };
        })
        .addCase(deleteSceneBackupSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeScene: action.payload,
                draftActiveSceneText: action.payload.text,
                backupScene: undefined,
                isDiffing: false,
            };
        })
        .addCase(saveSceneBackupSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeScene: action.payload,
                draftActiveSceneText: action.payload.text,
            };
        })
        .addCase(saveSceneVersionSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeScene: action.payload,
                draftActiveSceneText: action.payload.text,
                versionToCompare: undefined,
                isDiffing: false,
            };
        })
        .addCase(saveUnsavedSceneSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.updatedScene.id) {
                        return action.payload.updatedScene;
                    } else {
                        return item;
                    }
                }),
                activeScene: state.navigateToTargetScene,
                draftActiveSceneText: state.navigateToTargetScene?.text || "",
            };
        })
        .addCase(restoreSceneFromLocalStorageStart, (state) => {
            return {
                ...state,
                editMode: EditMode.PREVIEW,
            };
        })
        .addCase(restoreDeletedSceneSuccess, (state, action) => {
            return {
                ...state,
                scenes: [...state.scenes, action.payload.scene],
                activeSection: action.payload.section,
                activeScene: action.payload.scene,
                draftActiveSceneText: action.payload.scene.text,
            };
        })
        .addCase(restoreSceneFromLocalStorageSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
                activeScene: action.payload,
                draftActiveSceneText: action.payload.text,
            };
        })
        .addCase(updateSceneSuccess, (state, action) => {
            return {
                ...state,
                scenes: state.scenes.map((item) => {
                    if (item.id === action.payload.scene.id) {
                        return action.payload.scene;
                    } else {
                        return item;
                    }
                }),
                activeScene: action.payload.scene,
                activeSection: action.payload.section,
                setDraftActiveSceneText: action.payload.scene.text,
            };
        })
        .addCase(openCreateModal, (state, action) => {
            return {
                ...state,
                isCreateModalOpen: true,
                createType: action.payload,
            };
        })
        .addCase(closeCreateModal, (state) => {
            return {
                ...state,
                isCreateModalOpen: false,
                createType: undefined,
            };
        })
        .addCase(openDeleteModal, (state, action) => {
            return {
                ...state,
                isDeleteModalOpen: true,
                deleteType: action.payload.deleteType,
                itemToDelete: action.payload.itemToDelete,
            };
        })
        .addCase(openRenameModal, (state, action) => {
            return {
                ...state,
                isRenameModalOpen: true,
                itemToRename: action.payload,
            };
        })
        .addCase(openRevertSceneModal, (state) => {
            return {
                ...state,
                isRevertSceneModalOpen: true,
            };
        })
        .addCase(closeDeleteModal, (state) => {
            return {
                ...state,
                isDeleteModalOpen: false,
                deleteType: undefined,
                itemToDelete: undefined,
            };
        })
        .addCase(closeRenameModal, (state) => {
            return {
                ...state,
                isRenameModalOpen: false,
                itemToRename: undefined,
            };
        })
        .addCase(closeRevertSceneModal, (state) => {
            return {
                ...state,
                isRevertSceneModalOpen: false,
            };
        })
        .addCase(openLoadingOverlay, (state, action) => {
            return {
                ...state,
                isLoadingOverlayOpen: true,
                loadingMessage: action.payload,
            };
        })
        .addCase(setAgentForPrint, (state, action) => {
            return {
                ...state,
                agentIdForPrint: action.payload,
            };
        })
        .addCase(setIsCharacterBioOpen, (state, action) => {
            return {
                ...state,
                isCharacterBioOpen: action.payload.isOpen,
                characterBeingEdited: action.payload.characterBeingEdited,
                isInfoSidebarOpen: action.payload.isOpen
                    ? false
                    : state.isInfoSidebarOpen,
            };
        })
        .addCase(setIsDeleteSceneModalOpen, (state, action) => {
            return {
                ...state,
                isDeletedSceneModalOpen: action.payload.isOpen,
                deletedSceneAction: action.payload.deletedSceneAction,
                deletedScene: action.payload.deletedScene,
            };
        })
        .addCase(setIsDeleteAccountModalOpen, (state, action) => {
            return {
                ...state,
                isDeleteAccountModalOpen: action.payload,
            };
        })
        .addCase(openAbandonedSceneModal, (state, action) => {
            return {
                ...state,
                isAbandonedSceneModalOpen: true,
                abandonedSceneId: action.payload.abandonedSceneId,
            };
        })
        .addCase(closeAbandonedSceneModal, (state) => {
            return {
                ...state,
                isAbandonedSceneModalOpen: false,
            };
        })
        .addCase(setIsSubscribeModalOpen, (state, action) => {
            return {
                ...state,
                isSubscribeModalOpen: action.payload,
            };
        })
        .addCase(setIsSubmissionFormOpen, (state, action) => {
            return {
                ...state,
                isSubmissionFormOpen: action.payload.isOpen,
                isInfoSidebarOpen: action.payload.isOpen
                    ? false
                    : state.isInfoSidebarOpen,
                isQueryLetterOpen: action.payload.isOpen
                    ? false
                    : state.isQueryLetterOpen,
                submission: action.payload.submission,
            };
        })
        .addCase(setIsSynopsisEditorOpen, (state, action) => {
            return {
                ...state,
                isSynopsisEditorOpen: action.payload,
                isInfoSidebarOpen: !action.payload,
            };
        })
        .addCase(setIsUnsavedChangesModalOpen, (state, action) => {
            return {
                ...state,
                isUnsavedChangesModalOpen: action.payload.isOpen,
                navigateToTargetScene: action.payload.navigateToTargetScene,
            };
        })
        .addCase(setIsManuscriptDifferentInDatabase, (state, action) => {
            return {
                ...state,
                isManuscriptDifferentInDatabase: action.payload,
            };
        })
        .addCase(setIsQueryLetterOpen, (state, action) => {
            return {
                ...state,
                isQueryLetterOpen: action.payload,
            };
        })
        // .addCase(setIsManuscriptPdfLoading, (state, action) => {
        //     return {
        //         ...state,
        //         isManuscriptPdfLoading: action.payload,
        //     };
        // })
        .addCase(setIsManuscriptPdfOpen, (state, action) => {
            return {
                ...state,
                isManuscriptPdfOpen: action.payload,
                // isManuscriptPdfLoading: action.payload,
                isInfoSidebarOpen: action.payload
                    ? true
                    : state.isInfoSidebarOpen,
                isStorylineOpen: action.payload ? false : state.isStorylineOpen,
            };
        })
        .addCase(setMergeEditor, (state, action) => {
            return {
                ...state,
                ...action.payload,
                isSettingsSidebarOpen: false,
                isLeftSidebarOpen: false,
                isInfoSidebarOpen: false,
            };
        })
        .addCase(closeLoadingOverlay, (state) => {
            return {
                ...state,
                isLoadingOverlayOpen: false,
                loadingMessage: "",
            };
        })
        .addCase(closeActiveScene, (state) => {
            return {
                ...state,
                activeScene: undefined,
                draftActiveSceneText: "",
            };
        })
        .addCase(closeActiveSection, (state) => {
            return {
                ...state,
                activeScene: undefined,
                activeSection: undefined,
                draftActiveSceneText: "",
                isLeftSidebarOpen: isMobile ? false : state.isLeftSidebarOpen,
            };
        })
        .addCase(closeManuscript, (state) => {
            const ms = state.manuscripts.find(
                (item) => item.id === state.draftManuscript?.id
            );
            const manuscripts = state.manuscripts.map((item) => {
                if (item.id === ms?.id) {
                    return ms;
                } else {
                    return item;
                }
            });
            return {
                ...state,
                manuscripts,
                activeManuscript: undefined,
                activeScene: undefined,
                activeSection: undefined,
                compiledManuscript: "",
                isManuscriptPdfOpen: false,
                isSceneCleanerOpen: false,
                draftActiveSceneText: "",
                draftManuscript: undefined,
                scenes: [],
            };
        })
        .addCase(createUserWithEmailStart, (state) => {
            return {
                ...state,
                isLoadingOverlayOpen: true,
                loadingMessage: "Creating user account...",
            };
        })
        .addCase(createUserWithEmailSuccess, (state) => {
            return {
                ...state,
                // isLoadingOverlayOpen: false,
                // loadingMessage: "",
            };
        })
        .addCase(signInUserWithEmailSuccess, (state) => {
            return {
                ...state,
                // isLoadingOverlayOpen: false,
                // loadingMessage: "",
            };
        })
        .addCase(setActiveManuscript, (state, action) => {
            return {
                ...state,
                activeManuscript: action.payload,
                draftManuscript: action.payload,
            };
        })
        .addCase(revertActiveScene, (state) => {
            return {
                ...state,
                draftActiveSceneText: state.activeScene?.text || "",
            };
        })
        .addCase(setActiveScene, (state, action) => {
            const section = state.draftManuscript?.sections.find(
                (section) => section.id === action.payload.sectionId
            );
            return {
                ...state,
                activeScene: action.payload,
                compiledManuscript: "",
                draftActiveSceneText: action.payload.text,
                activeSection: section,
                isLeftSidebarOpen: isMobile ? false : state.isLeftSidebarOpen,
                isStorylineOpen: false,
                isManuscriptPdfOpen: false,
            };
        })
        .addCase(setDraftActiveSceneText, (state, action) => {
            return {
                ...state,
                draftActiveSceneText: action.payload,
            };
        })
        .addCase(setBackupScene, (state, action) => {
            return {
                ...state,
                backupScene: action.payload,
                isDiffing: action.payload ? true : false,
                isInfoSidebarOpen: action.payload === undefined ? true : false,
            };
        })
        .addCase(setActiveSection, (state, action) => {
            return {
                ...state,
                activeSection: action.payload,
                activeScene: undefined,
                compiledManuscript: "",
                draftActiveSceneText: "",
                isLeftSidebarOpen: isMobile ? false : state.isLeftSidebarOpen,
            };
        })
        .addCase(setCreateType, (state, action) => {
            return {
                ...state,
                createType: action.payload,
            };
        })
        .addCase(setEdgarText, (state, action) => {
            return {
                ...state,
                edgarText: action.payload,
                isDiffing: action.payload ? true : false,
                isInfoSidebarOpen: action.payload === undefined ? true : false,
            };
        })
        .addCase(setEditMode, (state, action) => {
            return {
                ...state,
                editMode: action.payload,
                isLeftSidebarOpen:
                    isMobile && action.payload === EditMode.EDIT
                        ? false
                        : state.isLeftSidebarOpen,
            };
        })
        .addCase(setIsCreateModalOpen, (state, action) => {
            return {
                ...state,
                isCreateModalOpen: action.payload,
            };
        })
        .addCase(setIsFullScreen, (state, action) => {
            return {
                ...state,
                isFullScreen: action.payload,
            };
        })
        .addCase(setIsInfoModalOpen, (state, action) => {
            return {
                ...state,
                isInfoModalOpen: action.payload,
            };
        })
        .addCase(setIsInfoSidebarOpen, (state, action) => {
            return {
                ...state,
                isInfoSidebarOpen: action.payload,
                isSettingsSidebarOpen:
                    action.payload === true
                        ? false
                        : state.isSettingsSidebarOpen,
            };
        })
        .addCase(setIsInternetUp, (state, action) => {
            return {
                ...state,
                isInternetUp: action.payload,
            };
        })
        .addCase(setIsLeftSidebarOpen, (state, action) => {
            return {
                ...state,
                isLeftSidebarOpen: action.payload,
            };
        })
        .addCase(setIsSceneCleanerOpen, (state, action) => {
            return {
                ...state,
                isSceneCleanerOpen: action.payload,
            };
        })
        .addCase(setIsSearchModalOpen, (state, action) => {
            return {
                ...state,
                isSearchModalOpen: action.payload,
            };
        })
        .addCase(setIsSettingsSidebarOpen, (state, action) => {
            return {
                ...state,
                isSettingsSidebarOpen: action.payload,
                isInfoSidebarOpen:
                    action.payload === true ? false : state.isInfoSidebarOpen,
            };
        })
        .addCase(setIsStoryArcModal, (state, action) => {
            return {
                ...state,
                isStoryArcModalOpen: action.payload,
            };
        })
        .addCase(setIsStorylineCollapsed, (state, action) => {
            return {
                ...state,
                isStorylineCollapsed: action.payload,
            };
        })
        .addCase(setIsStorylineOpen, (state, action) => {
            return {
                ...state,
                isStorylineOpen: action.payload,
                isSettingsSidebarOpen: false,
                isLeftSidebarOpen: false,
                isInfoSidebarOpen: false,
                isManuscriptPdfOpen: false,
            };
        })
        .addCase(setManuscripts, (state, action) => {
            return {
                ...state,
                manuscripts: action.payload,
            };
        })
        .addCase(setScenes, (state, action) => {
            return {
                ...state,
                scenes: action.payload,
            };
        })
        .addCase(setSelectedScene, (state, action) => {
            return {
                ...state,
                selectedScene: action.payload,
                isLeftSidebarOpen: isMobile ? false : state.isLeftSidebarOpen,
            };
        })
        .addCase(setTargetScene, (state, action) => {
            return {
                ...state,
                activeScene: action.payload,
                draftActiveSceneText: action.payload.text,
                navigateToTargetScene: undefined,
            };
        })
        .addCase(setVersionToCompare, (state, action) => {
            return {
                ...state,
                versionToCompare: action.payload,
                isDiffing: action.payload ? true : false,
                isInfoSidebarOpen: action.payload === undefined ? true : false,
            };
        })
        .addCase(setViewMode, (state, action) => {
            return {
                ...state,
                viewMode: action.payload,
            };
        })
        .addCase(updateScene, (state, action) => {
            return {
                ...state,
                activeScene: action.payload,
            };
        })
        .addCase(updateSection, (state, action) => {
            return {
                ...state,
                activeSection: action.payload,
            };
        })
        .addCase(signOutStart, (state) => {
            return { ...state, isSigningOut: true };
        })
        .addCase(signOutSuccess, () => {
            return initialState;
        });
});

export default reducer;
