import { createAction, createReducer } from "@reduxjs/toolkit";
import { AuthActionType } from "../types/auth";
import {
    ChatActionType,
    ChatDirection,
    ChatMessage,
    ChatSender,
} from "../types/chat";

const initialMessage: ChatMessage = {
    message: "I am Edgar.",
    sender: ChatSender.CHAT_GPT,
    position: "single",
    direction: ChatDirection.INCOMING,
};
export interface ChatState {
    messages: ChatMessage[];
}

const initialState = {
    messages: [initialMessage],
} as ChatState;

const addMessage = createAction<ChatMessage>(
    ChatActionType.ADD_MESSAGE
);

const sendChatMessageSuccess = createAction<ChatMessage>(
    ChatActionType.SEND_CHAT_MESSAGE_SUCCESS
);
const signOut = createAction<null>(AuthActionType.SIGN_OUT_SUCCESS);
const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(addMessage, (state, action) => {
            return { ...state, messages: [...state.messages, action.payload] };
        })
        .addCase(sendChatMessageSuccess, (state, action) => {
            return { ...state, messages: [...state.messages, action.payload] };
        })
        .addCase(signOut, () => {
            return initialState;
        });
});

export default reducer;
