import { AdminActionType, UserRole } from "../../types"; // enum
import { useAppDispatch } from "../../hooks/reduxHooks";
import OverlayTriggerButton from "../shared/OverlayTriggerButton";
import type { AdminUser } from "../../types";

interface Props {
    user: AdminUser;
}
export default function AdminActionBar({ user }: Props) {
    const dispatch = useAppDispatch();
    return (
        <div className="d-flex align-items-center">
            <OverlayTriggerButton
                classNames=""
                icon="pencil-square"
                fn={() => {
                    dispatch({
                        type: AdminActionType.SET_EDIT_USER_MODAL,
                        payload: { isOpen: true, user },
                    });
                }}
                tooltipText="Edit"
            />
            <OverlayTriggerButton
                disabled={user.role === UserRole.ADMIN}
                classNames={
                    user.enabled === true ? "text-success" : "text-danger"
                }
                icon={`person-${user.enabled === true ? "down" : "up"}`}
                fn={() => {
                    dispatch({
                        type: AdminActionType.SET_DISABLE_USER_MODAL,
                        payload: { isOpen: true, user },
                    });
                }}
                tooltipText="Disable User Account"
            />
            <OverlayTriggerButton
                disabled={user.role === UserRole.ADMIN}
                classNames="text-danger"
                icon="trash"
                fn={() => {
                    dispatch({
                        type: AdminActionType.SET_DELETE_USER_MODAL,
                        payload: { isOpen: true, user },
                    });
                }}
                tooltipText="Delete"
            />
        </div>
    );
}
