import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../contexts/ThemeProvider";
import { useContext, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Features from "./Features";
import poe from "../../assets/images/poe-icon.png";
import { Helmet } from "react-helmet";

export default function Home() {
    const homeRef = useRef<HTMLDivElement>(null);
    const { isDark, toggleTheme } = useContext(ThemeContext);
    const navigate = useNavigate();
    useHotkeys("meta+j", () => toggleTheme(), {
        enableOnContentEditable: true,
    });
    return (
        <>
            <div
                className="container d-flex flex-column align-items-center justify-content-center my-3 my-sm-5"
                ref={homeRef}
            >
                <Helmet>
                    <title>The Ravim</title>
                    <link rel="canonical" href="https://theravim.com" />

                    <meta property="title" content="The Ravim" />
                    <meta
                        name="description"
                        content="The only writing app exclusively created for authors and storytellers that leverages the efficiency of Vim. Custom-tailored features to help you take your novel from concept to publication.  Start your hero’s journey and write the next bestseller with The Ravim."
                    />

                    <meta property="og:title" content="The Ravim" />
                    <meta
                        property="og:description"
                        content="The only writing app exclusively created for authors and storytellers that leverages the efficiency of Vim. Custom-tailored features to help you take your novel from concept to publication.  Start your hero’s journey and write the next bestseller with The Ravim."
                    />
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://theravim.com"
                    />
                    <meta
                        property="og:image"
                        content="https://theravim.com/the_ravim_logo_200.jpg"
                    />
                    <meta property="og:image:alt" content="The Ravim" />
                </Helmet>
                <img
                    height={180}
                    width={180}
                    alt="poe-logo"
                    src={poe}
                    style={{
                        filter: `invert(${isDark ? 1 : 0})`,
                        opacity: isDark ? 0.6 : 1,
                    }}
                />
                <h1 className="display-1 fs-1">The Ravim</h1>
                <h2 className="lead fs-3">Story Writing with Vim</h2>
                <Features />
                <Button
                    className="mt-0"
                    style={{ width: 150 }}
                    onClick={() => navigate("/signIn")}
                >
                    Sign In
                </Button>
                <Link className="custom-link-hover-primary mt-3" to={"/faq"}>
                    <small>Learn More</small>
                </Link>
            </div>
        </>
    );
}
