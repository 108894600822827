export interface ContactFormEntry {
    name: string;
    email: string;
    issueType: IssueType | string;
    message: string;
}

export interface EmailPayload {
    sender: string;
    recipient: string;
    subject: string;
    text: string;
    html?: string;
}

export interface Issue {
    id: string;
    createdAt: number;
    updatedAt: number;
    ownerId: string;
    name: string;
    email: string;
    description: string;
    issueType: IssueType | string;
    title: string;
    threads: IssueThread[];
    status: IssueStatus;
}
export interface IssueThread {
    id: string;
    ownerName: string;
    ownerId: string;
    text: string;
    createdAt: number;
    updatedAt: number;
}

export interface EmailPayload {
    sender: string;
    recipient: string;
    subject: string;
    text: string;
    html?: string;
}

export enum IssueStatus {
    NEW = "new",
    PENDING_TRIAGE = "pending_triage",
    RESOLVED = "resolved",
}

export enum ActiveTab {
    WRITE = "write",
    PREVIEW = "preview",
}

export enum IssueType {
    BUG = "bug",
    FEATURE_REQUEST = "feature_request",
    FEEDBACK = "feedback",
    OTHER = "other",
    QUESTION = "question",
}

export enum IssueAction {
    CREATE_ISSUE_FAIL = "create_issue_fail",
    CREATE_ISSUE_START = "create_issue_start",
    CREATE_ISSUE_SUCCESS = "create_issue_success",
    DELETE_ISSUE_FAIL = "delete_issue_fail",
    DELETE_ISSUE_START = "delete_issue_start",
    DELETE_ISSUE_SUCCESS = "delete_issue_success",
    UPDATE_ISSUE_FAIL = "update_issue_fail",
    UPDATE_ISSUE_START = "update_issue_start",
    UPDATE_ISSUE_SUCCESS = "update_issue_success",
    GET_ISSUES_FAIL = "GET_ISSUES_FAIL",
    GET_ISSUES_START = "GET_ISSUES_START",
    GET_ISSUES_SUCCESS = "GET_ISSUES_SUCCESS",
}
