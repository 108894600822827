import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    children: JSX.Element;
}
export default function PublicRoute({ children }: Props) {
    const user = useAuth();
    if (user) {
        if (isDev) {
            console.log(
                "%c🚦 Redirecting to '/' from public route",
                "color:cyan"
            );
        }
        return <Navigate to="/" replace />;
    }

    return children;
}
