export interface UserState {
    agents: Agent[];
    displayName?: string | null | undefined;
    email: string | undefined;
    enabled: boolean | undefined;
    manuscriptEmail?: string | undefined;
    id: string | undefined;
    penName: string | undefined;
    phone?: string | undefined;
    role?: UserRole;
    settings: UserSettings;
}

export interface GetUserInfoAPIResponse {
    data: { disabled: boolean; email?: string; id: string };
}

export interface Agent {
    id: string;
    agency: string;
    assistant?: string;
    firstName: string;
    honorific: string;
    lastName: string;
    manuscripts?: string[];
    email: string;
    phone: string;
}

export enum UserRole {
    ADMIN = "admin",
    INTERESTED = "interested",
    SUBSCRIBER = "subscriber",
    NORMIE = "normie",
}

export interface UserSettings {
    editorFontSize: number;
    previewFontSize: number;
    justifyPreview: boolean;
    showTooltips: boolean;
    useVim: boolean;
    useDavinci: boolean;
}

export enum UserActionType {
    CHECK_DB_USER_FAIL = "check_db_user_fail",
    CHECK_DB_USER_START = "check_db_user_start",
    CHECK_DB_USER_SUCCESS = "check_db_user_success",
    DELETE_USER_ACCOUNT_FAIL = "delete_user_account_fail",
    DELETE_USER_ACCOUNT_START = "delete_user_account_start",
    DELETE_USER_ACCOUNT_SUCCESS = "delete_user_account_success",
    DELETE_USER_DATA_FAIL = "delete_user_data_fail",
    DELETE_USER_DATA_START = "delete_user_data_start",
    DELETE_USER_DATA_SUCCESS = "delete_user_data_success",
    CREATE_USER_FAIL = "create_user_fail",
    CREATE_USER_START = "create_user_start",
    CREATE_USER_SUCCESS = "create_user_success",
    GET_DB_USER_FAIL = "get_db_user_fail",
    GET_DB_USER_START = "get_db_user_start",
    GET_DB_USER_SUCCESS = "get_db_user_success",
    UPDATE_USER_FAIL = "update_user_fail",
    UPDATE_USER_START = "update_user_start",
    UPDATE_USER_SUCCESS = "update_user_success",
    UPDATE_USER_SETTINGS_FAIL = "update_user_settings_fail",
    UPDATE_USER_SETTINGS_START = "update_user_settings_start",
    UPDATE_USER_SETTINGS_SUCCESS = "update_user_settings_success",
    SET_USER_DATA = "set_user_data",
    SET_USER_SETTINGS = "set_user_settings",
}
