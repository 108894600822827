import { useState } from "react";
import { AdminActionType } from "../../types";
import { isMobile } from "react-device-detect";
import { Modal, Button, Spinner, Form, FormGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
    disableGoogleAuthUser,
    enableGoogleAuthUser,
    updateUser,
} from "../../actions";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    actionText?: string;
    destructive?: boolean;
    bodyText: string;
    bodySubtext?: string;
    buttonIcon?: string;
    emoji?: string;
    headerText?: string;
}
export default function DisableUserModal({
    actionText,
    bodyText,
    bodySubtext,
    buttonIcon,
    destructive = false,
    emoji,
    headerText,
}: Props) {
    const { isDisableUserModalOpen, selectedUser: user } = useAppSelector(
        (state) => state.admin
    );
    const dispatch = useAppDispatch();
    const [confirmText, setConfirmText] = useState("");
    const [spinning, setSpinning] = useState(false);

    function handleClose() {
        dispatch({
            type: AdminActionType.SET_DISABLE_USER_MODAL,
            payload: { isOpen: false, user: undefined },
        });
        resetModal();
    }
    function resetModal() {
        setConfirmText("");
    }
    async function handleUser(e: React.FormEvent<HTMLFormElement>) {
        try {
            e.preventDefault();
            if (
                user?.email &&
                user?.id &&
                !spinning &&
                confirmText === "delete"
            ) {
                setSpinning(true);
                if (user.enabled === true) {
                    await dispatch(
                        disableGoogleAuthUser({ ...user, enabled: false })
                    );
                    await dispatch(updateUser({ ...user, enabled: false }));
                } else {
                    await dispatch(
                        enableGoogleAuthUser({ ...user, enabled: true })
                    );
                    await dispatch(updateUser({ ...user, enabled: true }));
                }
                setSpinning(false);
                handleClose();
            } else {
                if (isDev) {
                    console.log("Function does not meet enabled conditions");
                }
            }
        } catch (error) {
            setSpinning(false);
            console.warn(error);
        }
    }
    return (
        <Modal
            centered={!isMobile}
            show={isDisableUserModalOpen}
            onHide={() => handleClose()}
            restoreFocus={false}
        >
            {headerText && (
                <Modal.Header closeButton>
                    <Modal.Title>{headerText}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>
                <Form onSubmit={(e) => handleUser(e)}>
                    <div className="d-flex flex-column align-items-center">
                        {emoji && (
                            <div className="mb-3" style={{ fontSize: 64 }}>
                                {emoji}
                            </div>
                        )}
                        <div>{bodyText}</div>
                        {bodySubtext && (
                            <div className="fw-bold text-danger mt-3">
                                {bodySubtext}
                            </div>
                        )}
                        <FormGroup className="d-flex justify-content-center mt-3 w-100">
                            <Form.Control
                                autoFocus
                                placeholder="Enter 'confirm' to proceed"
                                value={confirmText}
                                onChange={(e) => setConfirmText(e.target.value)}
                                style={{ width: 240 }}
                            />
                        </FormGroup>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div
                    className={`d-flex justify-content-${
                        actionText ? "between" : "center"
                    } w-100`}
                >
                    <Button
                        className="custom-link-btn-hover-primary"
                        disabled={spinning}
                        variant="link"
                        onClick={() => handleClose()}
                    >
                        close
                    </Button>
                    {actionText && (
                        <Button
                            className="d-flex align-items-center justify-content-center"
                            disabled={spinning || confirmText !== "confirm"}
                            type="submit"
                            variant={destructive ? "danger" : "success"}
                        >
                            {spinning && <Spinner className="me-2" size="sm" />}
                            {!spinning && buttonIcon && (
                                <i className={`bi bi-${buttonIcon} me-2`} />
                            )}
                            <span>{actionText}</span>
                        </Button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}
