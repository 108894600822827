import { AppThunk } from "../store/store";
import { AuthActionType } from "../types/auth";
import {
    DocumentData,
    deleteDoc,
    doc,
    getDoc,
    setDoc,
} from "firebase/firestore";
import { User, deleteUser, getAuth } from "firebase/auth";
import { UserActionType } from "../types/user"; // enum
import db from "../firebase/firebase";
import type { UserSettings, UserState } from "../types/user";
import { sendEmail } from "./emailActions";
import { EmailPayload } from "../types";

const isDev = process.env.NODE_ENV === "development";

export const deleteGoogleUserAccount =
    (): AppThunk<Promise<void>> => async (dispatch) => {
        try {
            dispatch({ type: UserActionType.DELETE_USER_ACCOUNT_START });
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                await deleteUser(user);
            } else {
                throw new Error("No auth user!");
            }
            dispatch({ type: UserActionType.DELETE_USER_ACCOUNT_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: UserActionType.DELETE_USER_ACCOUNT_FAIL });
            return Promise.reject(error);
        }
    };

export const deleteUserData =
    (userId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: UserActionType.DELETE_USER_DATA_START });
            await deleteDoc(doc(db, "users", userId));
            dispatch({ type: UserActionType.DELETE_USER_DATA_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: UserActionType.DELETE_USER_DATA_FAIL });
            return Promise.reject(error);
        }
    };

export const getDbUser =
    (user: User): AppThunk<Promise<void | DocumentData>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: UserActionType.GET_DB_USER_START });
            const {
                user: { id: userId },
            } = getState();
            const existingUserDoc = await getDoc(doc(db, "users", user.uid));
            if (existingUserDoc.exists()) {
                const existingUser = existingUserDoc.data();
                if (isDev) {
                    console.log(
                        `%c${
                            existingUser.displayName ||
                            existingUser.email ||
                            existingUser.uid
                        } already exists in db`,
                        "color:cyan"
                    );
                }
                if (!userId) {
                    dispatch({
                        type: UserActionType.SET_USER_DATA,
                        payload: existingUser as UserState,
                    });
                }
            } else {
                await dispatch(createUser(user));
                const emailPayload: EmailPayload = {
                    sender: "The Ravim <contact@theravim.com>",
                    recipient: "contact@theravim.com",
                    subject: `New User Created: ${
                        user.displayName || user.email || user.uid
                    }`,
                    text: `A new user has been created!\n\nDisplay Name: ${
                        user.displayName || "n/a"
                    }\n\nEmail: ${user.email || "n/a"}`,
                };
                if (isDev) {
                    console.log(
                        "%cWhy does this send two emails?",
                        "color:hotpink"
                    );
                }
                await dispatch(sendEmail(emailPayload));
            }
            dispatch({
                type: UserActionType.GET_DB_USER_SUCCESS,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: UserActionType.GET_DB_USER_FAIL });
            return Promise.reject(error);
        }
    };

export const createUser =
    (user: User): AppThunk<Promise<void | DocumentData>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: UserActionType.CREATE_USER_START });
            const {
                user: { id: userId },
            } = getState();
            const existingUser = await getDoc(doc(db, "users", user.uid));
            if (existingUser.exists()) {
                if (isDev) {
                    console.log(
                        `%c${
                            user.displayName || user.email || user.uid
                        } already exists in db`,
                        "color:cyan"
                    );
                }
                const data = existingUser.data();
                return Promise.resolve(data);
            } else {
                const payload = {
                    displayName: user.displayName,
                    enabled: true,
                    email: user.email,
                    id: user.uid,
                    settings: {
                        editorFontSize: 16,
                        justifyPreview: false,
                        previewFontSize: 18,
                        showTooltips: true,
                        useDavinci: false,
                        useVim: true,
                    },
                };
                await setDoc(doc(db, "users", user.uid), payload);
                if (isDev) {
                    console.log(
                        `%c${
                            user.displayName || user.email || user.uid
                        } created in db`,
                        "color:lime"
                    );
                }
                if (!userId) {
                    dispatch({ type: UserActionType.SET_USER_DATA, payload });
                }
                dispatch({ type: UserActionType.CREATE_USER_SUCCESS });
                return Promise.resolve();
            }
        } catch (error) {
            dispatch({ type: UserActionType.CREATE_USER_FAIL });
            return Promise.reject(error);
        }
    };

export const updateUser =
    (user: UserState): AppThunk<Promise<UserState>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: UserActionType.UPDATE_USER_START });
            if (!user.id) {
                throw new Error("No user id!");
            }
            const { id: currentUserId } = getState().user;
            await setDoc(doc(db, "users", user.id), user, { merge: true });
            if (user.id === currentUserId) {
                dispatch({
                    type: UserActionType.SET_USER_DATA,
                    payload: user,
                });
            }
            dispatch({
                type: UserActionType.UPDATE_USER_SUCCESS,
            });
            return Promise.resolve(user);
        } catch (error) {
            dispatch({ type: UserActionType.UPDATE_USER_FAIL });
            return Promise.reject(error);
        }
    };

export const updateUserSettings =
    (settings: UserSettings): AppThunk<Promise<void | DocumentData>> =>
    async (dispatch, getState) => {
        try {
            const { user } = getState();
            const { id: userId } = user;
            if (!userId) {
                throw new Error("No user ID!");
            }
            const payload = { id: userId, settings };
            dispatch({ type: UserActionType.UPDATE_USER_SETTINGS_START });
            await setDoc(doc(db, "users", userId), payload, { merge: true });
            if (isDev) {
                console.log(
                    `%c${
                        user.displayName || user.email || userId
                    } settings updated in db`,
                    "color:lime"
                );
            }
            dispatch({
                type: UserActionType.UPDATE_USER_SETTINGS_SUCCESS,
                payload: settings,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: UserActionType.UPDATE_USER_SETTINGS_FAIL });
            return Promise.reject(error);
        }
    };

export const signOut = (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
        dispatch({ type: AuthActionType.SIGN_OUT_START });
        const auth = getAuth();
        await auth.signOut();
        dispatch({ type: AuthActionType.SIGN_OUT_SUCCESS });
        return Promise.resolve();
    } catch (error) {
        dispatch({ type: AuthActionType.SIGN_OUT_FAIL });
        return Promise.reject(error);
    }
};
