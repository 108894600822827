import { useContext, useMemo } from "react";
import { AdminFeature } from "./Admin";
import { Button, Nav } from "react-bootstrap";
import { ThemeContext } from "../../contexts/ThemeProvider";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import config from "../../config/config.json";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import poe from "../../assets/images/poe-icon.png";

interface Props {
    isMed: boolean;
    isSmall: boolean;
    isLarge: boolean;
    selectedFeature: AdminFeature;
    setSelectedFeature: (feature: AdminFeature) => void;
}
export default function AdminNavbar({
    isMed,
    isSmall,
    isLarge,
    selectedFeature,
    setSelectedFeature,
}: Props) {
    const { isDark, toggleTheme } = useContext(ThemeContext);
    const navigate = useNavigate();

    const { logoSize } = useMemo(() => {
        const iconFontSize = isSmall ? "fs-6" : "fs-3";
        const logoSize = isSmall ? 24 : 30;
        return { iconFontSize, logoSize };
    }, [isSmall]);

    useHotkeys("meta+j", () => toggleTheme(), {
        enableOnContentEditable: true,
    });

    return (
        <Navbar className="w-100" bg="tertiary" expand={true}>
            <Container
                fluid
                className="d-flex align-items-center justify-content-between px-0"
            >
                <Navbar.Brand className="ps-2 me-1">
                    <div
                        className="d-flex align-items-center"
                        onClick={() => navigate(-1)}
                        style={{ cursor: "pointer" }}
                    >
                        <img
                            alt="Poe Logo"
                            src={poe}
                            width={logoSize}
                            height={logoSize}
                            style={{
                                filter: `invert(${isDark ? 1 : 0})`,
                                opacity: isDark ? 0.6 : 1,
                            }}
                        />{" "}
                        {!isMed && (
                            <span className="text-body-secondary ms-2">
                                {config.SITE_NAME}
                            </span>
                        )}
                    </div>
                </Navbar.Brand>
                <Nav className="ms-auto">
                    <Nav.Link
                        active={selectedFeature === AdminFeature.USERS}
                        onClick={() => setSelectedFeature(AdminFeature.USERS)}
                    >
                        Users
                    </Nav.Link>
                    <Nav.Link
                        active={selectedFeature === AdminFeature.MANUSCRIPTS}
                        onClick={() =>
                            setSelectedFeature(AdminFeature.MANUSCRIPTS)
                        }
                    >
                        Manuscripts
                    </Nav.Link>
                    <div className="d-flex align-items-center">
                        <Button
                            href="https://github.com/kimfucious/raven-write-react"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-flex align-items-center justify-content-center ms-3"
                            size="sm"
                            variant="secondary"
                        >
                            <i className="bi bi-github" />
                            {isLarge && <span className="ms-2">Open Repo</span>}
                        </Button>
                        <Button
                            className="d-flex align-items-center justify-content-center ms-3"
                            href="https://console.firebase.google.com/u/0/project/raven-write/overview"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="sm"
                            variant="warning"
                        >
                            <i className="bi bi-fire" />
                            {isLarge && (
                                <span className="ms-2">Open Firebase</span>
                            )}
                        </Button>
                    </div>
                </Nav>
            </Container>
        </Navbar>
    );
}
