import React from "react";
import { AuthProvider } from "./contexts/AuthProvider";
import { Provider as StateProvider } from "react-redux";
import App from "./appRouter/App";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import ThemeProvider from "./contexts/ThemeProvider";
import "./styles/styles.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

console.log("%c🐦‍⬛ Welcome to The Ravim", "color:violet");

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <AuthProvider>
                <StateProvider store={store}>
                    <App />
                </StateProvider>
            </AuthProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
