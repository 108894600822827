import { object, string } from "yup";

export const signInSchema = object().shape({
  email: string()
    .required("Email is required")
    .email("Please use a valid email address."),
  password: string()
    .required("Password is required")
    .max(24, "Password must be no longer than 24 characters.")
    .min(8, "Password must be at least 8 characters.")
});
