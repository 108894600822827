export enum ActionType {
    CHECK_MANUSCRIPT_FOR_UPDATES_FAIL = "check_manuscript_for_updates_fail",
    CHECK_MANUSCRIPT_FOR_UPDATES_START = "check_manuscript_for_updates_start",
    CHECK_MANUSCRIPT_FOR_UPDATES_SUCCESS = "check_manuscript_for_updates_success",
    CLEAN_SCENE_FAIL = "CLEAN_SCENE_FAIL",
    CLEAN_SCENE_START = "CLEAN_SCENE_START",
    CLEAN_SCENE_SUCCESS = "CLEAN_SCENE_SUCCESS",
    CLEAR_COMPILED_MANUSCRIPT = "clear_compiled_manuscript",
    CLOSE_ABANDONED_SCENE_MODAL = "close_abandoned_scene_modal",
    CLOSE_ACTIVE_SCENE = "close_active_scene",
    CLOSE_ACTIVE_SECTION = "close_active_section",
    CLOSE_CREATE_MODAL = "close_create_modal",
    CLOSE_DELETE_MODAL = "close_delete_modal",
    CLOSE_LOADING_OVERLAY = "close_loading_overlay",
    CLOSE_MANUSCRIPT = "close_manuscript",
    CLOSE_RENAME_MODAL = "close_rename_modal",
    CLOSE_REVERT_SCENE_MODAL = "close_revert_scene_modal",
    COMPILE_MANUSCRIPT_FAIL = "compile_manuscript_fail",
    COMPILE_MANUSCRIPT_START = "compile_manuscript_start",
    COMPILE_MANUSCRIPT_SUCCESS = "compile_manuscript_success",
    CREATE_MANUSCRIPT_FAIL = "create_manuscript_fail",
    CREATE_MANUSCRIPT_START = "create_manuscript_start",
    CREATE_MANUSCRIPT_SUCCESS = "create_manuscript_success",
    CREATE_SCENE_FAIL = "create_scene_fail",
    CREATE_SCENE_START = "create_scene_start",
    CREATE_SCENE_SUCCESS = "create_scene_success",
    CREATE_SECTION_FAIL = "create_section_fail",
    CREATE_SECTION_START = "create_section_start",
    CREATE_SECTION_SUCCESS = "create_section_success",
    DELETE_ALL_MANUSCRIPT_SCENES_FAIL = "delete_all_manuscript_scenes_fail",
    DELETE_ALL_MANUSCRIPT_SCENES_START = "delete_all_manuscript_scenes_start",
    DELETE_ALL_MANUSCRIPT_SCENES_SUCCESS = "delete_all_manuscript_scenes_success",
    DELETE_MANUSCRIPT_FAIL = "delete_manuscript_fail",
    DELETE_MANUSCRIPT_START = "delete_manuscript_start",
    DELETE_MANUSCRIPT_SUCCESS = "delete_manuscript_success",
    DELETE_SCENE_FAIL = "delete_scene_fail",
    DELETE_SCENE_START = "delete_scene_start",
    DELETE_SCENE_SUCCESS = "delete_scene_success",
    DELETE_SCENE_BACKUP_FAIL = "delete_scene_backup_fail",
    DELETE_SCENE_BACKUP_START = "delete_scene_backup_start",
    DELETE_SCENE_BACKUP_SUCCESS = "delete_scene_backup_success",
    DELETE_SECTION_FAIL = "delete_section_fail",
    DELETE_SECTION_START = "delete_section_start",
    DELETE_SECTION_SUCCESS = "delete_section_success",
    GET_DELETED_SCENES_FAIL = "get_deleted_scenes_fail",
    GET_DELETED_SCENES_START = "get_deleted_scenes_start",
    GET_DELETED_SCENES_SUCCESS = "get_deleted_scenes_success",
    GET_EPUB_FAIL = "get_epub_fail",
    GET_EPUB_START = "get_epub_start",
    GET_EPUB_SUCCESS = "get_epub_success",
    GET_MANUSCRIPT_FAIL = "get_manuscript_fail",
    GET_MANUSCRIPT_START = "get_manuscript_start",
    GET_MANUSCRIPT_SUCCESS = "get_manuscript_success",
    GET_MANUSCRIPT_SCENES_FAIL = "get_manuscript_scenes_fail",
    GET_MANUSCRIPT_SCENES_START = "get_manuscript_scenes_start",
    GET_MANUSCRIPT_SCENES_SUCCESS = "get_manuscript_scenes_success",
    GET_MANUSCRIPTS_FAIL = "get_manuscripts_fail",
    GET_MANUSCRIPTS_START = "get_manuscripts_start",
    GET_MANUSCRIPTS_SUCCESS = "get_manuscripts_success",
    GET_MARKDOWN_MANUSCRIPT_FAIL = "get_markdown_manuscript_fail",
    GET_MARKDOWN_MANUSCRIPT_START = "get_markdown_manuscript_start",
    GET_MARKDOWN_MANUSCRIPT_SUCCESS = "get_markdown_manuscript_success",
    GET_BACKUP_SCENE_FAIL = "get_backup_scene_fail",
    GET_BACKUP_SCENE_START = "get_backup_scene_start",
    GET_BACKUP_SCENE_SUCCESS = "get_backup_scene_success",
    LISTEN_TO_MANUSCRIPTS_FAIL = "listen_to_manuscripts_fail",
    LISTEN_TO_MANUSCRIPTS_START = "listen_to_manuscripts_start",
    LISTEN_TO_MANUSCRIPTS_SUCCESS = "listen_to_manuscripts_success",
    LISTEN_TO_SCENES_FAIL = "listen_to_scenes_fail",
    LISTEN_TO_SCENES_START = "listen_to_scenes_start",
    LISTEN_TO_SCENES_SUCCESS = "listen_to_scenes_success",
    MANUSCRIPT_ADD = "manuscript_add",
    MANUSCRIPT_DELETE = "manuscript_delete",
    MANUSCRIPT_UPDATE = "manuscript_update",
    MOVE_SCENE_FAIL = "move_scene_fail",
    MOVE_SCENE_START = "move_scene_start",
    MOVE_SCENE_SUCCESS = "move_scene_success",
    OPEN_ABANDONED_SCENE_MODAL = "open_abandoned_scene_modal",
    OPEN_CREATE_MODAL = "open_create_modal",
    OPEN_DELETE_MODAL = "open_delete_modal",
    OPEN_LOADING_OVERLAY = "open_loading_overlay",
    OPEN_RENAME_MODAL = "open_rename_modal",
    OPEN_REVERT_SCENE_MODAL = "open_revert_scene_modal",
    REMOVE_DELETED_SCENE_FAIL = "remove_deleted_scene_fail",
    REMOVE_DELETED_SCENE_START = "remove_deleted_scene_start",
    REMOVE_DELETED_SCENE_SUCCESS = "remove_deleted_scene_success",
    REMOVE_MANUSCRIPT_SCENES_FROM_STORAGE_FAIL = "remove_manuscript_scenes_from_storage_fail",
    REMOVE_MANUSCRIPT_SCENES_FROM_STORAGE_START = "remove_manuscript_scenes_from_storage_start",
    REMOVE_MANUSCRIPT_SCENES_FROM_STORAGE_SUCCESS = "remove_manuscript_scenes_from_storage_success",
    REMOVE_SCENE_FROM_STORAGE_FAIL = "remove_scene_from_storage_fail",
    REMOVE_SCENE_FROM_STORAGE_START = "remove_scene_from_storage_start",
    REMOVE_SCENE_FROM_STORAGE_SUCCESS = "remove_scene_from_storage_success",
    RENAME_SCENE_FAIL = "rename_scene_fail",
    RENAME_SCENE_START = "rename_scene_start",
    RENAME_SCENE_SUCCESS = "rename_scene_success",
    RESTORE_DELETED_SCENE_FAIL = "restore_deleted_scene_fail",
    RESTORE_DELETED_SCENE_START = "restore_deleted_scene_start",
    RESTORE_DELETED_SCENE_SUCCESS = "restore_deleted_scene_success",
    RESTORE_SCENE_FROM_LOCAL_STORAGE_FAIL = "restore_scene_from_local_storage_fail",
    RESTORE_SCENE_FROM_LOCAL_STORAGE_START = "restore_scene_from_local_storage_start",
    RESTORE_SCENE_FROM_LOCAL_STORAGE_SUCCESS = "restore_scene_from_local_storage_success",
    REVERT_ACTIVE_SCENE = "revert_active_scene",
    SAVE_ACTIVE_SCENE_FAIL = "save_active_scene_fail",
    SAVE_ACTIVE_SCENE_START = "save_active_scene_start",
    SAVE_ACTIVE_SCENE_SUCCESS = "save_active_scene_success",
    SAVE_UNSAVED_SCENE_FAIL = "save_unsaved_scene_fail",
    SAVE_UNSAVED_SCENE_START = "save_unsaved_scene_start",
    SAVE_UNSAVED_SCENE_SUCCESS = "save_unsaved_scene_success",
    SAVE_SCENE_TO_STORAGE_FAIL = "save_scene_to_storage_fail",
    SAVE_SCENE_TO_STORAGE_START = "save_scene_to_storage_start",
    SAVE_SCENE_TO_STORAGE_SUCCESS = "save_scene_to_storage_success",
    SAVE_SCENE_BACKUP_FAIL = "save_scene_backup_fail",
    SAVE_SCENE_BACKUP_START = "save_scene_backup_start",
    SAVE_SCENE_BACKUP_SUCCESS = "save_scene_backup_success",
    SAVE_SCENE_VERSION_FAIL = "SAVE_SCENE_VERSION_FAIL",
    SAVE_SCENE_VERSION_START = "SAVE_SCENE_VERSION_START",
    SAVE_SCENE_VERSION_SUCCESS = "SAVE_SCENE_VERSION_SUCCESS",
    SET_ACTIVE_MANUSCRIPT = "set_active_manuscript",
    SET_ACTIVE_SCENE = "set_active_scene",
    SET_ACTIVE_SECTION = "set_active_section",
    SET_AGENT_FOR_PRINT = "set_agent_for_print",
    SET_BACKUP_SCENE = "set_backup_scene",
    SET_CREATE_TYPE = "set_create_type",
    SET_DRAFT_ACTIVE_SCENE_TEXT = "set_draft_active_scene_text",
    SET_EDGAR_TEXT = "SET_EDGAR_TEXT",
    SET_EDIT_MODE = "set_edit_mode",
    SET_EDITOR_FONT_SIZE = "set_editor_font_size",
    SET_IS_ABANDONED_SCENE_MODAL_OPEN = "set_is_abandoned_scene_modal_open",
    // SET_IS_AGENT_SUBMISSIONS_MODAL_OPEN = "set_is_agent_submissions_modal_open",
    SET_IS_CHARACTER_BIO_OPEN = "set_is_character_bio_open",
    SET_IS_CREATE_MODAL_OPEN = "set_is_create_modal_open",
    SET_IS_DELETE_ACCOUNT_MODAL_OPEN = "set_is_delete_account_modal_open",
    SET_IS_DELETED_SCENE_MODAL_OPEN = "set_is_deleted_scene_modal_open",
    SET_IS_FULLSCREEN = "set_is_fullscreen",
    SET_IS_INFO_MODAL_OPEN = "set_is_info_modal_open",
    SET_IS_INFO_SIDEBAR_OPEN = "set_is_info_sidebar_open",
    SET_IS_INTERNET_UP = "set_is_internet_up",
    SET_IS_LEFT_SIDEBAR_OPEN = "set_is_left_sidebar_open",
    SET_IS_LIST_MANUSCRIPTS = "set_is_list_manuscripts",
    SET_IS_MANUSCRIPT_DIFFERENT_IN_DATABASE = "set_is_manuscript_different_in_database",
    SET_IS_MANUSCRIPT_PDF_LOADING = "set_is_manuscript_pdf_loading",
    SET_IS_MANUSCRIPT_PDF_OPEN = "set_is_manuscript_pdf_open",
    SET_IS_QUERY_LETTER_OPEN = "set_is_query_letter_open",
    SET_IS_SEARCH_MODAL_OPEN = "set_is_search_modal_open",
    SET_IS_SCENE_CLEANER_OPEN = "SET_IS_SCENE_CLEANER_OPEN",
    SET_IS_SETTINGS_SIDEBAR_OPEN = "set_is_settings",
    SET_IS_STORYLINE_COLLAPSED = "set_is_storyline_collapsed",
    SET_IS_STORYLINE_OPEN = "set_is_storyline_open",
    SET_IS_STORY_ARC_MODAL_OPEN = "set_is_story_arc_modal_open",
    SET_IS_SUBMISSION_FORM_OPEN = "set_is_submission_form_open",
    SET_IS_SUBSCRIBE_MODAL_OPEN = "set_is_subscribe_modal_open",
    SET_IS_SYNOPSIS_EDITOR_OPEN = "set_is_synopsis_editor_open",
    SET_IS_UNSAVED_CHANGES_MODAL_OPEN = "set_is_unsaved_changes_modal_open",
    SET_LOADING_MESSAGE = "set_loading_message",
    SET_MANUSCRIPTS = "set_manuscripts",
    SET_MERGE_EDITOR = "set_merge_editor",
    SET_ORIGINAL_ACTIVE_SCENE = "set_original_active_scene",
    SET_ORIGINAL_ACTIVE_SECTION = "set_original_active_section",
    SET_SCENES = "set_scenes",
    SET_SECTION = "set_section",
    SET_SELECTED_SCENE = "set_selected_scene",
    SET_TARGET_SCENE = "set_target_scene",
    SET_VERSION_TO_COMPARE = "SET_VERSION_TO_COMPARE",
    SET_VIEW_MODE = "set_view_mode",
    STOP_LISTEN_TO_MANUSCRIPTS_FAIL = "stop_listen_to_manuscripts_fail",
    STOP_LISTEN_TO_MANUSCRIPTS_START = "stop_listen_to_manuscripts_start",
    STOP_LISTEN_TO_MANUSCRIPTS_SUCCESS = "stop_listen_to_manuscripts_success",
    STOP_LISTEN_TO_SCENES_FAIL = "stop_listen_to_scenes_fail",
    STOP_LISTEN_TO_SCENES_START = "stop_listen_to_scenes_start",
    STOP_LISTEN_TO_SCENES_SUCCESS = "stop_listen_to_scenes_success",
    STOP_LISTENING = "stop_listening",
    TOGGLE_EXCLUDE_FROM_COMPILE = "toggle_exclude_from_compile",
    UPDATE_DELETED_SCENES = "UPDATE_DELETED_SCENES",
    UPDATE_DIFF_CHANGED_TEXT = "update_diff_changed_text",
    UPDATE_MANUSCRIPT_FAIL = "update_manuscript_fail",
    UPDATE_MANUSCRIPT_START = "update_manuscript_start",
    UPDATE_MANUSCRIPT_SUCCESS = "update_manuscript_success",
    UPDATE_SCENE = "update_scene",
    UPDATE_SCENE_FAIL = "update_scene_fail",
    UPDATE_SCENE_ORDER_FAIL = "update_scene_order_fail",
    UPDATE_SCENE_ORDER_START = "update_scene_order_start",
    UPDATE_SCENE_ORDER_SUCCESS = "update_scene_order_success",
    UPDATE_SCENE_START = "update_scene_start",
    UPDATE_SCENE_SUCCESS = "update_scene_success",
    UPDATE_SECTION = "update_section",
}

export enum Modal {
    AGENT_SUBMISSIONS_MODAL_OPEN = "set_is_agent_submissions_modal_open",
    CREATE_MODAL_OPEN = "set_is_create_modal_open",
    DELETE_ACCOUNT_MODAL_OPEN = "set_is_delete_account_modal_open",
    SUBSCRIBE_MODAL_OPEN = "SET_IS_SUBSCRIBE_MODAL_OPEN",
    INFO_MODAL_OPEN = "set_is_info_modal_open",
}
