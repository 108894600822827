import { useState } from "react";
import { Manuscript } from "../../types";
import ManuscriptDetail from "./ManuscriptDetail";
import ManuscriptList from "./ManuscriptList";

interface Props {
    handleSecuritySignOut: () => void;
}
export default function Maintenance({ handleSecuritySignOut }: Props) {
    const [selectedManuscript, setSelectedManuscript] = useState<Manuscript>();

    return (
        <div className="d-flex flex-column align-items-center my-3 w-100">
            <h1>Manuscripts</h1>
            {selectedManuscript ? (
                <ManuscriptDetail
                    selectedManuscript={selectedManuscript}
                    setSelectedManuscript={setSelectedManuscript}
                />
            ) : (
                <ManuscriptList
                    setSelectedManuscript={setSelectedManuscript}
                    handleSecuritySignOut={handleSecuritySignOut}
                />
            )}
        </div>
    );
}
